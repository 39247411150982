import { SVGProps } from 'react';

type CloudIconProps = {
  color?: string;
  props?: SVGProps<SVGSVGElement>;
};

export const CloudIcon = ({ props, color }: CloudIconProps): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path
        d='M4.1623 18.6088L4.14379 18.6086C4.12446 18.6082 4.09173 18.6074 4.04753 18.605C3.95879 18.6003 3.8259 18.5898 3.66403 18.5665C3.33519 18.5193 2.91381 18.4232 2.5058 18.235C2.10075 18.0482 1.73465 17.783 1.46858 17.4068C1.20868 17.0394 1 16.5044 1 15.6957C1 13.847 2.6986 12.4659 4.51363 12.844C4.79818 12.9033 5.09444 12.8358 5.32527 12.6592C5.5561 12.4826 5.69866 12.2142 5.71582 11.9241C5.91128 8.61928 8.64606 6 12 6C14.5114 6 16.6786 7.4755 17.6912 9.61164C17.8624 9.97295 18.2325 10.1975 18.632 10.1826C21.0503 10.0925 23 12.0295 23 14.3913C23 16.2748 22.0733 17.2889 21.1248 17.8776C20.6347 18.1818 20.1365 18.37 19.7562 18.4817C19.5679 18.537 19.4133 18.5722 19.3097 18.5929C19.276 18.5997 19.2479 18.6048 19.2259 18.6087H4.17391L4.16059 18.6088L4.1619 18.6088L4.1623 18.6088ZM4.1623 18.6088C4.16361 18.6088 4.164 18.6087 4.16345 18.6088H4.1623Z'
        stroke={color || 'currentcolor'}
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect fill='white' height='24' width='24' />
      </clipPath>
    </defs>
  </svg>
);
