import { useState } from 'react';

import { useUserContext, setMonthDeltaAction } from 'App/context';

const MONTH_DIFF = 1;
const FIRST_MONTH_INDEX = 1;
const LAST_MONTH_INDEX = 12;

export const useListActions = <T>(initialItemsToDisplay: number, itemsToExpand: number, list: Array<T>) => {
  const [{ monthDelta }, dispatch] = useUserContext();
  const [amountOfListItems, setAmountOfListItems] = useState(initialItemsToDisplay);
  const handleOnMonthChange = (newMonthDelta: number) => {
    dispatch(setMonthDeltaAction(newMonthDelta));
    setAmountOfListItems(initialItemsToDisplay);
  };
  const handleOnSwipeLeft = () => {
    if (monthDelta === FIRST_MONTH_INDEX) {
      return;
    }

    dispatch(setMonthDeltaAction((monthDelta as number) - MONTH_DIFF));
    setAmountOfListItems(initialItemsToDisplay);
  };
  const handleOnSwipeRight = () => {
    if (monthDelta === LAST_MONTH_INDEX) {
      return;
    }

    dispatch(setMonthDeltaAction((monthDelta as number) + MONTH_DIFF));
    setAmountOfListItems(initialItemsToDisplay);
  };
  const handleShowMoreItems = () => setAmountOfListItems((prevAmount) => prevAmount + itemsToExpand);
  const handleResetItemsToShow = () => setAmountOfListItems(initialItemsToDisplay);
  const canFetchMore = amountOfListItems < list.length;
  const isExpanderVisible = initialItemsToDisplay < list.length;

  return {
    handleShowMoreItems,
    handleResetItemsToShow,
    canFetchMore,
    isExpanderVisible,
    handleOnSwipeLeft,
    handleOnSwipeRight,
    handleOnMonthChange,
    amountOfListItems,
  };
};
