import { subMonths } from 'date-fns';
import * as R from 'ramda';

import { PaperWithList } from 'components';
import { useUserContext } from 'App/context';
import { HighlightedColor, Categories } from 'App/types';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { common } from 'impactApp/translations/dictionary';

import { convertGramsToLargerUnits, formatFullDate, formatMonthAndYear, formatNumber, formatCurrency } from 'App/utils';
import { CurrencySymbol } from 'App/translations';
import { useHighestTransactionsListData } from 'impactApp/modules/HomePage';
import { homePage } from 'impactApp/translations';
import { CURRENT_DATE } from 'App/components';

import { Transaction } from '@ecolytiq/js-data-provider';

import { TransactionListItem } from './TransactionListItem';

const TRANSACTIONS_TO_DISPLAY = 3;

const generateItems = (transactions: Transaction[]) => {
  return transactions.map((transaction: Transaction, index) => {
    const {
      date,
      merchant,
      co2Footprint: { value: co2Value },
      id: transactionId,
      amount: { value, currencyCode },
      metaCategory,
    } = transaction;
    const { merchantPlaceholder } = common;
    const { TRANSACTIONS } = RoutePaths;
    const formatDate = formatFullDate(date);
    const formatCO2ToKg = convertGramsToLargerUnits(co2Value);
    const amountValue = formatCurrency(value, currencyCode as CurrencySymbol);
    const redirectUrl = `${TRANSACTIONS}/${transactionId}`;
    const footprint = `${formatNumber(+formatCO2ToKg[0])} ${formatCO2ToKg[1]}`;
    return (
      <TransactionListItem
        redirectURL={redirectUrl}
        key={transactionId}
        category={metaCategory as Categories}
        date={formatDate}
        description={merchant?.name || merchantPlaceholder}
        footprint={footprint}
        id={transactionId}
        value={amountValue}
        transaction={transaction}
        removeLastDivider={index !== transactions.length - 1}
      />
    );
  });
};

export const TransactionList = () => {
  const { ORANGE } = HighlightedColor;
  const [{ monthDelta }] = useUserContext();
  const currentDate = subMonths(CURRENT_DATE, monthDelta);
  const { transactions, isLoading } = useHighestTransactionsListData({
    month: currentDate.getMonth() + 1,
    year: currentDate.getFullYear(),
  });
  const date = currentDate.toISOString();
  const {
    transactionFootprint: { title },
  } = homePage;
  const subtitle = `in ${formatMonthAndYear(date)}`;

  return (
    <PaperWithList
      appearance={ORANGE}
      isLoading={isLoading}
      list={generateItems(R.take(TRANSACTIONS_TO_DISPLAY, transactions))}
      subtitle={subtitle}
      title={title}
    />
  );
};
