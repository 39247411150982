import { Navigate } from 'react-router-dom';

import { useUserContext } from 'App/context';

import { RoutePaths } from 'impactApp/routes/routePaths';
import { useCookies } from 'react-cookie';
import { appConfig } from 'App/appConfig/appConfig';
import { Config } from 'App/config';

type PrivateRouteProps = {
  element: JSX.Element;
};

export const PrivateRoute = ({ element }: PrivateRouteProps) => {
  const [{ isLoggedIn }] = useUserContext();
  const { SSO_PAGE } = RoutePaths;
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME } = appConfig;
  const [cookies] = useCookies();
  const { REACT_APP_MOCK_API } = Config.getAll();

  return isLoggedIn || cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME] || REACT_APP_MOCK_API ? (
    element
  ) : (
    <Navigate to={SSO_PAGE} />
  );
};
