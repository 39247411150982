import { Typography } from '@mui/material';

import { Grid } from 'components';
import { chart } from 'App/translations';

export const ChartWithoutData = () => {
  const { chartWithoutData: chartWithoutDataText } = chart;

  return (
    <Grid justifyContent='center' spacingInside={{ top: 12, right: 5, bottom: 12, left: 5 }}>
      <Typography color='textSecondary' variant='h6'>
        {chartWithoutDataText}
      </Typography>
    </Grid>
  );
};
