import { SVGProps, useEffect, useState } from 'react';

interface BusinessClassProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const BusinessClass = ({ props, isActive }: BusinessClassProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.74902 17.75C8.74902 16.0931 10.0922 14.75 11.749 14.75H28.249C29.9059 14.75 31.249 16.0931 31.249 17.75V26.75C31.249 28.4069 29.9059 29.75 28.249 29.75H11.749C10.0922 29.75 8.74902 28.4069 8.74902 26.75V17.75Z'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.749 14.75V29.75'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25.249 14.75V29.75'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M23.749 14.75V11.75C23.749 10.9216 23.0775 10.25 22.249 10.25H17.749C16.9206 10.25 16.249 10.9216 16.249 11.75V14.75'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.8'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
