import { Grid, Typography, useMediaQuery } from '@mui/material';

import { resetStateAction, TCContext, useUserContext } from 'App/context';

import { common } from 'impactApp/translations';

import { ButtonAppearance, Button, ListExpander } from 'components';

import { useContext, useEffect, useState } from 'react';

import { Collapse } from 'react-collapse';

import dompurify from 'dompurify';

import { ModalStyles } from 'components/Dialog/Dialog.styles';

import { useCookies } from 'react-cookie';

import { appConfig } from 'App/appConfig/appConfig';

import { theme, color } from 'App/style';

import { useLocation, useNavigate } from 'react-router-dom';

import { handleOpenedOverlay } from 'App/utils';

import { RoutePaths } from 'impactApp/routes/routePaths';

type TCModalProps = {
  isOpen: boolean;
  showButtons?: boolean;
  onClose?: () => void;
};

export const TCModal = ({ isOpen, showButtons, onClose }: TCModalProps) => {
  const { LOGOUT_PAGE } = RoutePaths;
  const navigate = useNavigate();
  const [, dispatch] = useUserContext();
  const [expanded, setExpanded] = useState(false);
  const { setIsTCOpen } = useContext(TCContext);
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } = appConfig;

  const [cookies, , removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);

  const handleLogout = () => {
    if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]) {
      removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
      removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
    }
    dispatch(resetStateAction());
    navigate(LOGOUT_PAGE);
  };

  const {
    tc: { title, content, contentExtended, accept, reject, slug },
    collapse,
    expand,
  } = common;
  const sanitizer = dompurify.sanitize;

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const onAccept = () => {
    if (setIsTCOpen) setIsTCOpen(true);
  };

  const location = useLocation();
  const CollapseElement: any = Collapse;

  useEffect(() => {
    if (isOpen) {
      handleOpenedOverlay(slug, location.pathname);
    }
  }, [isOpen, location.pathname, slug]);

  /* eslint-disable react/no-danger */
  return (
    <ModalStyles.Dialog open={isOpen} scroll='paper'>
      {!showButtons && onClose && (
        <ModalStyles.CloseButton
          hoverColor={color.darkHighlight}
          $closeIconColor={theme.palette.primary.main}
          onClick={onClose}
        />
      )}
      <ModalStyles.Content id='tc-dialog-content'>
        <ModalStyles.Title variant={isMd ? 'subtitle1' : 'subtitle3'}>{title}</ModalStyles.Title>
        <Typography variant='body2'>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(content),
            }}
          />
        </Typography>

        <CollapseElement isOpened={expanded}>
          <Typography variant='body2'>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(contentExtended),
              }}
            />
          </Typography>
        </CollapseElement>
        <ListExpander
          expandList={() => setExpanded(true)}
          shortenList={() => setExpanded(false)}
          collapseLabel={collapse}
          expandLabel={expand}
          canFetchMore={!expanded}
        />
        {showButtons && (
          <ModalStyles.Bottom>
            <Grid container justifyContent='center' spacing={2}>
              <Grid item justifyContent='flex-end'>
                <Button isLarge isDisabled={!expanded} onClick={onAccept} text={accept} id='tc-dialog-accept' />
              </Grid>
              <Grid item justifyContent='flex-start'>
                <Button isLarge appearance={ButtonAppearance.SECONDARY} onClick={handleLogout} text={reject} />
              </Grid>
            </Grid>
          </ModalStyles.Bottom>
        )}
      </ModalStyles.Content>
    </ModalStyles.Dialog>
  );
};
