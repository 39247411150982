import styled from 'styled-components/macro';

export const PrevMonthPercentageStyles = styled.div<{
  color?: string;
}>`
  color: ${({
    color,
    theme: {
      palette: {
        primary: { main: mainColor },
      },
    },
  }) => color || mainColor};
  margin-left: 4px;
  white-space: nowrap;
`;
