import { Button, Header, InnerContainer, LogoutButton, useModal } from 'components';
import { Grid, Container, useMediaQuery } from '@mui/material';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, Link as LinkComponent } from 'App/components';

import { common, homePage } from 'impactApp/translations';

import { color, theme } from 'App/style';

import { RoutePaths } from 'impactApp/routes';

import { HomePageStyles } from './HomePage.styles';

import { FootprintChart, FootprintList, HowItWorksModal, TransactionList } from './submodules';

export const HomePage = () => {
  const { Modal, openCallback: openModal } = useModal();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { LOGOUT_PAGE } = RoutePaths;

  return (
    <Layout
      bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT}
      bgColor='light'
      hideContainer
      header={
        <Header
          title={homePage.appName}
          isSemiBold
          transparent
          hideLeftButton
          showInfo
          showDesktopNavigation
          rightButton={<LogoutButton url={LOGOUT_PAGE} />}
        />
      }
    >
      <div>
        <HowItWorksModal Modal={Modal} />
        <FootprintChart />
        <HomePageStyles.PaperWrapper>
          <Container maxWidth='xl' disableGutters={!isMd}>
            <InnerContainer>
              <Grid
                container
                justifyContent='center'
                alignItems='flex-start'
                flexDirection={{ sm: 'column', md: 'row' }}
                columnSpacing={{ md: 3, lg: 7 }}
                py={{ md: 8, lg: 12 }}
              >
                <Grid item md={4} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }}>
                  <FootprintList />
                </Grid>
                <Grid item md={4} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }} px={{ xs: 3, md: 0 }}>
                  <Grid
                    flexDirection={{ sm: 'column', md: 'column-reverse' }}
                    container
                    justifyContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Grid item width='100%'>
                      {isMd && (
                        <HomePageStyles.LinkWrapper>
                          <LinkComponent text={common.link} onClick={() => openModal()} textColor={color.white} />
                        </HomePageStyles.LinkWrapper>
                      )}
                    </Grid>

                    <Grid item width='100%'>
                      <TransactionList />
                      {!isMd && (
                        <HomePageStyles.LinkWrapper>
                          <Button text={common.link} onClick={() => openModal()} />
                        </HomePageStyles.LinkWrapper>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </InnerContainer>
          </Container>
        </HomePageStyles.PaperWrapper>
      </div>
    </Layout>
  );
};
