export type UserState = {
  isMenuVisible: boolean;
  isLoggedIn: boolean;
  isFirstLogin: boolean;
  monthDelta: number;
  sessionToken: string;
  sessionRefreshToken: boolean;
};

export enum UserActions {
  TOGGLE_MENU_VISIBILITY = 'toggleMenuVisibility',
  SET_SESSION_TOKEN = 'setSessionToken',
  SET_SESSION_REFRESH_TOKEN = 'setSessionRefreshToken',
  SET_MONTH_DELTA = 'setMonthDelta',
  SET_IS_FIRST_LOGIN = 'setIsFirstLogin',
  SET_IS_LOGGED_IN = 'setIsLoggedIn',
  RESET_STATE = 'resetState',
}

export type UserAction =
  | { type: UserActions.TOGGLE_MENU_VISIBILITY }
  | { type: UserActions.SET_SESSION_TOKEN; sessionToken: string }
  | { type: UserActions.SET_SESSION_REFRESH_TOKEN; sessionRefreshToken: boolean }
  | { type: UserActions.SET_MONTH_DELTA; monthDelta: number }
  | { type: UserActions.SET_IS_LOGGED_IN; isLoggedIn: boolean }
  | { type: UserActions.SET_IS_FIRST_LOGIN; isFirstLogin: boolean }
  | { type: UserActions.RESET_STATE };
