import SlickSlider from 'react-slick';
import styled from 'styled-components/macro';

import { CloseIcon as Icon } from 'components/Icons';

const NAV_HEIGHT = '24px';
const NAV_OUTER_SPACING = '24px';

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  overflow: auto;
  background: linear-gradient(
    ${({ theme }) => `${theme.palette.primary.light}`} 55%,
    ${({ theme }) => `${theme.palette.primary.main}`} 45%
  );
  ${(props) => props.theme.breakpoints.up('lg')} {
    position: fixed;
    background: linear-gradient(
      ${({ theme }) => `${theme.palette.primary.light}`} 50%,
      ${({ theme }) => `${theme.palette.primary.main}`} 50%
    );
  }
`;

const Slider = styled(SlickSlider)`
  .slick-dots {
    display: flex !important;
    justify-content: center;
    height: ${NAV_HEIGHT};
    align-items: center;
    bottom: 5%;

    li {
      margin: 0;
    }

    button {
      &:before {
        font-size: 10px;
        color: ${({ theme }) => `${theme.palette.primary.dark}`};
      }
    }

    .slick-active {
      button {
        &:before {
          opacity: 1;
          color: ${({ theme }) => `${theme.palette.common.white}`};
        }
      }
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit;
    display: flex;
  }

  .slick-arrow {
    z-index: 2;
    position: absolute;
    bottom: 5%;

    &.slick-next,
    &.slick-prev {
      height: ${NAV_HEIGHT};
      width: ${NAV_HEIGHT};
      transform-origin: center;
      transform: none;
      top: auto;

      &:before {
        display: flex;
        align-items: center;
        height: ${NAV_HEIGHT};
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 12H22' stroke='white' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.6667 3L22 12L12.6667 21' stroke='white' stroke-width='1.8' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        opacity: 1;
      }
    }

    &.slick-next {
      right: ${NAV_OUTER_SPACING};
    }

    &.slick-prev {
      left: ${NAV_OUTER_SPACING};
      transform: rotate(180deg);
    }

    ${(props) => props.theme.breakpoints.up('lg')} {
      &.slick-next {
        right: 35%;
      }

      &.slick-prev {
        left: 35%;
      }
    }

    &.slick-disabled {
      opacity: 0;
    }
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    .slick-arrow {
      bottom: 13%;
    }

    .slick-dots {
      bottom: 2%;
    }
  }
`;

const CloseIcon = styled(Icon)`
  position: fixed;
  z-index: 3;
  top: 36px;
  right: 24px;
  cursor: pointer;
  line {
    stroke: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  z-index: 3;
  display: flex;
  justify-content: center;
  bottom: 20%;
  ${(props) => props.theme.breakpoints.up('lg')} {
    bottom: 14%;
  }
`;

export const ProfileSettingsSliderStyles = {
  Wrapper,
  Slider,
  CloseIcon,
  ButtonWrapper,
};
