import { SVGProps, useEffect, useState } from 'react';

interface EconomyClassProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const EconomyClass = ({ props, isActive }: EconomyClassProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M18.446 24.9301H10.6892C10.515 24.9301 10.3479 24.861 10.2247 24.7381C10.1014 24.6151 10.0322 24.4484 10.0322 24.2745V16.6556C10.0322 16.4817 10.1014 16.3149 10.2247 16.192C10.3479 16.0691 10.515 16 10.6892 16H18.446C18.6203 16 18.7874 16.0691 18.9106 16.192C19.0338 16.3149 19.103 16.4817 19.103 16.6556V24.2745C19.103 24.3606 19.086 24.4459 19.053 24.5254C19.02 24.6049 18.9716 24.6772 18.9106 24.7381C18.8496 24.7989 18.7772 24.8472 18.6975 24.8802C18.6178 24.9131 18.5323 24.9301 18.446 24.9301ZM11.3462 23.619H17.7891V17.3111H11.3462V23.619Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M28.2568 24.9999H23.5V23.6888H27.5999V17.3795H23.5V16.0684H28.2568C28.4311 16.0684 28.5982 16.1374 28.7214 16.2604C28.8446 16.3833 28.9138 16.55 28.9138 16.7239V24.3443C28.914 24.4305 28.8972 24.5158 28.8642 24.5954C28.8313 24.6751 28.7829 24.7474 28.7218 24.8083C28.6608 24.8692 28.5883 24.9175 28.5085 24.9504C28.4287 24.9833 28.3432 25.0001 28.2568 24.9999Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M24.0308 23.1981H21.2262C21.0826 23.1981 20.9448 23.1411 20.8432 23.0398C20.7416 22.9384 20.6846 22.8009 20.6846 22.6576V18.4111C20.6846 18.2677 20.7416 18.1303 20.8432 18.0289C20.9448 17.9275 21.0826 17.8706 21.2262 17.8706H24.0308V18.9515H22.9023H21.7737V22.1171H24.0367L24.0308 23.1981Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M23.4912 18.4837H23.2956C23.1526 18.4833 23.0155 18.4264 22.9145 18.3253C22.8135 18.2243 22.7568 18.0874 22.7568 17.9447V16.7676C22.7568 16.5822 22.8307 16.4043 22.9621 16.2732C23.0935 16.142 23.2718 16.0684 23.4576 16.0684H23.4912C23.6345 16.0684 23.7719 16.1251 23.8732 16.2262C23.9745 16.3273 24.0314 16.4644 24.0314 16.6074V17.9447C24.0314 18.0877 23.9745 18.2248 23.8732 18.3258C23.7719 18.4269 23.6345 18.4837 23.4912 18.4837Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M24.0314 24.9998H23.4576C23.3656 24.9998 23.2745 24.9817 23.1894 24.9466C23.1044 24.9115 23.0272 24.86 22.9621 24.795C22.897 24.7301 22.8454 24.653 22.8102 24.5682C22.775 24.4833 22.7568 24.3924 22.7568 24.3006V22.5859H24.0314V24.9998Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M10.6079 24.0999H4.7998V22.8266H10.1116V18.1732H4.7998V16.8999H10.6079C10.7383 16.8999 10.8635 16.967 10.9557 17.0864C11.048 17.2058 11.0998 17.3677 11.0998 17.5366V23.4632C11.0995 23.632 11.0476 23.7937 10.9554 23.913C10.8632 24.0323 10.7383 24.0995 10.6079 24.0999Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M35.0008 24.0999H28.3629C28.2139 24.0995 28.0711 24.0323 27.9658 23.913C27.8604 23.7937 27.8011 23.632 27.8008 23.4632V17.5366C27.8008 17.3677 27.86 17.2058 27.9654 17.0864C28.0708 16.967 28.2138 16.8999 28.3629 16.8999H35.0008V18.1732H28.925V22.8266H35.0008V24.0999Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M16.549 23.052H12.575C12.4007 23.052 12.2336 22.983 12.1104 22.86C11.9872 22.7371 11.918 22.5703 11.918 22.3965V18.3743C11.9178 18.2882 11.9346 18.2028 11.9676 18.1232C12.0005 18.0436 12.0489 17.9712 12.11 17.9103C12.171 17.8494 12.2435 17.8011 12.3233 17.7683C12.4031 17.7354 12.4886 17.7186 12.575 17.7188H16.5519C16.6383 17.7186 16.7238 17.7354 16.8036 17.7683C16.8834 17.8011 16.9559 17.8494 17.0169 17.9103C17.078 17.9712 17.1263 18.0436 17.1593 18.1232C17.1922 18.2028 17.2091 18.2882 17.2089 18.3743V22.3965C17.2089 22.4828 17.1918 22.5683 17.1586 22.648C17.1254 22.7278 17.0768 22.8001 17.0155 22.8611C16.9541 22.922 16.8814 22.9702 16.8013 23.003C16.7213 23.0357 16.6355 23.0524 16.549 23.052ZM13.229 21.7409H15.892V19.0299H13.229V21.7409Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
    </svg>
  );
};
