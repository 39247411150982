import { SVGProps } from 'react';

export const HouseIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#house)'>
      <path
        d='M3.75391 13.923V22.173H9.75391V16.173C9.75391 15.7752 9.91194 15.3936 10.1932 15.1123C10.4746 14.831 10.8561 14.673 11.2539 14.673H12.7539C13.1517 14.673 13.5333 14.831 13.8146 15.1123C14.0959 15.3936 14.2539 15.7752 14.2539 16.173V22.173H20.2539V13.923M0.753906 12.423L10.9439 2.23398C11.2252 1.95277 11.6067 1.7948 12.0044 1.7948C12.4022 1.7948 12.7836 1.95277 13.0649 2.23398L23.2539 12.423M16.5039 5.67298V4.17298H20.2539V9.42298'
        stroke='#2D424D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </g>
    <defs>
      <clipPath id='house'>
        <rect fill='white' height='24' width='24' />
      </clipPath>
    </defs>
  </svg>
);
