import ReactDOM from 'react-dom/client';

import { App } from 'App';
import { AppsSchema } from 'App/types';
import { AppWithMockedApi as VancityApp } from 'impactApp/App';

const Apps: AppsSchema = [
  {
    id: 'vancity',
    Component: VancityApp,
  },
];

const renderApp = () => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App apps={Apps} />);
};

renderApp();
