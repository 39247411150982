import { ExpandMoreIcon, ExpandLessIcon } from 'components/Icons';
import { common } from 'App/translations';

import { ListExpanderStyles } from './ListExpander.styles';

type ListExpanderProps = {
  canFetchMore?: boolean;
  expandList?: () => void;
  shortenList?: () => void;
  collapseLabel?: string;
  expandLabel?: string;
};

export const ListExpander = ({
  canFetchMore,
  expandList,
  shortenList,
  collapseLabel,
  expandLabel,
}: ListExpanderProps) => {
  const { expand: expandCopy, shrink: shortenCopy } = common;

  return canFetchMore ? (
    <ListExpanderStyles.Content alignItems='center' justifyContent='center' onClick={expandList}>
      <ListExpanderStyles.ButtonText component='span' variant='subtitle2'>
        {expandLabel || expandCopy}
      </ListExpanderStyles.ButtonText>
      <ExpandMoreIcon />
    </ListExpanderStyles.Content>
  ) : (
    <ListExpanderStyles.Content alignItems='center' justifyContent='center' onClick={shortenList}>
      <ListExpanderStyles.ButtonText component='span' variant='subtitle2'>
        {collapseLabel || shortenCopy}
      </ListExpanderStyles.ButtonText>
      <ExpandLessIcon />
    </ListExpanderStyles.Content>
  );
};
