import { Typography } from '@mui/material';
import styled from 'styled-components/macro';

const Value = styled(Typography)`
  padding-right: 8px;
`;

export const ComparisonStyles = {
  Value,
};
