import { CustomPalette } from 'App/types';
import styled, { css } from 'styled-components/macro';

export const LayoutStyles = styled.div<{
  src?: string;
  $bgHeight?: string;
  bgColor: string;
  fullHeight?: boolean;
}>`
  background: ${({
    theme: {
      palette: {
        custom: { layout },
      },
    },
    bgColor,
  }) => layout[bgColor as keyof CustomPalette['custom']['layout']]};
  ${({ src }) =>
    src &&
    css`
      background-image: url(${src});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left top;
    `};

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    `};
`;
