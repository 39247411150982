import { FC, useEffect, useRef, useState, SVGProps } from 'react';

export const useImportSVG = (name: string) => {
  const iconRef = useRef<FC<SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const importIcon = async () => {
      try {
        setLoading(true);
        iconRef.current = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../assets/icons/${name}.svg`)).default;
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    importIcon();

    return () => {
      iconRef.current = undefined;
    };
  }, [name]);

  return { error, loading, SvgIcon: iconRef.current };
};
