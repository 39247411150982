import { Button } from '../../../components/Button/Button';

type LoginButtonProps = {
  button: string;
  setIsSliderVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoginButton = ({ button, setIsSliderVisible }: LoginButtonProps) => {
  return <Button onClick={() => setIsSliderVisible(true)} iconRight text={button} />;
};
