import { SVGProps } from 'react';

export const CategoryLiving = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='240' height='240' viewBox='0 0 240 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_896_14718)'>
      <g style={{ mixBlendMode: 'multiply' }} opacity='0.3'>
        <ellipse cx='134.5' cy='226.559' rx='87.5' ry='10' fill='#C4C4C4' />
      </g>
      <path
        d='M209.086 15.381C208.423 13.9341 206.766 13.0113 205.182 13.218C203.422 11.6678 200.549 11.5792 198.693 13.0187C197.352 12.1255 195.533 12 194.081 12.7013C192.63 13.4026 191.592 14.8938 191.444 16.5031C189.846 16.1635 188.078 16.9534 187.253 18.3634C186.428 19.7733 186.62 21.7074 187.695 22.9329C185.913 23.4127 184.668 25.3616 184.962 27.185C185.264 29.0083 187.069 30.4478 188.911 30.3371L208.15 19.7438C209.344 18.6882 209.734 16.8205 209.071 15.3736L209.086 15.381Z'
        fill='#E0E0E0'
      />
      <path
        d='M188.351 30.8464C189.434 22.5785 196.667 16.5251 204.976 16.9385L205.801 16.9828C214.684 17.9499 218.824 26.048 217.616 34.9139L214.854 64.5752L214.706 66.0147L193.949 69.1226L194.354 61.5485C194.354 61.5485 185.861 59.7916 186.178 51.2062C186.333 47.0575 187.342 38.5459 188.351 30.8538V30.8464Z'
        fill='#EDBE9E'
      />
      <path
        opacity='0.3'
        d='M194.288 61.5263C194.288 61.5263 199.739 62.1316 205.668 58.7949C205.668 58.7949 202.722 64.8409 194.221 63.6376L194.288 61.5263Z'
        fill='black'
      />
      <path
        d='M190.207 39.1881C190.155 39.8304 190.649 40.4135 191.327 40.48C191.997 40.5464 192.586 40.0814 192.638 39.4391C192.689 38.7969 192.196 38.2137 191.518 38.1399C190.848 38.0734 190.259 38.5385 190.2 39.1807L190.207 39.1881Z'
        fill='#263238'
      />
      <path
        d='M189.979 35.1353C190.119 35.3051 191.084 34.6629 192.395 34.7293C193.706 34.781 194.641 35.5044 194.789 35.3494C194.862 35.283 194.722 34.9877 194.317 34.6555C193.919 34.3307 193.227 33.9985 192.409 33.9616C191.592 33.9247 190.885 34.1904 190.472 34.4857C190.052 34.7736 189.898 35.0615 189.971 35.1353H189.979Z'
        fill='#263238'
      />
      <path
        d='M201.816 39.978C201.764 40.6202 202.258 41.2034 202.935 41.2698C203.606 41.3363 204.195 40.8712 204.254 40.229C204.305 39.5867 203.805 39.0035 203.134 38.9371C202.464 38.8707 201.875 39.3357 201.816 39.978Z'
        fill='#263238'
      />
      <path
        d='M202.899 36.0286C203.046 36.1984 204.003 35.5562 205.315 35.6226C206.626 35.6743 207.561 36.3977 207.709 36.2427C207.782 36.1763 207.642 35.881 207.237 35.5488C206.839 35.224 206.147 34.8918 205.329 34.8549C204.512 34.818 203.805 35.0837 203.392 35.379C202.972 35.6669 202.818 35.9548 202.891 36.0286H202.899Z'
        fill='#263238'
      />
      <path
        d='M197.639 45.5662C197.647 45.4924 196.836 45.3078 195.525 45.0716C195.194 45.0199 194.877 44.9387 194.833 44.7025C194.767 44.4589 194.921 44.0972 195.105 43.7133C195.459 42.916 195.835 42.0745 196.225 41.196C197.787 37.6157 198.936 34.6629 198.788 34.6038C198.641 34.5448 197.264 37.3942 195.702 40.9746C195.326 41.8604 194.965 42.702 194.619 43.5066C194.472 43.8831 194.236 44.3039 194.376 44.8206C194.457 45.079 194.693 45.2709 194.907 45.3374C195.12 45.4112 195.304 45.426 195.474 45.4481C196.807 45.5884 197.632 45.6327 197.639 45.5588V45.5662Z'
        fill='#263238'
      />
      <path
        d='M201.47 46.6736C201.256 46.6514 201.182 48.0836 199.908 49.0359C198.634 49.9955 197.124 49.7593 197.102 49.9586C197.079 50.0472 197.426 50.2539 198.067 50.3056C198.693 50.3573 199.628 50.2022 200.402 49.619C201.175 49.0359 201.543 48.2165 201.617 47.6333C201.698 47.0353 201.558 46.6736 201.462 46.681L201.47 46.6736Z'
        fill='#263238'
      />
      <path
        d='M202.818 30.8464C202.928 31.2081 204.254 31.1048 205.786 31.3706C207.326 31.5994 208.548 32.1161 208.769 31.8061C208.865 31.6585 208.666 31.3115 208.18 30.935C207.701 30.5659 206.92 30.1968 206 30.0418C205.079 29.8941 204.224 29.9975 203.65 30.2042C203.075 30.4035 202.773 30.6693 202.818 30.8464Z'
        fill='#E0E0E0'
      />
      <path
        d='M190.656 31.3483C190.87 31.6583 191.791 31.3926 192.888 31.4295C193.978 31.4295 194.892 31.7322 195.12 31.4295C195.223 31.2819 195.091 30.9718 194.7 30.6692C194.317 30.3665 193.662 30.1007 192.91 30.086C192.159 30.0712 191.496 30.3148 191.106 30.6027C190.708 30.8906 190.561 31.1933 190.656 31.3483Z'
        fill='#E0E0E0'
      />
      <path
        d='M189.367 26.956C189.367 26.956 191.894 21.6926 194.538 20.5705C197.742 19.2048 198.921 22.7187 201.941 22.0322C204.961 21.3456 208.025 20.039 210.957 22.4972C214.169 25.1917 212.519 26.8896 212.533 30.7061C212.607 46.179 215.2 50.6821 216.356 43.455C216.481 42.6651 218.721 42.141 219.214 38.7895C219.855 34.4488 220.282 29.1559 219.465 26.0111C218.772 23.3609 217.38 21.4859 214.817 19.3598C210.773 16.0158 204.998 15.654 201.057 15.8607C197.116 16.06 189.33 20.2088 189.36 26.9486L189.367 26.956Z'
        fill='#E0E0E0'
      />
      <path
        d='M215.833 43.1966C215.951 41.8752 217.144 40.886 218.455 41.0779C220.047 41.3068 221.778 42.3034 221.505 45.6105C221.026 51.4645 215.259 49.7814 215.266 49.6116C215.266 49.4935 215.612 45.6548 215.833 43.204V43.1966Z'
        fill='#EDBE9E'
      />
      <path
        opacity='0.3'
        d='M216.71 47.5077C216.71 47.5077 216.805 47.5889 216.975 47.6775C217.137 47.7661 217.417 47.8473 217.734 47.7587C218.367 47.5815 218.971 46.644 219.082 45.5884C219.141 45.0569 219.067 44.5401 218.912 44.1046C218.772 43.6616 218.514 43.3368 218.212 43.2556C217.91 43.1597 217.675 43.3295 217.586 43.4845C217.498 43.6395 217.52 43.765 217.491 43.7724C217.476 43.7871 217.373 43.6616 217.439 43.4254C217.476 43.3147 217.549 43.1818 217.697 43.0785C217.844 42.9751 218.058 42.9308 218.271 42.9751C218.721 43.0415 219.089 43.4919 219.258 43.9717C219.45 44.4515 219.546 45.0347 219.479 45.6327C219.347 46.8064 218.654 47.8621 217.785 48.0392C217.365 48.113 217.034 47.958 216.864 47.8104C216.695 47.648 216.673 47.5151 216.695 47.5077H216.71Z'
        fill='black'
      />
      <path
        d='M216.283 31.8282C215.494 32.3081 214.669 32.8101 213.749 32.8544C212.828 32.8986 211.804 32.3228 211.701 31.4001C211.649 30.9867 211.775 30.5216 211.524 30.1894C211.222 29.7982 210.625 29.8867 210.132 29.9163C208.423 30.0122 206.736 28.7204 206.39 27.0373C205.926 27.6647 204.909 27.5909 204.276 27.1332C203.643 26.6755 203.252 25.9595 202.766 25.3468C202.28 24.734 201.587 24.1656 200.807 24.2394C200.372 24.2764 199.952 24.52 199.518 24.4535C198.752 24.3502 198.42 23.4274 198.346 22.6523C198.265 21.8846 198.221 20.9839 197.595 20.541C197.013 20.1276 196.218 20.3122 195.503 20.3786C194.789 20.4451 193.883 20.2384 193.735 19.5371C193.662 19.168 193.831 18.7915 194.052 18.4888C195.032 17.0936 197.05 16.5547 198.597 17.2708C199.267 15.3588 201.145 13.9414 203.164 13.8307C205.182 13.72 207.208 14.9159 208.084 16.7392C209.381 15.6762 211.2 15.2924 212.806 15.75C214.419 16.2004 215.774 17.4775 216.319 19.0646C217.763 18.7619 219.354 19.3894 220.201 20.6001C221.048 21.8107 221.085 23.5308 220.304 24.7857C221.328 25.4427 222.175 26.5353 222.138 27.7459C222.102 28.964 220.857 30.1082 219.708 29.7317C220.135 30.6988 219.686 31.9611 218.743 32.4483C217.8 32.9282 216.518 32.5591 215.981 31.6437'
        fill='#E0E0E0'
      />
      <path
        d='M220.135 29.7392C220.135 29.7392 220.216 30.2411 219.715 30.8465C219.465 31.1344 219.052 31.4297 218.507 31.4887C217.977 31.5478 217.336 31.3927 216.864 30.9203L217.115 30.8981C216.798 31.3854 216.393 31.8873 215.877 32.3007C215.546 32.5665 215.178 32.788 214.78 32.9135C214.389 33.0463 213.947 33.039 213.594 32.9651C212.857 32.847 212.231 32.4115 211.951 31.9095C211.664 31.4149 211.583 30.9646 211.539 30.6619C211.495 30.3593 211.495 30.1895 211.517 30.1821C211.539 30.1821 211.583 30.3445 211.664 30.6324C211.752 30.9203 211.863 31.3485 212.15 31.7766C212.666 32.6477 214.448 33.1644 215.664 32.035C216.143 31.6437 216.54 31.1787 216.842 30.721L216.953 30.5512L217.093 30.6914C217.476 31.0901 218.021 31.2451 218.485 31.2156C218.949 31.186 219.325 30.9498 219.575 30.7062C220.069 30.1968 220.091 29.717 220.142 29.7318L220.135 29.7392Z'
        fill='white'
      />
      <path
        d='M209.742 15.7944C209.742 15.7944 209.587 15.8313 209.322 15.9273C209.064 16.0233 208.673 16.23 208.371 16.6581L208.298 16.7689L208.224 16.6581C207.716 15.8978 206.847 14.9012 205.506 14.222C204.843 13.8898 204.062 13.6389 203.23 13.6019C202.398 13.5576 201.514 13.757 200.718 14.1851C200.099 14.5247 199.569 14.9824 199.171 15.5139C198.781 16.0454 198.531 16.6507 198.442 17.2487L198.413 17.4332L198.236 17.3742C197.367 17.0863 196.461 17.2118 195.79 17.5514C195.105 17.8836 194.656 18.4224 194.391 18.917C193.868 19.9284 194 20.6445 193.942 20.6371C193.927 20.6371 193.89 20.4747 193.897 20.1572C193.905 19.8398 193.971 19.3674 194.229 18.8358C194.479 18.3117 194.943 17.7064 195.68 17.3299C196.402 16.946 197.374 16.791 198.339 17.0937L198.14 17.2192C198.229 16.5622 198.494 15.9052 198.914 15.3294C199.341 14.7535 199.901 14.2589 200.564 13.8972C201.411 13.4322 202.361 13.2255 203.252 13.2845C204.143 13.3362 204.961 13.6093 205.646 13.971C207.031 14.7093 207.893 15.7649 208.379 16.5695H208.231C208.585 16.1119 209.012 15.9273 209.3 15.8535C209.587 15.7797 209.749 15.8018 209.749 15.8166L209.742 15.7944Z'
        fill='white'
      />
      <path
        opacity='0.3'
        d='M203.436 47.626C203.341 47.626 203.333 46.9985 202.862 46.467C202.405 45.9133 201.801 45.81 201.808 45.714C201.808 45.6697 201.978 45.6328 202.243 45.6919C202.508 45.7509 202.854 45.9281 203.12 46.2455C203.392 46.563 203.51 46.9321 203.525 47.1978C203.539 47.471 203.473 47.626 203.436 47.6186V47.626Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M204.04 46.0314C203.959 46.0757 203.753 45.8616 203.451 45.7066C203.149 45.5442 202.862 45.4999 202.847 45.4039C202.825 45.3153 203.201 45.1972 203.606 45.4039C204.011 45.6106 204.121 45.9945 204.04 46.024V46.0314Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M210.346 60.1017C210.346 60.1017 210.434 60.0648 210.508 59.9541C210.581 59.8507 210.648 59.6514 210.544 59.4373C210.463 59.2085 210.206 59.0018 210.044 58.6105C209.962 58.426 209.948 58.1381 210.088 57.924C210.213 57.7173 210.382 57.5844 210.485 57.4442C210.964 56.8831 209.653 56.418 209.859 55.6798C209.904 55.3698 210.073 55.1631 210.206 55.0671C210.346 54.9712 210.441 54.9638 210.449 54.9785C210.485 55.0155 210.125 55.1926 210.102 55.6946C210.088 55.9456 210.228 56.1597 210.5 56.4033C210.625 56.5288 210.787 56.6838 210.876 56.9053C210.986 57.1267 210.927 57.4589 210.758 57.6361C210.611 57.828 210.456 57.9461 210.375 58.079C210.294 58.2045 210.294 58.3374 210.346 58.485C210.441 58.7803 210.692 59.0313 210.78 59.334C210.883 59.644 210.751 59.9245 210.611 60.0279C210.463 60.1386 210.346 60.0943 210.353 60.0796L210.346 60.1017Z'
        fill='black'
      />
      <path
        opacity='0.3'
        d='M212.172 59.9098C212.172 59.9098 212.15 59.8212 212.106 59.6957C212.062 59.5628 211.959 59.4004 211.804 59.1863C211.73 59.0756 211.642 58.9575 211.568 58.8025C211.495 58.6474 211.45 58.4334 211.509 58.2267C211.627 57.8059 211.981 57.5623 212.158 57.2596C212.356 56.9643 212.202 56.7059 211.966 56.4402C211.856 56.3073 211.738 56.1671 211.671 55.9973C211.605 55.8275 211.59 55.6651 211.605 55.5174C211.686 54.9047 212.099 54.7497 212.099 54.7866C212.143 54.8235 211.841 55.0376 211.841 55.5248C211.841 55.7684 211.951 55.9899 212.202 56.2335C212.312 56.359 212.46 56.5066 212.541 56.7281C212.629 56.9569 212.57 57.2375 212.445 57.4294C212.202 57.8133 211.885 58.0347 211.804 58.3005C211.716 58.5515 211.878 58.8246 212.003 59.0535C212.135 59.2823 212.224 59.4964 212.231 59.6662C212.231 59.8286 212.187 59.9098 212.172 59.9024V59.9098Z'
        fill='black'
      />
      <path
        d='M90.3837 201.661L78.9664 228L46.593 213.036C48.2062 210.025 67.8144 211.545 67.8144 211.545L74.9078 194.559L90.3763 201.661H90.3837Z'
        fill='#F69B8F'
      />
      <path
        d='M78.4727 214.764C79.0178 215.222 79.1282 216.13 78.6936 216.698C78.2591 217.266 77.3383 217.384 76.7859 216.934C76.2334 216.484 76.064 215.399 76.5649 214.882C77.0658 214.365 78.1486 214.291 78.5832 214.86'
        fill='#E0E0E0'
      />
      <path
        d='M78.9662 228L80.2553 225.276L48.5816 212.084C48.5816 212.084 46.8506 212.055 46.556 213.155L78.9736 228.007L78.9662 228Z'
        fill='#E0E0E0'
      />
      <path
        d='M67.3722 211.139C67.3059 211.302 68.0646 211.737 68.6023 212.608C69.1695 213.465 69.2505 214.336 69.4273 214.336C69.5893 214.373 69.7882 213.317 69.1105 212.276C68.4402 211.228 67.4016 210.977 67.3648 211.139H67.3722Z'
        fill='#E0E0E0'
      />
      <path
        d='M63.3283 210.977C63.2252 211.117 63.785 211.7 64.0133 212.616C64.2711 213.524 64.0722 214.314 64.2343 214.38C64.3742 214.476 64.9046 213.597 64.6026 212.461C64.308 211.324 63.4093 210.829 63.3356 210.977H63.3283Z'
        fill='#E0E0E0'
      />
      <path
        d='M59.5274 214.159C59.6526 214.262 60.2419 213.568 60.2345 212.512C60.2345 211.457 59.6453 210.77 59.52 210.874C59.3727 210.962 59.6379 211.649 59.6305 212.52C59.6453 213.383 59.3801 214.077 59.5274 214.166V214.159Z'
        fill='#E0E0E0'
      />
      <path
        d='M69.4125 207.448C69.4125 207.625 70.2375 207.743 71.1067 208.216C71.9833 208.674 72.5357 209.294 72.683 209.198C72.8303 209.124 72.4178 208.223 71.394 207.684C70.3701 207.131 69.3978 207.293 69.4199 207.456L69.4125 207.448Z'
        fill='#E0E0E0'
      />
      <path
        d='M71.9538 203.956C71.9243 204.023 72.4989 204.407 73.5817 204.695C74.1194 204.835 74.7749 204.946 75.5189 204.953C75.8872 204.953 76.2776 204.938 76.6754 204.887C76.8669 204.857 77.0879 204.842 77.3383 204.732C77.5887 204.65 77.8834 204.274 77.795 203.92C77.7434 203.595 77.5004 203.351 77.272 203.24C77.0363 203.122 76.8227 203.078 76.6238 203.041C76.226 202.96 75.8283 202.923 75.46 202.923C74.7087 202.923 74.0457 203.041 73.5154 203.218C72.4399 203.565 71.917 204.038 71.9538 204.097C71.998 204.185 72.5946 203.853 73.6259 203.632C74.1415 203.521 74.7602 203.447 75.4452 203.484C75.7914 203.499 76.145 203.55 76.5059 203.624C76.889 203.691 77.1836 203.824 77.1983 204.016C77.1983 204.089 77.1983 204.111 77.0805 204.178C76.9626 204.237 76.7859 204.267 76.5943 204.289C76.2187 204.34 75.8577 204.37 75.5115 204.385C74.8191 204.407 74.193 204.348 73.6701 204.259C72.6241 204.089 71.9906 203.853 71.9538 203.942V203.956Z'
        fill='#E0E0E0'
      />
      <path
        d='M72.057 204.274C72.1012 204.326 72.6757 203.905 73.2061 202.901C73.4639 202.406 73.6996 201.764 73.8027 201.018C73.8543 200.649 73.869 200.251 73.8469 199.845C73.8027 199.439 73.7732 198.9 73.1913 198.545C72.8746 198.383 72.4916 198.516 72.3074 198.686C72.1012 198.855 71.9907 199.033 71.8654 199.202C71.6297 199.557 71.4677 199.926 71.3646 200.295C71.1509 201.033 71.1657 201.734 71.2835 202.295C71.5266 203.425 72.1012 203.92 72.138 203.875C72.2264 203.824 71.8139 203.263 71.7034 202.229C71.6445 201.72 71.6813 201.1 71.8949 200.465C71.998 200.147 72.1527 199.83 72.359 199.542C72.5652 199.217 72.823 199.003 72.9409 199.099C73.1029 199.173 73.2576 199.557 73.265 199.896C73.2944 200.265 73.2944 200.62 73.265 200.959C73.1987 201.638 73.0293 202.244 72.8304 202.724C72.44 203.698 71.998 204.215 72.0717 204.274H72.057Z'
        fill='#E0E0E0'
      />
      <path
        d='M172.057 135.466C170.687 136.115 102.876 134.122 101.167 136.499C98.707 139.91 67.7479 205.898 67.7479 205.898L86.1996 215.583L115.862 162.986L190.141 162.824C190.141 162.824 198.545 162.381 199.569 151.588L172.065 135.466H172.057Z'
        fill='#263238'
      />
      <path
        d='M83.5847 178.931L76.5575 200.886L44.0368 188.506C45.4069 185.464 64.6026 185.619 64.6026 185.619L68.6097 173.786L83.5847 178.924V178.931Z'
        fill='#F69B8F'
      />
      <path
        d='M75.1949 188.033C75.762 188.447 75.9241 189.318 75.5337 189.901C75.1433 190.484 74.2594 190.661 73.6922 190.255C73.125 189.857 72.8893 188.808 73.3386 188.277C73.788 187.745 74.8413 187.59 75.298 188.122'
        fill='#E0E0E0'
      />
      <path
        d='M76.5576 200.886L77.633 198.147L45.9004 187.435C45.9004 187.435 44.2136 187.524 44 188.609L76.5576 200.886Z'
        fill='#E0E0E0'
      />
      <path
        d='M64.1385 185.25C64.087 185.413 64.853 185.789 65.4349 186.594C66.0463 187.391 66.1863 188.233 66.3557 188.225C66.5104 188.247 66.6356 187.207 65.9064 186.239C65.1845 185.265 64.1533 185.088 64.1312 185.25H64.1385Z'
        fill='#E0E0E0'
      />
      <path
        d='M60.1904 185.368C60.0946 185.509 60.6839 186.04 60.9638 186.919C61.2732 187.79 61.1332 188.565 61.2953 188.624C61.4352 188.705 61.8993 187.812 61.5236 186.727C61.1627 185.642 60.2493 185.213 60.1904 185.368Z'
        fill='#E0E0E0'
      />
      <path
        d='M56.6989 188.72C56.8315 188.808 57.3545 188.1 57.2735 187.074C57.1998 186.048 56.5884 185.42 56.4706 185.523C56.3306 185.619 56.6326 186.269 56.6842 187.118C56.7579 187.96 56.5442 188.646 56.6989 188.727V188.72Z'
        fill='#E0E0E0'
      />
      <path
        d='M65.8769 181.522C65.8917 181.692 66.7019 181.751 67.5711 182.15C68.455 182.534 69.0369 183.102 69.1695 182.999C69.3021 182.918 68.8454 182.069 67.8068 181.611C66.7756 181.146 65.8401 181.367 65.8696 181.522H65.8769Z'
        fill='#E0E0E0'
      />
      <path
        d='M68.1162 177.949C68.0867 178.016 68.676 178.355 69.7514 178.562C70.2818 178.665 70.93 178.725 71.6518 178.688C72.0128 178.665 72.3884 178.621 72.7788 178.547C72.963 178.51 73.1766 178.481 73.4197 178.348C73.6554 178.252 73.9205 177.868 73.8101 177.529C73.7364 177.211 73.486 176.997 73.2502 176.901C73.0145 176.805 72.8009 176.776 72.6094 176.754C72.2116 176.702 71.8286 176.694 71.4677 176.717C70.7384 176.761 70.0976 176.931 69.5894 177.13C68.5729 177.543 68.0867 178.038 68.1309 178.09C68.1825 178.171 68.7423 177.809 69.7293 177.529C70.2228 177.388 70.8195 177.278 71.4898 177.263C71.8212 177.263 72.1748 177.278 72.5357 177.329C72.9114 177.366 73.206 177.477 73.2355 177.669C73.2429 177.743 73.2355 177.765 73.1324 177.839C73.0219 177.905 72.8451 177.942 72.6683 177.979C72.3074 178.06 71.9612 178.112 71.6224 178.141C70.9447 178.208 70.3333 178.193 69.8177 178.141C68.7865 178.045 68.1604 177.861 68.1309 177.942L68.1162 177.949Z'
        fill='#E0E0E0'
      />
      <path
        d='M68.2414 178.259C68.2856 178.304 68.8159 177.853 69.2652 176.849C69.4862 176.348 69.6704 175.705 69.7219 174.974C69.744 174.613 69.7367 174.221 69.6851 173.823C69.6188 173.432 69.5451 172.907 68.9559 172.605C68.6391 172.472 68.2782 172.62 68.1014 172.804C67.9099 172.981 67.8141 173.166 67.711 173.336C67.5048 173.69 67.3722 174.066 67.2912 174.436C67.1291 175.166 67.1954 175.853 67.3501 176.384C67.6668 177.47 68.2561 177.913 68.2929 177.868C68.3739 177.809 67.9394 177.292 67.7626 176.296C67.6742 175.801 67.6594 175.196 67.8289 174.568C67.9099 174.251 68.0351 173.934 68.2193 173.638C68.4034 173.313 68.6391 173.085 68.7496 173.166C68.9117 173.225 69.0884 173.594 69.1179 173.919C69.1768 174.273 69.1916 174.627 69.1842 174.96C69.1621 175.624 69.0369 176.222 68.8822 176.709C68.5655 177.684 68.1677 178.215 68.2487 178.274L68.2414 178.259Z'
        fill='#E0E0E0'
      />
      <path
        d='M84.7927 188.225L63.8145 180.651L83.2385 120.044C85.8976 113.504 92.8879 109.85 99.7677 111.407L162.813 125.662L163.94 135.436L102.891 136.492L84.8001 188.225H84.7927Z'
        fill='#263238'
      />
      <path
        d='M63.807 180.659C63.807 180.659 63.9396 180.688 64.1827 180.769C64.4479 180.858 64.8088 180.983 65.2802 181.146C66.2599 181.485 67.6668 181.987 69.4715 182.622C73.125 183.922 78.3696 185.797 84.8295 188.1L84.6454 188.188C88.5346 177.411 93.7129 163.038 99.392 147.299C100.136 145.254 100.865 143.224 101.558 141.32V141.305L101.565 141.297C102.058 140.352 102.699 139.496 103.458 138.765C104.224 138.049 105.101 137.466 106.043 137.045C106.986 136.632 107.995 136.374 109.005 136.285C110.021 136.218 110.993 136.248 111.973 136.226C115.877 136.182 119.626 136.13 123.191 136.086C130.314 136.004 136.686 135.938 142.026 135.879C147.344 135.835 151.631 135.798 154.607 135.768C156.073 135.768 157.215 135.761 158.01 135.753C158.386 135.753 158.681 135.753 158.894 135.753C159.093 135.753 159.196 135.761 159.196 135.768C159.196 135.776 159.093 135.783 158.894 135.79C158.681 135.79 158.386 135.805 158.01 135.82C157.215 135.842 156.073 135.864 154.615 135.901C151.639 135.953 147.352 136.034 142.034 136.13C136.693 136.211 130.322 136.314 123.199 136.425C119.634 136.477 115.884 136.529 111.98 136.588C111.008 136.61 110.006 136.588 109.041 136.654C108.069 136.743 107.112 136.994 106.198 137.392C104.386 138.197 102.832 139.651 101.904 141.475V141.452C101.226 143.357 100.49 145.38 99.753 147.432C94.037 163.163 88.8219 177.521 84.9179 188.292L84.8663 188.425L84.7338 188.373C78.2885 186.018 73.0587 184.106 69.4125 182.777C67.6153 182.113 66.2157 181.589 65.2434 181.234C64.7794 181.057 64.4184 180.924 64.1606 180.821C63.9175 180.725 63.7997 180.666 63.7997 180.666L63.807 180.659Z'
        fill='#455A64'
      />
      <g opacity='0.3'>
        <path
          d='M84.7927 188.225C83.4816 187.768 82.3546 187.332 81.0434 186.874C80.7414 187.509 80.6751 188.262 80.3658 188.897L79.9385 190.27C80.9771 190.728 82.3177 191.156 83.4005 191.518C84.4833 191.879 85.9271 191.791 86.8625 191.141C87.8569 190.462 88.3136 189.082 88.6967 187.937C90.1993 183.412 91.6946 178.887 93.1973 174.362C95.768 166.618 98.0441 157.752 98.8838 149.639C93.8749 162.063 89.0944 175.543 84.7853 188.225H84.7927Z'
          fill='black'
        />
      </g>
      <path
        d='M215.03 62.7297L194.089 66.5241C193.522 67.1738 184.388 81.1333 182.458 85.3854C180.572 89.5415 173.95 112.655 173.95 112.655L159.204 126.718L166.349 137.532C168.676 141.061 171.917 143.903 175.726 145.749C182.48 149.034 191.157 152.946 194.14 152.946C196.858 152.946 199.68 151.809 199.709 151.802L199.753 151.787L199.775 151.743C200.011 151.285 221.512 105.302 222.846 91.2763C224.429 74.6075 215.141 62.8995 215.023 62.7297H215.03Z'
        fill='#F69B8F'
      />
      <path
        d='M190.472 105.738C190.472 105.782 189.699 105.871 188.447 105.871C187.194 105.871 185.456 105.767 183.563 105.465C181.67 105.155 179.99 104.697 178.805 104.298C177.619 103.9 176.911 103.575 176.926 103.531C176.948 103.479 177.685 103.708 178.886 104.04C180.086 104.372 181.758 104.786 183.629 105.088C185.5 105.391 187.216 105.539 188.454 105.605C189.699 105.671 190.472 105.686 190.472 105.745V105.738Z'
        fill='white'
      />
      <path
        d='M197.978 77.5529C197.963 77.6489 195.952 77.2134 193.824 75.892C191.688 74.5853 190.391 72.9834 190.465 72.9317C190.546 72.8505 191.938 74.2974 194.023 75.5671C196.1 76.8516 198.015 77.4422 197.978 77.5529Z'
        fill='white'
      />
      <path
        d='M200.866 146.686C200.866 146.686 200.505 146.672 199.864 146.568C199.223 146.472 198.302 146.28 197.183 145.985C196.056 145.697 194.744 145.276 193.308 144.73C191.879 144.169 190.347 143.468 188.793 142.619C187.246 141.755 185.832 140.825 184.602 139.917C183.379 138.994 182.318 138.101 181.478 137.304C180.631 136.506 179.983 135.835 179.563 135.34C179.136 134.853 178.908 134.572 178.93 134.558C178.952 134.543 179.21 134.786 179.674 135.237C180.123 135.694 180.808 136.337 181.663 137.104C182.517 137.872 183.585 138.736 184.815 139.636C186.053 140.522 187.452 141.438 188.984 142.287C190.524 143.128 192.041 143.829 193.448 144.405C194.862 144.966 196.159 145.409 197.264 145.727C198.369 146.051 199.275 146.28 199.901 146.421C200.527 146.568 200.873 146.657 200.873 146.686H200.866Z'
        fill='white'
      />
      <path
        d='M215.303 116.9C215.303 116.995 213.977 116.892 212.629 116.043C211.266 115.209 210.581 114.072 210.67 114.028C210.758 113.954 211.546 114.928 212.835 115.718C214.11 116.523 215.332 116.789 215.31 116.9H215.303Z'
        fill='white'
      />
      <g opacity='0.3'>
        <path
          d='M198.236 132.675C195.076 133.797 191.68 133.989 188.336 134.181C180.558 134.616 173.943 134.779 166.165 135.214C175.099 137.961 185.147 137.798 194.236 135.591C196.785 134.971 199.356 134.196 201.514 132.697C203.672 131.199 206.199 128.659 206.375 126.031C205.145 129.154 201.396 131.553 198.236 132.675Z'
          fill='black'
        />
      </g>
      <path
        d='M117.203 77.627C117.512 85.1936 120.496 117.254 120.496 117.254L152.147 135.458L154.482 133.162L123.405 114.825L120.356 77.4351C120.289 76.6009 119.575 75.9586 118.742 75.9808C117.866 76.0029 117.174 76.7411 117.21 77.627H117.203Z'
        fill='#E0E0E0'
      />
      <path
        d='M117.203 77.6268C117.203 77.6268 117.188 77.5603 117.196 77.4274C117.196 77.2946 117.232 77.0952 117.336 76.8516C117.446 76.6154 117.652 76.3423 117.991 76.143C118.323 75.951 118.809 75.8477 119.302 76.0175C119.788 76.1725 120.275 76.6228 120.392 77.265C120.466 77.8851 120.51 78.5495 120.577 79.273C120.702 80.7198 120.842 82.3734 121.004 84.2263C121.63 91.6527 122.514 102.216 123.567 114.818L123.486 114.685C132.664 120.096 143.293 126.356 154.57 133.007L154.784 133.133L154.607 133.31C154.489 133.428 154.357 133.553 154.232 133.679C153.576 134.329 152.921 134.971 152.272 135.606L152.169 135.709L152.044 135.635C140.487 128.977 129.674 122.746 120.407 117.409L120.334 117.365V117.276C119.243 105.398 118.404 95.4914 117.873 88.5522C117.608 85.0827 117.417 82.3513 117.313 80.491C117.255 79.5609 117.225 78.8448 117.21 78.365C117.21 78.1361 117.196 77.9516 117.196 77.8187C117.196 77.6932 117.196 77.6341 117.196 77.6341C117.196 77.6341 117.21 77.6932 117.218 77.8187C117.225 77.9516 117.24 78.1361 117.262 78.365C117.299 78.8596 117.35 79.5682 117.409 80.491C117.564 82.3661 117.778 85.09 118.057 88.5449C118.647 95.484 119.538 105.383 120.65 117.254L120.569 117.128C129.843 122.451 140.664 128.667 152.235 135.31L152.007 135.34C152.648 134.705 153.304 134.063 153.966 133.413C154.092 133.288 154.224 133.162 154.342 133.044L154.379 133.347C143.109 126.688 132.487 120.413 123.317 114.988L123.243 114.943V114.855C122.234 102.253 121.402 91.6896 120.805 84.2559C120.665 82.403 120.532 80.7494 120.422 79.3025C120.363 78.5864 120.326 77.9073 120.26 77.3093C120.157 76.7188 119.722 76.2906 119.265 76.143C118.809 75.9805 118.345 76.0617 118.028 76.2389C117.696 76.4161 117.498 76.6745 117.38 76.9033C117.159 77.3684 117.218 77.6563 117.196 77.6489L117.203 77.6268Z'
        fill='#263238'
      />
      <path
        d='M123.449 114.84C123.508 114.928 122.831 115.46 122.006 116.11C121.181 116.759 120.51 117.298 120.437 117.217C120.363 117.143 120.923 116.471 121.77 115.807C122.61 115.142 123.398 114.751 123.449 114.84Z'
        fill='#263238'
      />
      <path
        d='M139.905 170.685H204.21C206.456 170.685 208.276 168.862 208.276 166.611C208.276 164.359 206.456 162.536 204.21 162.536H139.905C137.658 162.536 135.839 164.359 135.839 166.611C135.839 168.862 137.658 170.685 139.905 170.685Z'
        fill='#24ABB4'
      />
      <path
        d='M199.834 162.536L207.841 168.456L226.838 97.6693C227.508 95.1741 226.028 92.6051 223.538 91.9334C221.048 91.2616 218.485 92.7454 217.822 95.2405L199.834 162.536Z'
        fill='#24ABB4'
      />
      <path
        d='M206.81 169.748C206.692 169.807 205.867 167.607 203.878 165.607C201.919 163.584 199.768 162.654 199.834 162.543C199.849 162.499 200.409 162.676 201.234 163.126C202.059 163.569 203.149 164.307 204.158 165.341C205.167 166.374 205.882 167.482 206.294 168.323C206.707 169.172 206.861 169.733 206.817 169.748H206.81Z'
        fill='#263238'
      />
      <path d='M142.07 228H137.842L143.219 169.659L147.411 170.021L142.07 228Z' fill='#24ABB4' />
      <path d='M205.315 228H209.535L204.129 169.239L199.937 169.6L205.315 228Z' fill='#24ABB4' />
      <path d='M204.703 193.112H143.382V196.013H204.703V193.112Z' fill='#24ABB4' />
      <path d='M204.703 200.406H143.382V203.307H204.703V200.406Z' fill='#24ABB4' />
      <path
        d='M204.026 170.604C204.026 170.708 189.389 170.796 171.343 170.796C153.296 170.796 138.66 170.708 138.66 170.604C138.66 170.501 153.289 170.412 171.343 170.412C189.397 170.412 204.026 170.501 204.026 170.604Z'
        fill='#263238'
      />
      <path
        d='M142.313 225.298C142.21 225.291 143.065 215 144.229 202.318C145.392 189.635 146.424 179.36 146.527 179.374C146.63 179.382 145.775 189.672 144.612 202.355C143.448 215.037 142.417 225.306 142.313 225.298Z'
        fill='#263238'
      />
      <path
        d='M157.465 124.592L149.289 118.309L136.281 121.447C136.281 121.447 136.465 122.591 138.137 123.152L141.665 122.591L135.161 127.057C135.161 127.057 135.529 128.91 137.666 129.567C139.802 130.224 140.921 131.9 140.921 131.9L144.266 135.436H153.186L159.233 134.823L157.458 124.584L157.465 124.592Z'
        fill='#EDBE9E'
      />
      <path
        d='M158.983 135.746L156.824 123.315L156.957 123.292L188.719 113.482C188.719 113.482 196.593 81.8715 198.148 78.0623C200.063 73.3599 207.009 68.0891 209.572 68.4361C214.029 69.034 223.31 67.2475 217.807 90.3387C215.679 99.2711 210.176 114.803 206.501 124.85C204.637 129.944 199.93 133.435 194.523 133.738L158.983 135.746Z'
        fill='#F69B8F'
      />
      <path
        d='M217.52 77.0879C217.432 77.1322 217.292 75.796 215.885 74.3787C214.529 72.9834 211.973 71.5882 208.968 71.4627C207.48 71.448 206.051 71.7728 204.851 72.26C203.635 72.7398 202.641 73.3895 201.86 73.9948C200.313 75.2424 199.672 76.3349 199.606 76.2832C199.591 76.2685 199.731 75.9953 200.063 75.5377C200.387 75.08 200.925 74.4525 201.691 73.7881C203.208 72.4888 205.823 71.0567 208.976 71.0789C212.106 71.2117 214.721 72.6955 216.084 74.1941C216.769 74.9471 217.166 75.6927 217.336 76.2316C217.439 76.4973 217.461 76.7188 217.498 76.8664C217.527 77.0141 217.535 77.0879 217.52 77.0953V77.0879Z'
        fill='#263238'
      />
      <path
        d='M198.369 78.018C198.369 78.018 198.31 78.2395 198.17 78.6529C198.022 79.0663 197.853 79.6937 197.61 80.5058C197.146 82.1372 196.483 84.5438 195.665 87.6295C194.037 93.8009 191.776 102.682 189.087 113.482L189.065 113.57L188.977 113.6C179.769 116.449 168.941 119.808 157.222 123.44H157.215H157.207C157.163 123.447 157.119 123.455 157.075 123.462L157.23 123.241C157.922 127.227 158.651 131.413 159.388 135.672L159.189 135.51C159.992 135.465 160.854 135.414 161.723 135.369C173.457 134.712 184.631 134.085 194.796 133.509C197.308 133.362 199.694 132.483 201.646 131.117C203.598 129.752 205.138 127.914 206.081 125.883C206.552 124.872 206.906 123.809 207.289 122.783C207.664 121.757 208.025 120.746 208.386 119.756C209.093 117.778 209.778 115.873 210.427 114.057C212.99 106.786 214.949 100.858 216.187 96.7242C216.813 94.6646 217.262 93.0479 217.564 91.9554C217.704 91.4165 217.815 91.0031 217.896 90.7078C217.969 90.4273 218.021 90.287 218.021 90.287C218.021 90.287 217.999 90.4347 217.933 90.7152C217.866 91.0105 217.763 91.4313 217.638 91.9702C217.358 93.0701 216.938 94.6941 216.327 96.7611C215.126 100.91 213.211 106.852 210.677 114.139C210.036 115.955 209.366 117.867 208.659 119.852C208.305 120.842 207.937 121.853 207.569 122.886C207.186 123.912 206.847 124.968 206.361 126.016C205.396 128.098 203.827 129.981 201.831 131.391C199.834 132.793 197.389 133.701 194.811 133.856C184.646 134.439 173.472 135.074 161.738 135.746C160.868 135.798 159.999 135.842 159.204 135.886H159.034L159.005 135.724C158.268 131.457 157.539 127.279 156.854 123.292L156.824 123.108L157.009 123.071C157.053 123.064 157.097 123.056 157.141 123.049H157.119C168.846 119.446 179.681 116.11 188.889 113.275L188.785 113.386C191.503 102.593 193.816 93.7197 195.496 87.563C196.335 84.4847 197.028 82.0855 197.521 80.4615C197.779 79.6494 197.963 79.0294 198.133 78.6233C198.287 78.2099 198.376 78.0106 198.376 78.0106L198.369 78.018Z'
        fill='#263238'
      />
      <path
        d='M215.016 98.0826C214.986 98.1859 210.441 97.0491 204.858 95.5431C199.275 94.0372 194.767 92.7379 194.796 92.6346C194.826 92.5312 199.37 93.6681 204.961 95.174C210.544 96.68 215.052 97.9792 215.023 98.0826H215.016Z'
        fill='white'
      />
      <path
        d='M179.99 133.701C179.887 133.716 179.401 130.032 178.908 125.477C178.414 120.923 178.097 117.224 178.208 117.21C178.311 117.195 178.797 120.878 179.291 125.433C179.784 129.988 180.101 133.686 179.99 133.694V133.701Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_896_14718'>
        <rect width='183' height='226' fill='white' transform='translate(44 11)' />
      </clipPath>
    </defs>
  </svg>
);
