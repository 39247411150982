export const transactionDetails = {
  title: 'Transaction Details',
  textHelper: 'emitted through your transaction',
  submitButton: 'Submit',
  feedbackLoopButton: 'Refine your transaction',
  submittedTitle: 'You refined your settings',
  submittedSubtitle: `Your transaction's CO₂e footprint changed`,
  saveChanges: 'Save changes',
  showAllTransaction: 'Show all transactions',
  questionsTitle: 'Refine your transaction',
  preferences: {
    expanded: 'Close settings',
    collapsed: 'View settings',
  },
  feedbackLoop1: {
    questions: [
      {
        questionId: '6b988e5a-5540-42d5-a0a7-d24ff054c2f9',
        type: 'multiple-choices',
        title: '1. Which option best describes your food choices?',
        answers: [
          {
            title: 'Meat-heavy',
            iconName: 'lots-of-meat',
            answerId: '9d1d0a2b-597b-4608-8371-9af6e178c969',
          },
          {
            title: 'Mixed',
            iconName: 'mixed-diet',
            answerId: '6d264733-133b-4243-abf2-c932b45c93b0',
          },
          {
            title: 'Reduced meat',
            iconName: 'few-meat',
            answerId: '476caf88-1714-4b8e-a945-1ceeb8cefe34',
          },
          {
            title: 'Vegetarian',
            iconName: 'vegeterian',
            answerId: '2c270bb0-0e1a-4b7c-846b-f4fcfe5f0fa0',
          },
          {
            title: 'Vegan',
            iconName: 'vegan',
            answerId: '0af44297-94dd-4634-a9e3-b75b9850e029',
          },
        ],
      },
      {
        title: '2. Which option best describes your grocery shopping?',
        type: 'multiple-choices',
        questionId: 'd155718a-e3f5-4f4f-845c-90a67f9393f4',
        info: `Where do you typically go for groceries? If a national grocery chain is your go-to, select 'General/Chain'. If you frequent farmers' markets in your area, the 'Local/Seasonal' option might be more your speed. If you do a bit of both, no problem — click the option you go for most often. <br/> <br/> This selection doesn't have to be exact; it simply helps us narrow down an estimate of your environmental footprint. It's all about becoming more aware of your buying decisions and how they affect the planet. <br/><br/> For example, buying from a big chain might mean less sustainable food practices, but if it's a walkable option from your home, shopping there can reduce your need for high-emission transportation. On the flip side, you might only opt for in-season produce (which requires less energy) from local grocers or farmers markets that require a car to visit. There is no one right answer — there's just the one that fits your lifestyle, and the small actions you might be able to take to make it better for the planet.`,
        answers: [
          {
            title: 'General/\nChain',
            iconName: 'normal',
            answerId: 'd112a419-e04a-4c78-bddb-a0e08f4abce9',
          },
          {
            title: 'Local/\nSeasonal',
            iconName: 'regional',
            answerId: 'c6916ca2-6551-478f-ace2-2666e356393e',
          },
        ],
      },
      {
        title: '3. How often do you eat frozen foods?',
        type: 'multiple-choices',
        questionId: 'ca827946-24c6-4231-9901-be803c8c876e',
        info: `How does frozen food impact your carbon footprint? It takes a lot of energy to freeze food and keep it cold, especially during transportation. The energy used is associated with releasing carbon, so buying fresh food is usually better for the planet than buying frozen food. But not always! Buying frozen food may help you reduce food waste, and chilled foods that aren't local can have an even higher footprint than frozen foods due to inefficient cooling systems.`,
        answers: [
          {
            title: 'Daily',
            iconName: 'daily',
            answerId: '6090e059-d0cd-4103-b934-13faf3494ec7',
          },
          {
            title: 'Up to 3 times a week',
            iconName: 'until-three-times',
            answerId: 'c39f8457-d8cd-4939-89fe-27759e0d0b59',
          },
          {
            title: 'Rarely',
            iconName: 'rare',
            answerId: '08713530-31c2-48d1-8eb9-507339d4c221',
          },
        ],
      },
    ],
  },
  feedbackLoop2: {
    questions: [
      {
        title: 'How long is your flight?',
        type: 'slider',
        questionId: '9ce39d4f-cb36-4e93-b896-822156c9c159',
        answers: [
          {
            answerId: 'c1583fd4-65a4-4ef8-8672-28a84f4b5074',
          },
          {
            answerId: '6755c3d6-b201-4524-b3d3-cc3f9801c17e',
          },
          {
            answerId: '85187a01-0745-4ae6-b1cc-dff2f29ab00c',
          },
          {
            answerId: '6b6124b9-344d-47bb-af6f-e82cad25e6b4',
          },
          {
            answerId: 'e8d74fcc-0e26-411a-8b08-cfddaaac65a8',
          },
          {
            answerId: 'e5a5c036-077c-4ffd-821a-71befbd3cbc4',
          },
          {
            answerId: '9d06954e-c617-4453-91c4-4509d5047a23',
          },
          {
            answerId: '2f085fa7-aceb-4e35-9da5-cb39f8a46fca',
          },
          {
            answerId: 'cac272ab-9af5-41f9-80c1-2e24447797a6',
          },
          {
            answerId: '66801fe0-8f09-4b13-be28-28ac868960cc',
          },
          {
            answerId: 'f85c4210-d359-4f7c-b878-045afd6ddbcc',
          },
          {
            answerId: '61156630-7410-4daa-8f2a-e7fdf8f27aca',
          },
          {
            answerId: 'dfdde291-7c41-4905-a709-c6a10e95238e',
          },
          {
            answerId: '467730f6-eb89-45f2-b469-71dc2d0c5595',
          },
          {
            answerId: '5451eb94-a70c-48c3-92fe-ae85da1aaef1',
          },
          {
            answerId: 'ece855ec-3024-432e-877f-0ccca2a17077',
          },
          {
            answerId: '7bf3e50e-68ee-4fc5-a5eb-4d2ab9e11731',
          },
          {
            answerId: '073cb9e4-7626-49b4-9735-159623b0948c',
          },
          {
            answerId: 'b9cd41c3-4381-4c18-b251-6f2c5743c25d',
          },
        ],
      },
      {
        title: 'Which travel class did you select?',
        type: 'multiple-choices',
        questionId: 'cf4bd26e-c1e6-40d5-8825-e12c0246a5d5',
        info: `When you travel in a higher class, you take up more space and have higher luggage allowance than if you were in economy. This means fewer passengers on-board the plane, increasing your share of the total emissions of the flight.`,
        answers: [
          {
            title: 'Economy',
            iconName: 'economy-class',
            answerId: '152a69f0-c6ff-4f7b-bfac-fe6e92773188',
          },
          {
            title: 'Business',
            iconName: 'business-class',
            answerId: '80bf7e45-4cae-4f50-9d16-d749dd3bd9b7',
          },
          {
            title: 'First',
            iconName: 'first-class',
            answerId: '6f7078dc-9c25-40a7-b957-28b02948d71f',
          },
        ],
      },
      {
        title: 'What type of flight is this?',
        type: 'multiple-choices',
        questionId: 'a94bc4b7-2664-43d3-97ee-1556bc6674ab',
        answers: [
          {
            title: 'One-way',
            iconName: 'one-way',
            answerId: '2815016c-a6d3-4505-82f2-02cb614fc10c',
          },
          {
            title: 'Round-trip',
            iconName: 'round-trip',
            answerId: 'a8c60936-c821-48c6-8975-9f209423d9d9',
          },
        ],
      },
    ],
  },
  feedbackLoop8: {
    questions: [
      {
        questionId: '8e63b3d3-88c5-49b8-82a4-16d1935c853f',
        type: 'multiple-choices',
        title: 'Which option best describes your food choices?',
        answers: [
          {
            title: 'Meat-heavy',
            iconName: 'lots-of-meat',
            answerId: '040ed2ec-fc1a-439f-b70d-7f6c749c4c8c',
          },
          {
            title: 'Mixed',
            iconName: 'mixed-diet',
            answerId: 'fa16ba08-1935-4bac-9502-fd9a649a4d18',
          },
          {
            title: 'Reduced meat',
            iconName: 'few-meat',
            answerId: '4cba7212-7f08-4b7f-a3b9-a77db8149f9d',
          },
          {
            title: 'Vegetarian',
            iconName: 'vegeterian',
            answerId: 'c6529bad-3c53-40c3-a9a2-744cb93136a8',
          },
          {
            title: 'Vegan',
            iconName: 'vegan',
            answerId: '476cbe8a-ca2e-46e3-ae08-f268917975de',
          },
        ],
      },
      {
        title: 'Which option best describes your grocery shopping?',
        type: 'multiple-choices',
        questionId: '04a986f7-fac5-4c49-ac8c-571a7cb8a823',
        info: `Where do you typically go for groceries? If a national grocery chain is your go-to, select 'General/Chain'. If you frequent farmers' markets in your area, the 'Local/Seasonal' option might be more your speed. If you do a bit of both, no problem — click the option you go for most often. <br/> <br/> This selection doesn't have to be exact; it simply helps us narrow down an estimate of your environmental footprint. It's all about becoming more aware of your buying decisions and how they affect the planet. <br/><br/> For example, buying from a big chain might mean less sustainable food practices, but if it's a walkable option from your home, shopping there can reduce your need for high-emission transportation. On the flip side, you might only opt for in-season produce (which requires less energy) from local grocers or farmers markets that require a car to visit. There is no one right answer — there's just the one that fits your lifestyle, and the small actions you might be able to take to make it better for the planet.`,
        answers: [
          {
            title: 'General/\nChain',
            iconName: 'normal',
            answerId: 'df84033f-85a1-4ede-b4cc-ae188c0b5031',
          },
          {
            title: 'Local/\nSeasonal',
            iconName: 'regional',
            answerId: '2d209f6c-9e95-4e77-a574-afd36d88a8f6',
          },
        ],
      },
      {
        title: 'How often do you eat frozen foods?',
        type: 'multiple-choices',
        questionId: '9af3033b-eb51-4d9f-a943-1f98fde1d6f2',
        info: `How does frozen food impact your carbon footprint? It takes a lot of energy to freeze food and keep it cold, especially during transportation. The energy used is associated with releasing carbon, so buying fresh food is usually better for the planet than buying frozen food. But not always! Buying frozen food may help you reduce food waste, and chilled foods that aren't local can have an even higher footprint than frozen foods due to inefficient cooling systems.`,
        answers: [
          {
            title: 'Daily',
            iconName: 'daily',
            answerId: '8bb87b93-7b08-412e-abe7-1f4fa0c47393',
          },
          {
            title: 'Up to 3 times a week',
            iconName: 'until-three-times',
            answerId: '841d7f05-b499-4ad1-977e-730d06b8ffec',
          },
          {
            title: 'Rarely',
            iconName: 'rare',
            answerId: 'b6e8b886-8d9e-49ea-8360-6ea82138c47c',
          },
        ],
      },
    ],
  },
  feedbackLoop9: {
    questions: [
      {
        title: 'Which option best describes your food choices?',
        type: 'multiple-choices',
        questionId: 'd7436a8c-06a4-4ecd-8161-915ae096fa96',
        answers: [
          {
            title: 'Meat-heavy',
            iconName: 'lots-of-meat',
            answerId: 'f07f226b-c933-471d-9e80-9d096ee3698f',
          },
          {
            title: 'Mixed',
            iconName: 'mixed-diet',
            answerId: '55aa08fa-cd49-497b-9af4-814dc786a871',
          },
          {
            title: 'Reduced meat',
            iconName: 'few-meat',
            answerId: 'c8facada-08f8-4d2d-b0be-341aeb5ecd84',
          },
          {
            title: 'Vegetarian',
            iconName: 'vegeterian',
            answerId: '98495b80-614b-4273-aacb-1fef3618a64d',
          },
          {
            title: 'Vegan',
            iconName: 'vegan',
            answerId: 'beab7c40-f33f-467b-8c8e-8a53445c5a02',
          },
        ],
      },
    ],
  },
};
