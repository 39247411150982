export const footer = {
  privacy: {
    title: 'Privacy Policy',
    link: 'https://www.vancity.com/PrivacyAndSecurity/YourPrivacy/',
  },
  termsAndConditions: {
    title: 'Terms of Use',
    link: 'https://www.vancity.com/legal/carbon-counter',
  },
  link: 'https://www.vancity.com/',
};
