import { Dialog as MuiDialog, DialogContent } from '@mui/material';

import styled from 'styled-components/macro';

import { CloseIcon } from 'components/Icons';

const Dialog = styled(MuiDialog)`
  .MuiDialog-container {
    padding: 16px;
  }

  .MuiDialog-paper {
    margin: 0;
    max-width: 340px;
    border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
    ${(props) => props.theme.breakpoints.up('lg')} {
      max-width: 560px;
    }
  }
`;

const Content = styled(DialogContent)`
  background-color: white;
  padding: 40px 24px 20px;
  color: ${({ theme }) => `${theme.palette.common.black}`};
  font-size: ${({ theme }) => `${theme.typography.body2.fontSize}`};
  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 40px 40px 32px;
  }
  p {
    padding-bottom: 20px;

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

const CloseButton = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
  top: 25px;
  right: 20px;
`;

const Title = styled('div')``;

export const ModalStyles = {
  CloseButton,
  Content,
  Title,
  Dialog,
};
