export const appConfig = {
  REACT_APP_AUTO_LOGOUT_TIMER: 300000,
  REACT_APP_AUTO_LOGOUT_MODAL_TIMER: 60,
  REACT_APP_NECESSARY_COOKIE_NAME: 'allowNecessary',
  REACT_APP_ADVERTISING_COOKIE_NAME: 'allowAdvertising',
  REACT_APP_ANALYTIC_COOKIE_NAME: 'allowAnalytics',
  REACT_APP_FIRST_LOGIN_COOKIE_NAME: 'firstLogin',
  REACT_APP_ACCESS_TOKEN_COOKIE_NAME: 'accessToken',
  REACT_APP_REFRESH_TOKEN_COOKIE_NAME: 'refreshToken',
};
