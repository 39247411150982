import { SVGProps } from 'react';

type ExpandMoreIconProps = {
  props?: SVGProps<SVGSVGElement>;
  color?: string;
};

export const ExpandMoreIcon = ({ color, props }: ExpandMoreIconProps): JSX.Element => (
  <svg {...props} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.8333 6.66666L8.16667 10.6667L4.5 6.66666'
      stroke={color || '#414042'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
