import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

export const TransactionFootprintListHeaderStyles = styled(Typography)`
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { lightGreyText },
        },
      },
    },
  }) => lightGreyText};

  white-space: nowrap;
`;
