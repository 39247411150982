import { AccordionSummary as MuiAccordionSummary, Typography, TypographyProps } from '@mui/material';
import dompurify from 'dompurify';

import { ExpandMoreIcon } from 'components/Icons';

import { Item } from '../types';

type AccordionSummaryProps = {
  body: Item['title'];
  iconColor: string;
  textColor: TypographyProps['color'];
};

const sanitizer = dompurify.sanitize;

export const AccordionSummary = ({ body, iconColor, textColor }: AccordionSummaryProps) => (
  <MuiAccordionSummary aria-controls='content' expandIcon={<ExpandMoreIcon color={iconColor} />} id='accordion'>
    <Typography
      color={textColor}
      dangerouslySetInnerHTML={{
        __html: sanitizer(body),
      }}
      variant='subtitle2'
    />
  </MuiAccordionSummary>
);
