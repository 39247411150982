import { CheckboxStyles } from './Checkbox.styles';

type CheckboxProps = {
  disabled?: boolean;
  checked?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
};

export const Checkbox = ({ checked, onClick, disabled }: CheckboxProps) => (
  <CheckboxStyles.CheckboxContainer>
    <CheckboxStyles.HiddenCheckbox checked={checked} disabled={disabled} readOnly />
    <CheckboxStyles.StyledCheckbox checked={checked} onClick={onClick} disabled={disabled}>
      <CheckboxStyles.Icon />
    </CheckboxStyles.StyledCheckbox>
  </CheckboxStyles.CheckboxContainer>
);
