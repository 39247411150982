import { formatNumber } from 'App/utils';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { CustomTooltipStyles } from './CustomTooltip.styles';

export const CustomTooltip = (props: TooltipProps<ValueType, NameType>) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return <CustomTooltipStyles>{payload[0]?.value && formatNumber(Number(payload[0].value))} kg</CustomTooltipStyles>;
  }
  return null;
};
