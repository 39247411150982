import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

const Content = styled.div`
  position: relative;
`;

const TextWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Text = styled(Typography)`
  margin-bottom: 20px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 12px;
  }
`;

export const DidYouKnowStyles = {
  Content,
  Text,
  TextWrapper,
};
