import { useEffect, useState } from 'react';
import {
  useQueryData,
  GetInsightByIdResponse,
  DEFAULT_OVERRIDE_CACHE_TIME,
  useGetInsightById,
} from '@ecolytiq/js-data-provider';

import { RandomInsightById } from 'impactApp/modules/HomePage';
import { useDeps } from 'App/context';

export const useRandomInsightsByIdData = (id: string) => {
  const { httpClientService } = useDeps();

  const [response, setResponse] = useState<Omit<RandomInsightById, 'isLoading'>>({
    randomInsight: {},
  });
  const { randomInsight } = response;
  const { isLoading, isFetching, data } = useQueryData<{ data: GetInsightByIdResponse }>(
    useGetInsightById(httpClientService, id),
    {
      staleTime: DEFAULT_OVERRIDE_CACHE_TIME,
      cacheTime: DEFAULT_OVERRIDE_CACHE_TIME,
    }
  );

  useEffect(() => {
    if (data?.data) {
      setResponse({
        randomInsight: data.data.randomInsight,
      });
    }
  }, [data, setResponse]);

  return {
    isLoading: isLoading || isFetching,
    randomInsight,
  };
};
