import { useState } from 'react';

import { AccordionItem } from '../AccordionItem';
import { AccordionAppearance } from '../enums';
import { ItemList } from '../types';

export const useAccordion = (
  itemList: ItemList,
  appearance: AccordionAppearance = AccordionAppearance.LIGHT,
  showCounter?: boolean
) => {
  const [expanded, setExpanded] = useState<number | false>(false);

  return itemList?.map(({ title, body, titleCollapsed }, index) => {
    const isExpanded = expanded === index;
    const handleChange = () => {
      if (expanded !== index) {
        setExpanded(index);
      } else {
        setExpanded(false);
      }
    };

    return (
      <AccordionItem
        key={title}
        counter={showCounter ? index : undefined}
        appearance={appearance}
        body={body}
        isExpanded={isExpanded}
        onChange={handleChange}
        title={title}
        titleCollapsed={titleCollapsed}
      />
    );
  });
};
