import { UserAction, UserActions, UserState } from './types';

export const INIT_MONTH_DELTA = 0;

export const initialState: UserState = {
  isMenuVisible: false,
  sessionToken: '',
  sessionRefreshToken: false,
  monthDelta: INIT_MONTH_DELTA,
  isLoggedIn: false,
  isFirstLogin: false,
};

export function reducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case UserActions.TOGGLE_MENU_VISIBILITY:
      return { ...state, isMenuVisible: !state.isMenuVisible };

    case UserActions.SET_SESSION_TOKEN:
      return { ...state, sessionToken: action.sessionToken };

    case UserActions.SET_SESSION_REFRESH_TOKEN:
      return { ...state, sessionRefreshToken: action.sessionRefreshToken };

    case UserActions.SET_MONTH_DELTA:
      return { ...state, monthDelta: action.monthDelta };

    case UserActions.SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.isLoggedIn };

    case UserActions.SET_IS_FIRST_LOGIN:
      return { ...state, isFirstLogin: action.isFirstLogin };

    case UserActions.RESET_STATE:
      return { ...initialState };

    default:
      throw new Error('You have to specify action type');
  }
}
