import { Accordion as MuiAccordion } from '@mui/material';
import styled from 'styled-components/macro';

const ACCORDION_MIN_HEIGHT = '56px';

const Wrapper = styled(MuiAccordion)`
  box-shadow: none;
  ::before {
    display: none;
  }

  &.MuiPaper-root {
    background: transparent;
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    padding-right: 8px;
    padding-left: 0;
    min-height: ${ACCORDION_MIN_HEIGHT};
  }

  .MuiAccordionDetails-root {
    padding: 8px 0 16px;
  }

  .MuiAccordionSummary-content {
    padding-right: 48px;
    margin: 12px 0 16px 0;
    ${(props) => props.theme.breakpoints.up('lg')} {
      margin: 16px 0 8px;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .MuiIconButton-root {
    fill: ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
  }
`;

const Item = styled.li`
  color: ${({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => main};
  list-style: none;
  && {
    padding: 0;
  }

  a {
    color: white;
  }

  span {
    cursor: pointer;
    text-decoration: underline;
  }

  &:last-child {
    :not(.Mui-expanded) {
      .MuiAccordionSummary-content {
        margin-bottom: 0;
      }
    }
  }
`;

export const AccordionItemStyles = {
  Item,
  Wrapper,
};
