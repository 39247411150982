import { SVGProps, useEffect, useState } from 'react';

interface DieselProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Diesel = ({ props, isActive }: DieselProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M20.1248 15.0602C20.0257 15.0589 19.9278 15.0821 19.8393 15.1277C19.7509 15.1732 19.6745 15.2399 19.6168 15.322C19.221 15.8799 15.792 20.8161 15.792 22.9521C15.792 25.2958 17.7362 27.1983 20.1248 27.1983C22.5135 27.1983 24.4565 25.2922 24.4565 22.9521C24.4565 20.8161 21.0275 15.8752 20.6375 15.322C20.5793 15.2393 20.5022 15.1722 20.4129 15.1266C20.3236 15.0809 20.2247 15.0582 20.1248 15.0602ZM20.1248 25.9881C18.4167 25.9881 17.0303 24.6282 17.0303 22.9521C17.0303 21.7809 18.7662 18.8003 20.1248 16.7539C21.4858 18.7944 23.2183 21.775 23.2183 22.9521C23.2183 24.6282 21.8318 25.9881 20.1248 25.9881Z'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        fill={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='0.3'
      />
      <path
        d='M26.9922 31.9998H13.2565C12.4585 31.9998 11.6931 31.6767 11.1289 31.1016C10.5646 30.5265 10.2476 29.7464 10.2476 28.9331V17.7115C10.2463 17.0427 10.4998 16.3995 10.9547 15.9175L15.9587 10.5933C16.1963 10.3403 16.4816 10.1388 16.7973 10.0008C17.1131 9.86282 17.4529 9.79113 17.7965 9.79004H26.9922C27.7903 9.79004 28.5556 10.1131 29.1199 10.6882C29.6842 11.2634 30.0012 12.0434 30.0012 12.8567V28.9343C30.0009 29.7474 29.6837 30.5271 29.1195 31.102C28.5552 31.6768 27.7901 31.9998 26.9922 31.9998ZM17.7965 11.2054C17.6406 11.2056 17.4863 11.2378 17.3429 11.3002C17.1995 11.3627 17.07 11.454 16.9621 11.5687L11.9592 16.8929C11.753 17.1122 11.6377 17.4042 11.6375 17.7079V28.9343C11.6375 29.3722 11.8082 29.7922 12.112 30.1019C12.4158 30.4116 12.8279 30.5856 13.2576 30.5856H26.9922C27.4219 30.5856 27.834 30.4116 28.1379 30.1019C28.4417 29.7922 28.6124 29.3722 28.6124 28.9343V12.8555C28.6124 12.4176 28.4417 11.9976 28.1379 11.6879C27.834 11.3782 27.4219 11.2042 26.9922 11.2042L17.7965 11.2054Z'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        fill={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='0.3'
      />
      <path
        d='M13.4105 9.2844L9.27879 13.4954C8.90639 13.8749 8.90638 14.4903 9.27879 14.8699C9.65119 15.2494 10.255 15.2494 10.6274 14.8698L14.7591 10.6589C15.1315 10.2793 15.1315 9.66394 14.7591 9.2844C14.3867 8.90485 13.7829 8.90485 13.4105 9.2844Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
    </svg>
  );
};
