import { SVGProps, useEffect, useState } from 'react';

interface FoodVeganProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodVegan = ({ props, isActive }: FoodVeganProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} width='40' height='41' viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M32.182 13.9998C31.3458 13.1637 28.3148 12.0488 26.8515 13.5121M29.778 10.5856L26.8515 13.5121L29.778 10.5856ZM26.3638 8.18164C27.1999 9.01779 28.3148 12.0488 26.8515 13.5121L26.3638 8.18164Z'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.5'
      />
      <path
        d='M20.2025 24.6765L17.9295 22.4366M26.7538 13.6106C24.9488 11.8052 22.7093 11.1366 21.1718 12.6745C17.1942 16.6863 10.9771 27.5183 11.913 28.4544C12.8489 29.3904 23.9795 22.9046 27.6897 19.1603C29.2272 17.6224 28.5587 15.3825 26.7538 13.6106ZM19.9685 14.2123L22.5756 16.8535L19.9685 14.2123ZM24.648 21.7011L22.5756 19.6283L24.648 21.7011ZM17.0939 17.8899L18.8654 19.6283L17.0939 17.8899Z'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='1.5'
      />
    </svg>
  );
};
