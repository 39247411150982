// import { StylesProvider, MuiThemeProvider } from '@mui/material';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { ReactChild } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/macro';

import { GlobalStyles } from './GlobalStyles';

interface ThemeProviderProps {
  children: ReactChild;
  theme: any;
}

const StyledComponentsThemeProviderElement: any = StyledComponentsThemeProvider;
const GlobalStylesElement: any = GlobalStyles;

export const ThemeProvider = ({ children, theme }: ThemeProviderProps): JSX.Element => (
  <StyledEngineProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <StyledComponentsThemeProviderElement theme={theme}>
        <>
          <GlobalStylesElement />
          {children}
        </>
      </StyledComponentsThemeProviderElement>
    </MuiThemeProvider>
  </StyledEngineProvider>
);
