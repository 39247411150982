import styled, { css } from 'styled-components/macro';

import { Grid, Paper } from 'components';

const Content = styled(Paper.Rounded)<{ $paddingTop?: boolean }>`
  padding: ${({ $paddingTop }) => `${$paddingTop ? '28px' : '4px'} 8px 32px 8px`};
  z-index: 1;
  position: relative;
  width: 100%;
  flex: 1;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 45px 8px 28px;
    box-shadow: 0px 8px 16px ${({ theme }) => theme.palette.custom.boxShadow.color};
  }

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 45px 28px 28px;
  }
`;

const Divider = styled.div<{ reduceMarginRight?: boolean }>`
  height: 1px;
  margin: 8px 16px 8px 72px;
  background-color: ${({
    theme: {
      palette: { grey },
    },
  }) => grey[400]};

  ${(props) => props.theme.breakpoints.up('lg')} {
    ${({ reduceMarginRight }) =>
      reduceMarginRight &&
      css`
        margin-right: 36px;
      `};
  }
`;

const Wrapper = styled(Grid)`
  margin: 20px 0;
  text-align: center;
  white-space: pre-wrap;
`;

export const ListStyles = {
  Content,
  Divider,
  Wrapper,
};
