import { SVGProps } from 'react';

interface ArrowForwardIconProps {
  color: string;
  props?: SVGProps<SVGSVGElement>;
}

export const ArrowForwardIcon = ({ props, color }: ArrowForwardIconProps): JSX.Element => (
  <svg {...props} fill='none' height='16' viewBox='0 0 19 16' width='19' xmlns='http://www.w3.org/2000/svg'>
    <path d='M17.3989 8.00006L0.598927 8.00006' stroke={color} strokeLinejoin='round' strokeWidth='1.5' />
    <path
      d='M10.3989 1.00006L17.3989 8.00006L10.3989 15.0001'
      stroke={color}
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </svg>
);
