import { SVGProps, useEffect, useState } from 'react';

interface BenzinProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const Benzin = ({ props, isActive }: BenzinProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M26.3801 31.0001H13.2227V11.1156C13.2227 10.2899 13.5331 9.49808 14.0858 8.91426C14.6385 8.33043 15.3881 8.00244 16.1697 8.00244H23.4331C24.2147 8.00244 24.9642 8.33043 25.5169 8.91426C26.0696 9.49808 26.3801 10.2899 26.3801 11.1156V31.0001ZM15.2641 28.8448H24.3398V11.1156C24.3398 10.8615 24.2443 10.6179 24.0742 10.4382C23.9042 10.2586 23.6735 10.1577 23.4331 10.1577H16.1697C15.9292 10.1577 15.6986 10.2586 15.5285 10.4382C15.3585 10.6179 15.2629 10.8615 15.2629 11.1156L15.2641 28.8448Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M29.0519 26.1064H25.2979V23.9511H29.0519C29.2924 23.9511 29.5231 23.8502 29.6931 23.6706C29.8632 23.4909 29.9587 23.2473 29.9587 22.9932V15.3062L26.7204 11.7142L28.1939 10.2271L31.9956 14.4417V22.9921C31.9959 23.8173 31.686 24.6089 31.134 25.1929C30.5821 25.7768 29.8332 26.1054 29.0519 26.1064Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M27.6384 28.748H11.9635C11.4314 28.748 11 29.2037 11 29.7658V29.9813C11 30.5434 11.4314 30.9991 11.9635 30.9991H27.6384C28.1705 30.9991 28.6018 30.5434 28.6018 29.9813V29.7658C28.6018 29.2037 28.1705 28.748 27.6384 28.748Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M25.2479 14.7866H14.1716C13.719 14.7866 13.3521 15.1742 13.3521 15.6523V16.172C13.3521 16.6501 13.719 17.0376 14.1716 17.0376H25.2479C25.7005 17.0376 26.0674 16.6501 26.0674 16.172V15.6523C26.0674 15.1742 25.7005 14.7866 25.2479 14.7866Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
      <path
        d='M30.201 13.5796H29.7193C29.1872 13.5796 28.7559 14.0353 28.7559 14.5973V16.2808C28.7559 16.8429 29.1872 17.2986 29.7193 17.2986H30.201C30.7332 17.2986 31.1645 16.8429 31.1645 16.2808V14.5973C31.1645 14.0353 30.7332 13.5796 30.201 13.5796Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
      />
    </svg>
  );
};
