import { useTheme } from 'styled-components/macro';

import { convertGramsToLargerUnits, formatNumber } from 'App/utils';
import { HighlightedColor } from 'App/types';
import { Avatar, AvatarSize, Grid, ListHeader } from 'components';
import { CloudIcon } from 'components/Icons';
import { homePage } from 'impactApp/translations';

import { FootprintListHeaderStyles } from './FootprintListHeader.styles';

type FootprintListHeaderProps = {
  summaryGco2: number;
  month: number;
  onMonthChange: (month: number) => void;
};

export const FootprintListHeader = ({ month, onMonthChange, summaryGco2 }: FootprintListHeaderProps) => {
  const {
    palette: {
      common: { white },
      custom: {
        list: { highlightedData },
      },
    },
  } = useTheme();
  const {
    footprintReport: { listSubtitle, listTitle },
  } = homePage;
  const { ORANGE } = HighlightedColor;
  const { SMALL } = AvatarSize;
  const convertedValue = convertGramsToLargerUnits(summaryGco2);
  const co2Sum = `${formatNumber(+convertedValue[0])} ${convertedValue[1]}`;

  return (
    <ListHeader month={month} onMonthChange={onMonthChange} subtitle={listSubtitle} title={listTitle}>
      <Grid alignItems='center' justifyContent='center'>
        <Avatar backgroundIcon={highlightedData[ORANGE]} color={white} icon={<CloudIcon />} size={SMALL} />
        <FootprintListHeaderStyles id='report-summary' variant='subtitle1'>
          {co2Sum}
        </FootprintListHeaderStyles>
      </Grid>
    </ListHeader>
  );
};
