import { createContext, PropsWithChildren, useContext } from 'react';

const DepsContext = createContext<Record<string, any>>({});

export const useDeps = () => {
  const context = useContext(DepsContext);

  if (context === undefined) {
    throw new Error('useDeps must be nested in DepsProvider');
  }

  return context;
};

export const DepsProvider = ({ children, ...services }: PropsWithChildren<Record<string, any>>) => (
  <DepsContext.Provider value={services}>{children}</DepsContext.Provider>
);
