import { SVGProps, useEffect, useState } from 'react';

interface FirstClassProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FirstClass = ({ props, isActive }: FirstClassProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'>
      <path
        d='M20 10L23.09 16.26L30 17.27L25 22.14L26.18 29.02L20 25.77L13.82 29.02L15 22.14L10 17.27L16.91 16.26L20 10Z'
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
