import { useNavigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Transaction } from '@ecolytiq/js-data-provider';

import { ArrowLeftIcon } from 'components/Icons';

import { IconButton } from '../IconButton';

type GoBackButtonProps = {
  url?: string;
  transaction?: Transaction;
};

export const GoBackButton = ({ url, transaction }: GoBackButtonProps) => {
  const { back } = createBrowserHistory();
  const navigate = useNavigate();
  let handleRedirection = back;

  if (url) {
    handleRedirection = () => navigate(url, { state: { transaction } });
  }

  return (
    <IconButton.Content aria-label='Go back' color='inherit' edge='start' onClick={handleRedirection}>
      <IconButton.IconWrapper>
        <ArrowLeftIcon />
      </IconButton.IconWrapper>
    </IconButton.Content>
  );
};
