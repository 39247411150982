import { Typography } from '@mui/material';
import { ComparisonArrowIconComponent, Grid } from 'components';
import { HeaderWithValue, Comparison } from 'App/components';
import { convertGramsToLargerUnits } from 'App/utils/convertGramsToLargerUnits';
import { homePage } from 'impactApp/translations';
import { ComparisonValue } from 'App/types';

import { HeaderStyles } from './Header.styles';

type HeaderProps = {
  averageFootprint: number;
  value: number;
};

export const Header = ({ averageFootprint, value }: HeaderProps) => {
  const comparisonDifference = convertGramsToLargerUnits(Math.abs(averageFootprint - value));
  const footprint = convertGramsToLargerUnits(value);
  const {
    intro: {
      comparison: {
        compareTo: comparisonCopy,
        outcome: { above, below },
      },
      subtitle: headerSubtitle,
    },
  } = homePage;
  const comparison = averageFootprint > value ? below : above;
  const { INCREASE, DECREASE } = ComparisonValue;
  const comparisonArrow = averageFootprint > value ? DECREASE : INCREASE;

  const comparisonMessage = `${comparison} ${comparisonCopy}`;
  return (
    <>
      <Grid flexDirection='column'>
        <HeaderStyles.Wrapper>
          <HeaderStyles.Title>
            <Typography color='primary.dark' variant='subtitle1'>
              {headerSubtitle}
            </Typography>
          </HeaderStyles.Title>
          <HeaderStyles.ArrowWrapper>
            <ComparisonArrowIconComponent trend={comparisonArrow} />
            <HeaderWithValue isExtraLarge isLarge unitVariant='h3' value={footprint} color='primary.main' />
          </HeaderStyles.ArrowWrapper>
          <Comparison comparison={comparisonDifference} comparisonMessage={comparisonMessage} />
        </HeaderStyles.Wrapper>
      </Grid>
    </>
  );
};
