import { AvatarStyles } from './Avatar.styles';
import { AvatarSize } from './types';

type AvatarProps = {
  icon: JSX.Element | null;
  color?: string;
  size?: AvatarSize;
  backgroundIcon?: string;
};

const { MEDIUM } = AvatarSize;

export const Avatar = ({ color, icon, size = MEDIUM, backgroundIcon }: AvatarProps) => (
  <AvatarStyles $avatarSize={size} color={color} $backgroundIcon={backgroundIcon}>
    {icon}
  </AvatarStyles>
);
