import styled, { css } from 'styled-components/macro';
import { TextField as MuiTextField } from '@mui/material';

const MIN_HEIGHT_TEXT_FIELD = '56px';

type TextFieldProps = {
  $isReadOnly?: boolean;
};

const BasicTextField = css`
  min-height: ${MIN_HEIGHT_TEXT_FIELD};
  border: none;
  outline: 0;
  border-radius: 12px;
  background-color: ${({
    theme: {
      palette: {
        custom: {
          input: { background },
        },
      },
    },
  }) => background};
  padding: 24px 20px 0 20px;

  color: ${({
    theme: {
      palette: {
        custom: {
          input: { text },
        },
      },
    },
  }) => text};
  font-size: 16px;
  line-height: 22px;

  ::placeholder {
    color: transparent;
  }
`;

const StyledMuiTextField = styled(MuiTextField)<TextFieldProps>`
  &:hover label {
    color: ${({
      theme: {
        palette: {
          custom: {
            input: { text },
          },
        },
      },
    }) => text};

    &:read-only {
      color: ${({
        theme: {
          palette: {
            custom: {
              input: { readOnlyText },
            },
          },
        },
      }) => readOnlyText};
    }
  }

  .MuiFilledInput-root {
    ${BasicTextField}

    &:read-only {
      color: ${({
        theme: {
          palette: {
            custom: {
              input: { readOnlyText },
            },
          },
        },
      }) => readOnlyText};
      background-color: ${({
        theme: {
          palette: {
            custom: {
              input: { readOnlyBackground },
            },
          },
        },
      }) => readOnlyBackground};
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background-color: ${({
      theme: {
        palette: {
          custom: {
            input: { disabledBackground },
          },
        },
      },
    }) => disabledBackground};
  }

  .MuiFormLabel-root {
    color: ${({
      theme: {
        palette: {
          custom: {
            input: { lightText, readOnlyText },
          },
        },
      },
      $isReadOnly,
    }) => ($isReadOnly ? readOnlyText : lightText)};
    font-size: 16px;
    line-height: 22px;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: ${({
      theme: {
        palette: {
          custom: {
            input: { disabledText },
          },
        },
      },
    }) => disabledText};
  }
  .MuiFormLabel-root.Mui-error {
    color: ${({
      theme: {
        palette: {
          custom: {
            input: { error },
          },
        },
      },
    }) => error};
  }

  .MuiInputLabel-filled {
    transform: translate(16px, 20px) scale(1);
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: translate(16px, 10px) scale(0.75);
  }

  .MuiFormHelperText-contained {
    margin-left: 16px;
    margin-right: 16px;
    color: ${({
      theme: {
        palette: {
          custom: {
            input: { error },
          },
        },
      },
    }) => error};
  }

  .MuiFilledInput-inputMultiline {
    padding: 8px 0;
  }
`;

export const TextFieldStyles = {
  BasicTextField,
  StyledMuiTextField,
};
