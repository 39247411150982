import { routesNavigationList, NavigationItem, NavigationRoutes, isNavigationItem } from 'App/routes';

import { Logo } from 'components/Logo';
import { Typography } from '@mui/material';
import { resetStateAction, useUserContext } from 'App/context';

import { RoutePaths } from 'impactApp/routes/routePaths';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { handleNavigationLink } from 'App/utils';

import { homePage } from 'impactApp/translations';

import { useCookies } from 'react-cookie';

import { appConfig } from 'App/appConfig/appConfig';

import { NavbarStyles } from './Navbar.styles';

export const Navbar = () => {
  const navigate = useNavigate();
  const { HOME, LOGOUT_PAGE } = RoutePaths;
  const [, dispatch] = useUserContext();

  const { pathname } = useLocation();
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } = appConfig;

  const [cookies, , removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);

  const handleLogout = () => {
    if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]) {
      removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
      removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
    }
    dispatch(resetStateAction());
    navigate(LOGOUT_PAGE);
  };

  const renderNavigationItem = () => (item: NavigationRoutes | NavigationItem) => {
    const { id, name } = item;

    if (isNavigationItem(item)) {
      return (
        <NavbarStyles.LinkItem key={id} to={HOME} onClick={() => handleNavigationLink(pathname, name, HOME)}>
          {name}
        </NavbarStyles.LinkItem>
      );
    }

    const { link, outlink } = item;

    if (outlink)
      return (
        <NavbarStyles.LinkItem
          key={id}
          to={{ pathname: link }}
          target='_blank'
          onClick={() => handleNavigationLink(pathname, name, link)}
        >
          <Typography variant='subtitle3'> {name} </Typography>
        </NavbarStyles.LinkItem>
      );

    if (link === LOGOUT_PAGE)
      return (
        <NavbarStyles.PlainLink
          key={id}
          onClick={() => {
            handleLogout();
            handleNavigationLink(pathname, name, link);
          }}
        >
          <Typography variant='subtitle3'> {name} </Typography>
        </NavbarStyles.PlainLink>
      );

    return (
      <NavbarStyles.LinkItem key={id} to={link} onClick={() => handleNavigationLink(pathname, name, link)}>
        <Typography variant='subtitle3'> {name} </Typography>
      </NavbarStyles.LinkItem>
    );
  };

  const navigation = routesNavigationList.map(renderNavigationItem());

  return (
    <NavbarStyles.Wrapper>
      <NavbarStyles.LogoWrapper>
        <NavLink to={HOME}>
          <Logo />
        </NavLink>
        <NavbarStyles.Title>{homePage.appName}</NavbarStyles.Title>
      </NavbarStyles.LogoWrapper>
      <NavbarStyles.LinksWrapper>{navigation}</NavbarStyles.LinksWrapper>
    </NavbarStyles.Wrapper>
  );
};
