import { SVGProps } from 'react';

export const CheckboxIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.15843 6.66691C3.94353 6.66691 3.72845 6.58742 3.56172 6.42762L0.824391 3.80333C0.483387 3.47644 0.474208 2.93728 0.803813 2.59914C1.13336 2.26083 1.67705 2.25184 2.01794 2.57867L4.75527 5.20297C5.09627 5.52986 5.10545 6.06902 4.77584 6.40716C4.60742 6.58005 4.38304 6.66691 4.15843 6.66691Z'
      fill='#F04E39'
    />
    <path
      d='M4.15887 6.66658C3.94274 6.66658 3.72649 6.58622 3.55959 6.42481C3.21999 6.09647 3.21309 5.55731 3.5441 5.22056L8.42257 0.257233C8.75357 -0.0796894 9.2972 -0.086473 9.63681 0.24181C9.97641 0.570152 9.9833 1.10931 9.6523 1.44606L4.77383 6.40938C4.60552 6.58066 4.38225 6.66658 4.15887 6.66658Z'
      fill='#F04E39'
    />
  </svg>
);
