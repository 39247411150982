import { useMediaQuery, useTheme, Container } from '@mui/material';

import { common } from 'impactApp/translations';

import { RefObject, useEffect, useState } from 'react';

import { HowItWorks } from 'impactApp/modules/HomePage/submodules/HowItWorks';

import { color } from 'App/style';

import { GoBackButton } from 'components/IconButton';

import { CloseIcon } from 'components/Icons';

import { HowItWorksModal } from '../../impactApp/modules/HomePage/submodules/HowItWorksModal/HowItWorksModal';

import { useModal } from '../Modal/useModal';

import { Navbar } from './Navbar';

import { HeaderBar } from './Header.styles';

type HeaderProps = {
  button?: JSX.Element;
  rightButton?: JSX.Element;
  title?: string;
  isSemiBold?: boolean;
  showInfo?: boolean;
  hideRightButton?: boolean;
  hideLeftButton?: boolean;
  showDesktopNavigation?: boolean;
  transparent?: boolean;
  isFixed?: boolean;
  contentRef?: RefObject<HTMLDivElement>;
};

export const Header = ({
  button,
  rightButton,
  showInfo,
  isSemiBold,
  hideRightButton,
  title,
  hideLeftButton,
  transparent,
  showDesktopNavigation,
  isFixed,
  contentRef,
}: HeaderProps) => {
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const [isInverted, setIsInverted] = useState(false);
  const [isShowInfo, setIsShowInfo] = useState(showInfo);

  const { Modal, openCallback: openModal } = useModal();

  useEffect(() => {
    const onScroll = () => {
      if (contentRef && contentRef.current) {
        const contentTop = contentRef.current.getBoundingClientRect().top;
        setIsInverted(contentTop <= 0);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [contentRef]);
  return (
    <HeaderBar.Wrapper transparent={transparent} isFixed={isFixed} isInverted={isInverted}>
      {lg && <Navbar />}
      {(!lg || (showDesktopNavigation && !showInfo)) && (
        <HeaderBar.Toolbar>
          <Container
            disableGutters
            maxWidth='xl'
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <HeaderBar.LeftButton>{!hideLeftButton && (button || (!lg && <GoBackButton />))}</HeaderBar.LeftButton>
            <HeaderBar.Title
              $isSemiBold={isSemiBold}
              variant='subtitle3'
              sx={{ typography: { sm: 'subtitle3', lg: 'subtitle1' } }}
            >
              {title}
            </HeaderBar.Title>
            <HeaderBar.RightButton>{!hideRightButton && rightButton}</HeaderBar.RightButton>
          </Container>
        </HeaderBar.Toolbar>
      )}
      {isShowInfo && (
        <HeaderBar.Info component='span' variant='subtitle3' color='common.white' justifyContent='center'>
          <HeaderBar.HowItWorksContainer>
            <HowItWorks openCallback={openModal} />
            {common.headerInfoText}
          </HeaderBar.HowItWorksContainer>
          <HeaderBar.HowItWorksCloseIconContainer>
            <CloseIcon hoverColor={color.darkHighlight} onClick={() => setIsShowInfo(false)} />
          </HeaderBar.HowItWorksCloseIconContainer>
        </HeaderBar.Info>
      )}
      <HowItWorksModal Modal={Modal} />
    </HeaderBar.Wrapper>
  );
};
