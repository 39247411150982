import { subMonths } from 'date-fns';
import { formatMonthFromDate } from 'App/utils';

import { ListHeader } from 'components';
import { Categories, CategoriesTranslation } from 'App/types';
import { PrevMonthPercentage } from 'impactApp/modules';
import { reportDetails } from 'impactApp/translations';

import { TransactionFootprintListHeaderStyles } from './TransactionFootprintListHeader.styles';

type ListHeaderProps = {
  category: Categories;
  footprintDelta: number | boolean;
  month: number;
  onMonthChange: (month: number) => void;
};

export const TransactionListHeader = ({ category, month, onMonthChange, footprintDelta }: ListHeaderProps) => {
  const prevMonthIndex = month + 1;
  const monthName = month !== undefined && formatMonthFromDate(subMonths(new Date(), prevMonthIndex));
  const {
    listSpenders: { title },
  } = reportDetails;
  const footprintDeltaValue = footprintDelta;
  const monthCopy = `from ${monthName}`;
  const subtitleCopy = `in ${CategoriesTranslation[category as keyof typeof CategoriesTranslation].toLowerCase()}`;

  return (
    <ListHeader month={month} onMonthChange={onMonthChange} position='flex-start' subtitle={subtitleCopy} title={title}>
      {footprintDeltaValue !== false && footprintDeltaValue !== Infinity && (
        <>
          <PrevMonthPercentage percentage={Number(footprintDeltaValue)} />
          <TransactionFootprintListHeaderStyles align='right' variant='subtitle3'>
            {monthCopy}
          </TransactionFootprintListHeaderStyles>
        </>
      )}
    </ListHeader>
  );
};
