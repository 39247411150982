import { Typography } from '@mui/material';
import dompurify from 'dompurify';

import { AccordionAppearance, useAccordion } from 'components';
import { ModalProps } from 'components/Modal/types';

import { homePage } from 'impactApp/translations';

import { HowItWorksModalStyles } from './HowItWorksModal.styles';

const sanitizer = dompurify.sanitize;

type HowItWorksModalProps = {
  Modal: ({ content, render }: ModalProps) => JSX.Element;
};

export const HowItWorksModal = ({ Modal }: HowItWorksModalProps) => {
  const {
    howItWorks: { description, list, title, slug },
  } = homePage;

  const accordionList = useAccordion(list, AccordionAppearance.DARK);

  const descriptionContent = description.map((content) => (
    <Typography
      key={content.slice(0, 15)}
      color='common.black'
      dangerouslySetInnerHTML={{
        __html: sanitizer(content),
      }}
      variant='body2'
    />
  ));

  return (
    <Modal
      slug={slug}
      content={[
        descriptionContent,
        <HowItWorksModalStyles.List key='content' id='how-it-works-list'>
          {accordionList}
        </HowItWorksModalStyles.List>,
      ]}
      render={({ closeButton, content }) => (
        <>
          <HowItWorksModalStyles.Header>
            <Typography color='primary.light' variant='h6'>
              {title}
            </Typography>
            {closeButton}
          </HowItWorksModalStyles.Header>
          {content}
        </>
      )}
    />
  );
};
