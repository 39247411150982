import {
  CutleryIcon,
  BagIcon,
  HouseIcon,
  SuitcaseIcon,
  WalletIcon,
  CategoryFood,
  CategoryShopping,
  CategoryTravel,
  CategoryLiving,
  CategoryOther,
} from 'components/Icons';
import { Categories } from 'App/types';

const { FOOD, TRAVEL, SHOPPING, LIVING, OTHER } = Categories;

const backgrounds = {
  [FOOD]: <CategoryFood />,
  [TRAVEL]: <CategoryTravel />,
  [SHOPPING]: <CategoryShopping />,
  [LIVING]: <CategoryLiving />,
  [OTHER]: <CategoryOther />,
};

const icons = {
  [FOOD]: <CutleryIcon />,
  [TRAVEL]: <SuitcaseIcon />,
  [SHOPPING]: <BagIcon />,
  [LIVING]: <HouseIcon />,
  [OTHER]: <WalletIcon />,
};

const renderBackground = (category: Categories) => backgrounds[category];

const renderIcon = (category: Categories) => icons[category];

export { renderBackground, renderIcon };
