import { UnitsOfMass } from 'App/types';

import { convertGramsToKg } from './convertGramsToKg';
import { convertGramsToTons } from './convertGramsToTons';

export const convertGramsToLargerUnits = (grams: number): [string, string] => {
  const { KG, T } = UnitsOfMass;
  switch (true) {
    case grams < 10000:
      return [`${convertGramsToKg(grams, 2)}`, KG];
    case grams < 100000:
      return [`${convertGramsToKg(grams, 1)}`, KG];
    case grams < 1000000:
      return [`${convertGramsToKg(grams, 0)}`, KG];
    case grams < 10000000:
      return [`${convertGramsToTons(grams, 2)}`, T];
    case grams < 100000000:
      return [`${convertGramsToTons(grams, 1)}`, T];
    default:
      return [`${convertGramsToTons(grams, 0)}`, T];
  }
};
