import { ComparableTextWrapper } from './ComparableText.style';

type ComparableTextProps = {
  text: string;
};

export const ComparableText = ({ text }: ComparableTextProps): JSX.Element => {
  return (
    <ComparableTextWrapper variant='caption' sx={{ typography: { lg: 'subtitle1' } }} color='primary.main'>
      {text}
    </ComparableTextWrapper>
  );
};
