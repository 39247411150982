export const foodSettings = {
  title: 'Your settings.',
  subtitle:
    'If the way you shop changes, you can adjust these settings. This helps us calculate the most accurate carbon footprint for you.',
  submitButton: 'Save',
  feedbackLoopButton: 'Refine your foodprint',
  submittedTitle: 'You refined your settings',
  submittedSubtitle: `Your transaction's CO₂e footprint changed`,
  categoryButton: 'Show all transactions',
};
