import { Link, useLocation } from 'react-router-dom';

import { handleOutlink } from 'App/utils';

import { useMediaQuery } from '@mui/material';

import { Logo } from 'components';

import { footer } from 'impactApp/translations';

import { theme } from '../../App/style/theme';

import { Footer } from './Footer.style';

const {
  privacy: { title: privacyTitle, link: privacyLink },
  termsAndConditions: { title: termsTitle, link: termsLink },
  link,
} = footer;

export const FooterSection = () => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const { pathname } = useLocation();

  const footerElements = (
    <>
      <Footer.Element key={privacyTitle} title={privacyTitle}>
        <Link to={{ pathname: privacyLink }} target='_blank' onClick={() => handleOutlink(privacyLink, pathname)}>
          <Footer.Link variant={isMd ? 'caption' : 'label'}>{privacyTitle}</Footer.Link>
        </Link>
      </Footer.Element>
      <Footer.Element key={termsTitle} title={termsTitle}>
        <Link to={{ pathname: termsLink }} target='_blank' onClick={() => handleOutlink(termsLink, pathname)}>
          <Footer.Link variant={isMd ? 'caption' : 'label'}>{termsTitle}</Footer.Link>
        </Link>
      </Footer.Element>
    </>
  );

  return (
    <Footer.Wrapper>
      <Footer.List>
        <Footer.Element>
          <Link to={{ pathname: link }} target='_blank' onClick={() => handleOutlink(link, pathname)}>
            <Logo inFooter />
          </Link>
        </Footer.Element>
        {footerElements}
      </Footer.List>
    </Footer.Wrapper>
  );
};
