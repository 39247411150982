import styled from 'styled-components/macro';
import { Paper } from 'components';

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0;
  padding-top: 92px;
  ${(props) => props.theme.breakpoints.down('md')} {
    padding-top: 32px;
    padding-bottom: 56px;
  }
`;

const PaperWrapper = styled(Paper.RoundedTop)`
  background: linear-gradient(
    ${({ theme }) => `${theme.palette.common.white}`} 64%,
    ${({ theme }) => `${theme.palette.primary.main}`} 0%
  );
  ${(props) => props.theme.breakpoints.up('md')} {
    background: linear-gradient(
      ${({ theme }) => `${theme.palette.common.white}`} 245px,
      ${({ theme }) => `${theme.palette.primary.main}`} 0%
    );
  }
`;

export const HomePageStyles = {
  LinkWrapper,
  PaperWrapper,
};
