import { useEffect, useState } from 'react';
import { useGetCurrentAccount, useQueryData, GetCurrentAccountResponse } from '@ecolytiq/js-data-provider';

import { CurrentAccount } from 'impactApp/modules/HomePage';
import { useDeps } from 'App/context';

export const useCurrentAccountData = () => {
  const { httpClientService } = useDeps();

  const [response, setResponse] = useState<Omit<CurrentAccount, 'isLoading'>>({
    firstLogin: false,
    onboardingAnswers: undefined,
  });
  const { firstLogin, onboardingAnswers } = response;
  const { isLoading, isFetching, data } = useQueryData<{ data: GetCurrentAccountResponse }>(
    useGetCurrentAccount(httpClientService),
    {
      staleTime: 0,
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data?.data) {
      setResponse({
        onboardingAnswers: data.data.currentAccount.onboardingAnswers,
        firstLogin: data.data.currentAccount.firstLogin,
      });
    }
  }, [data, setResponse]);

  return {
    isLoading: isLoading || isFetching,
    onboardingAnswers,
    firstLogin,
  };
};
