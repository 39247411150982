import { SVGProps } from 'react';

export const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path d='M22 12H2' stroke='#414042' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.8' />
    <path
      d='M11.3333 3L2 12L11.3333 21'
      stroke='#414042'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.8'
    />
  </svg>
);
