import { useTheme } from 'styled-components/macro';

import { ArrowForwardIcon } from 'components/Icons';
import { ComparisonValue } from 'App/types';

import { ComparisonArrowIconWrapper } from './ComparisonArrowIcon.style';

type ComparisonArrowIconProps = {
  trend: ComparisonValue;
};

export const ComparisonArrowIconComponent = ({ trend }: ComparisonArrowIconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ComparisonArrowIconWrapper trend={trend}>
      <ArrowForwardIcon color={theme.palette.common.white} />
    </ComparisonArrowIconWrapper>
  );
};
