import styled, { css } from 'styled-components/macro';

import { Grid } from 'components';
import { Typography } from '@mui/material';

const Ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled(Grid)<{ $position?: string }>`
  align-items: ${({ $position }) => $position || 'flex-start'};
  padding: 0 16px;
  margin-bottom: 28px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 36px;
  }
`;

const Title = styled(Typography)`
  ${Ellipsis};
  margin-bottom: 4px;
`;

const Subtitle = styled(Typography)`
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { lightGreyText },
        },
      },
    },
  }) => lightGreyText};
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

export const ListHeaderStyles = {
  Subtitle,
  Title,
  TitleWrapper,
  Wrapper,
};
