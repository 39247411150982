import { useParams } from 'react-router-dom';

import { Layout, CURRENT_DATE } from 'App/components';
import { Categories, CategoriesTranslation, SubCategoriesImages } from 'App/types';
import { theme } from 'App/style';

import { GoBackButton, Header as LayoutHeader, InnerContainer } from 'components';
import { Grid, Container, useMediaQuery } from '@mui/material';

import { useUserContext } from 'App/context';

import { subMonths } from 'date-fns';

import { RoutePaths } from 'impactApp/routes';

import { DidYouKnow, FoodSettingsButton, ReportDetailsHeader, ReportDetailsTransactionList } from './submodules';
import { Report } from './ReportDetails.styles';
import { useCO2MonthlyData } from './useCO2MonthlyData';
import { useRandomInsightsByCategoryData } from './useRandomInsightsByCategoryData';

export const ReportDetails = () => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const { category } = useParams<{
    category: string;
  }>();

  const [{ monthDelta }] = useUserContext();

  const date = subMonths(CURRENT_DATE, monthDelta);

  const categoryType = category?.toUpperCase() as Categories;
  const { totalCo2ByMonth } = useCO2MonthlyData(
    {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    },
    categoryType
  );

  const { randomInsightByCategory } = useRandomInsightsByCategoryData(categoryType);

  const insight: any = randomInsightByCategory;
  const { HOME } = RoutePaths;

  const Insight = insight?.title ? (
    <Report.InsightsWrapper>
      <DidYouKnow
        category={
          insight?.categories?.length > 0
            ? SubCategoriesImages[insight?.categories[0] as keyof typeof SubCategoriesImages]
            : SubCategoriesImages['ex:misc.zero']
        }
        color={theme.palette.grey[400]}
        content={insight?.text}
        title={insight?.title}
      />
    </Report.InsightsWrapper>
  ) : (
    ''
  );

  return (
    <Layout
      bgColor='light'
      hideContainer
      header={
        <LayoutHeader
          button={<GoBackButton url={HOME} />}
          transparent
          title={CategoriesTranslation[category?.toUpperCase() as keyof typeof CategoriesTranslation]}
          rightButton={category === 'food' ? <FoodSettingsButton /> : <></>}
          showDesktopNavigation
        />
      }
    >
      <ReportDetailsHeader category={categoryType} currentTotalCo2ByMonth={totalCo2ByMonth} />
      <Report.Wrapper>
        <Container maxWidth='xl' disableGutters={!isMd}>
          <InnerContainer>
            <Grid
              container
              justifyContent='center'
              alignItems='flex-start'
              flexDirection={{ sm: 'column', md: 'row' }}
              columnSpacing={{ md: 3, lg: 7 }}
              pt={{ md: 8 }}
            >
              <Grid item md={4} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }}>
                <Report.ListWrapper>
                  <ReportDetailsTransactionList category={categoryType} currentTotalCo2ByMonth={totalCo2ByMonth} />
                </Report.ListWrapper>
              </Grid>
              <Grid item md={4} lg={6} sx={{ width: { sm: '100%', md: 'auto' } }}>
                {Insight}
              </Grid>
            </Grid>
          </InnerContainer>
        </Container>
      </Report.Wrapper>
    </Layout>
  );
};
