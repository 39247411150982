import { InfoIcon } from 'components';

import { LinkStyles } from './Link.styles';

type LinkProps = {
  onClick?: () => void;
  text: string;
  textColor: string;
};

export const Link = ({ text, textColor, onClick }: LinkProps) => {
  return (
    <LinkStyles.Wrapper $textColor={textColor} onClick={onClick}>
      <InfoIcon color={textColor} />
      <LinkStyles.Text variant='h6' sx={{ typography: { sm: 'subtitle3', md: 'subtitle2' } }}>
        {text}
      </LinkStyles.Text>
    </LinkStyles.Wrapper>
  );
};
