import { theme } from 'App/style';
import { InfoIcon } from 'components';

import { HowItWorksStyles } from './HowItWorks.styles';

type HowItWorksProps = {
  openCallback?: () => void;
};

export const HowItWorks = ({ openCallback }: HowItWorksProps) => {
  return (
    <HowItWorksStyles.InfoButton onClick={openCallback} id='how-it-works-button'>
      <InfoIcon color={theme.palette.common.black} />
    </HowItWorksStyles.InfoButton>
  );
};
