import { SVGProps } from 'react';

interface InfoPros {
  color?: string;
  props?: SVGProps<SVGSVGElement>;
}

export const InfoIcon = ({ props, color }: InfoPros): JSX.Element => (
  <svg {...props} xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
    <g clipPath='url(#clip0_4891_15765)'>
      <path
        d='M7.9995 0.857143C9.89333 0.859428 11.7089 1.61276 13.0481 2.95191C14.3872 4.29098 15.1405 6.10647 15.1429 8.00018C15.1428 9.41284 14.7239 10.7938 13.9391 11.9684C13.1542 13.143 12.0386 14.0585 10.7335 14.5991C9.42827 15.1398 7.99208 15.2812 6.6065 15.0056C5.22092 14.73 3.94819 14.0497 2.94924 13.0508C1.95029 12.0518 1.27 10.7791 0.994394 9.3935C0.718786 8.00792 0.860238 6.57173 1.40086 5.26655C1.94149 3.96136 2.85701 2.8458 4.03164 2.06093C5.20614 1.27616 6.58695 0.857242 7.9995 0.857143ZM9.21219 13.2122C9.53368 12.8907 9.71429 12.4547 9.71429 12V8C9.71429 7.54534 9.53368 7.10931 9.21219 6.78782C9.06606 6.64169 8.89626 6.52466 8.7118 6.44047C8.97292 6.34103 9.21283 6.18703 9.41422 5.98564C9.78929 5.61057 10 5.10186 10 4.57143C10 4.17587 9.88271 3.78919 9.66294 3.46029C9.44318 3.13139 9.13082 2.87505 8.76537 2.72367C8.39992 2.57229 7.99778 2.53269 7.60982 2.60986C7.22186 2.68703 6.86549 2.87751 6.58579 3.15722C6.30609 3.43692 6.1156 3.79329 6.03843 4.18125C5.96126 4.56921 6.00087 4.97134 6.15224 5.3368C6.30362 5.70225 6.55997 6.01461 6.88886 6.23437C7.01446 6.31829 7.14848 6.38727 7.2882 6.44048C7.10375 6.52466 6.93395 6.64169 6.78782 6.78782C6.46633 7.10931 6.28572 7.54534 6.28572 8L6.28572 12C6.28572 12.4547 6.46633 12.8907 6.78782 13.2122C7.10931 13.5337 7.54535 13.7143 8 13.7143C8.45466 13.7143 8.8907 13.5337 9.21219 13.2122Z'
        fill={color}
        stroke={color}
        strokeWidth='1.71429'
      />
    </g>
    <defs>
      <clipPath id='clip0_4891_15765'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);
