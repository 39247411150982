import { HighlightedColor, Categories, CategoriesTranslation } from 'App/types';
import { convertGramsToLargerUnits, formatNumber } from 'App/utils';
import { ListStyles, ListItem, ProgressBar } from 'components';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { renderIcon } from 'impactApp/modules/ReportDetails/utils';

type FootprintListItemProps = {
  name: string;
  co2: number;
  co2Ratio: number;
  progressBarColor: string;
};

export const FootprintListItem = ({ name, co2, co2Ratio, progressBarColor }: FootprintListItemProps) => {
  const { REPORTS } = RoutePaths;
  const { BLACK } = HighlightedColor;
  const convertedCo2 = convertGramsToLargerUnits(co2);
  const footprintValue = `${formatNumber(+convertedCo2[0])} ${convertedCo2[1]}`;
  const co2Value = `${formatNumber(+co2Ratio.toFixed(2))}%`;
  const redirectUrl = `${REPORTS}/${name.toLowerCase()}`;
  const translatedTitle = CategoriesTranslation[name as keyof typeof CategoriesTranslation];

  return (
    <>
      <ListItem
        appearance={BLACK}
        icon={renderIcon(name as Categories)}
        information={<ProgressBar progressBarColor={progressBarColor} value={co2Ratio} />}
        redirectURL={redirectUrl}
        title={translatedTitle}
        value={co2Value}
        weightValue={footprintValue}
      />
      <ListStyles.Divider />
    </>
  );
};
