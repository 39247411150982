import { SVGProps } from 'react';

export const KeyboardArrowRightIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='21' height='24' viewBox='0 0 21 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M1.24805 12.4971H19.748M19.748 12.4971L15.998 16.2471M19.748 12.4971L15.998 8.74707'
      stroke='currentcolor'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
