import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

const Merchant = styled(Typography)`
  margin-bottom: 4px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 12px;
  }
`;

const Price = styled(Typography)`
  margin-bottom: 8px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 12px;
  }
`;

export const MerchantWrapperStyles = {
  Merchant,
  Price,
};
