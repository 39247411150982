import { Typography, Container, Grid } from '@mui/material';

import { renderBackground } from 'impactApp/modules';
import { Categories, CategoriesTranslation } from 'App/types';
import { convertGramsToLargerUnits } from 'App/utils';
import { InfoCircle } from 'components';
import { reportDetails } from 'impactApp/translations';

import { Co2Footprint } from '@ecolytiq/js-data-provider';

import { ReportDetailsHeaderStyles } from './ReportDetailsHeader.styles';

type ReportDetailsHeaderProps = {
  category: Categories;
  currentTotalCo2ByMonth: Co2Footprint | {};
};

export const ReportDetailsHeader = ({ category, currentTotalCo2ByMonth }: ReportDetailsHeaderProps) => {
  const {
    title: { prefix, postfix },
  } = reportDetails;
  const footprint = convertGramsToLargerUnits((currentTotalCo2ByMonth as any)?.value || 0);

  const translatedCategory = CategoriesTranslation[category as keyof typeof CategoriesTranslation];
  const title = `${prefix}${translatedCategory.toLowerCase()}\n${postfix}`;

  return (
    <Container maxWidth='xl' disableGutters>
      <ReportDetailsHeaderStyles.ContentWithBackground category={category}>
        <Grid
          container
          flexDirection={{ sm: 'column', md: 'row' }}
          pl={{ sm: 3 }}
          px={{ md: 10, xl: 15 }}
          columnGap={{ xl: 10 }}
        >
          <Grid item>
            <Typography
              align='left'
              color='primary.main'
              variant='h6'
              sx={{ typography: { lg: 'h5' }, fontWeight: { lg: 500 } }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item width='100%'>
            <Grid
              container
              width='100%'
              flexWrap='nowrap'
              flexDirection={{ sm: 'row' }}
              justifyContent={{ sm: 'center', xl: 'end' }}
              position='relative'
              bottom={{ lg: '12px' }}
            >
              <ReportDetailsHeaderStyles.CircleWrapper>
                <InfoCircle value={footprint} />
              </ReportDetailsHeaderStyles.CircleWrapper>
              <ReportDetailsHeaderStyles.ImageWrapper>
                {renderBackground(category)}
              </ReportDetailsHeaderStyles.ImageWrapper>
            </Grid>
          </Grid>
        </Grid>
      </ReportDetailsHeaderStyles.ContentWithBackground>
    </Container>
  );
};
