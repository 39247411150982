import {
  HomePage,
  TransactionDetailsPage,
  TransactionDetailsEditPage,
  FoodSettingsPage,
  ProfileSettings,
  LogoutPage,
  SSOPage,
  LoadingPage,
  ReportDetails,
} from 'impactApp/modules';
import { RoutePaths } from 'impactApp/routes/routePaths';
import { routes } from 'impactApp/translations';

const {
  HOME,
  REPORTS,
  TRANSACTION_DETAILS,
  TRANSACTION_DETAILS_EDIT,
  PROFILE_SETTINGS,
  FOOD_SETTINGS,
  LOGOUT_PAGE,
  SSO_PAGE,
  LOADING_PAGE,
} = RoutePaths;

const {
  homePage,
  reports,
  transactions,
  transactionsEdit,
  profileSettings,
  foodSettings,
  logoutPage,
  ssoPage,
  loadingPage,
} = routes;

export const ROUTES = {
  HOMEPAGE: {
    name: homePage,
    id: 'id_home_page',
    url: HOME,
    component: HomePage,
    private: true,
  },
  REPORTS: {
    name: reports,
    id: 'id_reports',
    url: `${REPORTS}/:category`,
    component: ReportDetails,
    private: true,
  },
  TRANSACTIONS: {
    name: transactions,
    id: 'id_transactions',
    url: TRANSACTION_DETAILS,
    component: TransactionDetailsPage,
    private: true,
  },
  TRANSACTIONS_EDIT: {
    name: transactionsEdit,
    id: 'id_transactions_edit',
    url: TRANSACTION_DETAILS_EDIT,
    component: TransactionDetailsEditPage,
    private: true,
  },
  PROFILE_SETTINGS: {
    name: profileSettings,
    id: 'id_profile_settings_page',
    url: PROFILE_SETTINGS,
    component: ProfileSettings,
    private: true,
  },
  FOOD_SETTINGS: {
    name: foodSettings,
    id: 'id_food_settings_page',
    url: FOOD_SETTINGS,
    component: FoodSettingsPage,
    private: true,
  },
  LOGOUT_PAGE: {
    name: logoutPage,
    id: 'id_logout_page',
    url: LOGOUT_PAGE,
    component: LogoutPage,
    private: false,
  },
  SSO_PAGE: {
    name: ssoPage,
    id: 'id_sso_page',
    url: SSO_PAGE,
    component: SSOPage,
    private: false,
  },
  LOADING_PAGE: {
    name: loadingPage,
    id: 'id_sso_page',
    url: LOADING_PAGE,
    component: LoadingPage,
    private: false,
  },
};
