import styled from 'styled-components/macro';
import { Grid as MuiGrid } from '@mui/material';
import * as CSS from 'csstype';

import { GridStylesProps } from './types';

const addStylesByProps = (prop: string, param?: CSS.PropertyValue<string | number>) => param && { [prop]: param };

export const GridStyles = styled(MuiGrid)<GridStylesProps>`
  display: ${({ $display }) => ($display ? `${$display}` : 'flex')};
  ${({ $alignItems }) => addStylesByProps('align-items', $alignItems)}
  ${({ $flex }) => addStylesByProps('flex', $flex)}
  ${({ $flexDirection }) => addStylesByProps('flex-direction', $flexDirection)}
  ${({ $height }) => addStylesByProps('height', $height)}
  ${({ $justifyContent }) => addStylesByProps('justify-content', $justifyContent)}
  ${({ $maxWidth }) => addStylesByProps('max-width', $maxWidth)}
  ${({ $minHeight }) => addStylesByProps('min-height', $minHeight)}
  ${({ $width }) => addStylesByProps('width', $width)}
`;
