import { Grid, Typography } from '@mui/material';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled(Grid)<{
  $isExtraLarge?: boolean;
}>`
  ${({ $isExtraLarge }) =>
    $isExtraLarge &&
    css`
      margin-left: 10px;
      ${(props) => props.theme.breakpoints.up('md')} {
        margin-left: 0px;
      }
      ${(props) => props.theme.breakpoints.up('lg')} {
        margin-left: 10px;
      }
    `};
`;

const Value = styled(Typography)<{
  $isExtraLarge?: boolean;
}>`
  display: inline;
  margin-bottom: 16px;
  font-weight: 500;
  ${(props) => props.theme.breakpoints.up('lg')} {
    ${({ $isExtraLarge }) =>
      $isExtraLarge &&
      css`
        font-size: 96px;
        line-height: 68px;
      `};
  }
`;

const Unit = styled(Typography)`
  display: inline;
  font-weight: 500;
  padding-left: 4px;
`;

const Suffix = styled(Unit)`
  padding: 0;
`;

export const HeaderWithValueStyles = {
  Suffix,
  Unit,
  Value,
  Wrapper,
};
