import styled from 'styled-components/macro';
import { Typography } from '@mui/material';

const Wrapper = styled.div`
  background-color: ${({ theme }) => `${theme.palette.primary.light}`};
  position: relative;
  z-index: 1;
  /* use footerHeight from theme.ts here */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  ${(props) => props.theme.breakpoints.up('md')} {
    height: 64px;
  }
`;

const Element = styled.li`
  display: inline-flex;
  align-items: center;
  a {
    text-decoration: none;
    display: flex;
    color: ${({ theme }) => `${theme.palette.grey[900]}`};
  }
`;

const List = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${Element} {
    margin: 0 16px;
  }
`;

export const Link = styled(Typography)`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => `${theme.palette.primary.main}`};
  }
`;

export const Footer = {
  Wrapper,
  Element,
  List,
  Link,
};
