import styled from 'styled-components/macro';
import { color } from 'App/style/theme';
import { Typography } from '@mui/material';

const Text = styled(Typography)`
  color: ${color.white};
  text-align: center;
  margin-bottom: 28px;
  padding: 0 32px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 40px;
`;

const CurveWrapper = styled.div`
  height: 100%;
  min-height: calc(100vh - 68px);
  background: linear-gradient(transparent 420px, ${({ theme }) => `${theme.palette.primary.main}`} 0%);
  ${(props) => props.theme.breakpoints.up('lg')} {
    background: linear-gradient(transparent 515px, ${({ theme }) => `${theme.palette.primary.main}`} 0%);
  }
`;

const ImgWrapper = styled.div`
  height: 340px;
  max-width: 400px;
  margin: 0 -24px 20px;
`;

const Img = styled.img`
  max-width: 100%;
`;

const Wrapper = styled.div`
  height: 100vh;
`;

export const ProfileSettingsStyles = {
  Text,
  ButtonWrapper,
  CurveWrapper,
  ImgWrapper,
  Img,
  Wrapper,
};
