import { CurrencySymbol } from 'App/translations';

export const formatNumber = (value: number) => new Intl.NumberFormat('en-CA').format(value);
export const formatCurrency = (value: number, currencyCode: CurrencySymbol) =>
  new Intl.NumberFormat('en-CA', { style: 'currency', currency: currencyCode }).format(value);

export const formatCash = (n: number) => {
  if (n >= 1e3 && n < 1e6) return `${formatNumber(+(n / 1e3).toFixed(1))}K`;
  if (n >= 1e6 && n < 1e9) return `${formatNumber(+(n / 1e6).toFixed(1))}M`;
  if (n >= 1e9 && n < 1e12) return `${formatNumber(+(n / 1e9).toFixed(1))}B`;
  if (n >= 1e12) return `${formatNumber(+(n / 1e12).toFixed(1))}T`;
  return formatNumber(n);
};
