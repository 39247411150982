import { CircularLoading } from 'components';

import { useImportSVG } from './useImportSVG';

type IconProps = {
  name: string;
  height: string;
  width: string;
};

export const Icon = ({ name, ...rest }: IconProps) => {
  const { error, loading, SvgIcon } = useImportSVG(name);

  if (error) {
    throw new Error(error.message);
  }
  if (loading) {
    return <CircularLoading />;
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />;
  }

  return null;
};
