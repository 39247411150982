import { PropsWithChildren } from 'react';

import { BasicListItemStyles } from 'components/List/ListItem/BasicLIstItem/BasicListItem.styles';
import { Transaction } from '@ecolytiq/js-data-provider';

export const NavLink = ({
  redirectURL,
  transaction,
  children,
}: PropsWithChildren<{
  redirectURL: string;
  transaction?: Transaction;
}>) => {
  return (
    <BasicListItemStyles.Link to={redirectURL} state={{ transaction }}>
      {children}
    </BasicListItemStyles.Link>
  );
};
