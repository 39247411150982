import { DotProps } from 'recharts';

import { ChartMonthData, MARKER_RADIUS, ChartMarkerIcon } from 'App/components';

type ChartMarkerProps = {
  monthDelta: number;
  payload?: ChartMonthData;
} & DotProps;

export const ChartMarker = (props: ChartMarkerProps) => {
  const { monthDelta: monthDeltaFromProps, cx, cy, payload } = props;

  if (payload === undefined || !cx || !cy) {
    return null;
  }

  const { date, monthDelta } = payload;

  return monthDelta === monthDeltaFromProps ? (
    <ChartMarkerIcon key={[date, monthDelta].join('-')} x={cx - MARKER_RADIUS / 2} y={cy - MARKER_RADIUS / 2} />
  ) : null;
};
