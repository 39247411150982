import { PropsWithChildren } from 'react';

import { GridStyles } from './Grid.styles';
import { GridProps } from './types';

export const Grid = ({
  alignItems,
  children,
  component = 'div',
  display,
  flex,
  flexDirection,
  height,
  justifyContent,
  maxWidth,
  minHeight,
  spacingInside,
  width,
  ...restProps
}: PropsWithChildren<GridProps>) => (
  <GridStyles
    component={component}
    {...restProps}
    $alignItems={alignItems}
    $display={display}
    $flex={flex}
    $flexDirection={flexDirection}
    $height={height}
    $justifyContent={justifyContent}
    $maxWidth={maxWidth}
    $minHeight={minHeight}
    $spacingInside={spacingInside}
    $width={width}
  >
    {children}
  </GridStyles>
);
