import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';

interface TCContextI {
  isTCOpen: boolean;
  setIsTCOpen?: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  isTCOpen: false,
};

export const TCContext = createContext<TCContextI>(defaultState);

export const TCProvider = ({ children }: PropsWithChildren<Record<string, any>>) => {
  const [isTCOpen, setIsTCOpen] = useState(defaultState.isTCOpen);

  return <TCContext.Provider value={{ isTCOpen, setIsTCOpen }}>{children}</TCContext.Provider>;
};
