import { Dispatch } from 'react';

import { UserAction } from 'App/context';

export type Routes = {
  name: string;
  id: string;
  url: string;
  private: boolean;
};

export type NavigationRoutes = {
  name: string;
  id: string;
  link: string;
  outlink?: boolean;
};

export type NavigationItem = {
  name: string;
  id: string;
  onClick: (dispatch: Dispatch<UserAction>) => void;
};

export function isNavigationItem(item: NavigationRoutes | NavigationItem): item is NavigationItem {
  return 'onClick' in item;
}
