import { Dialog as MuiDialog, DialogContent, Typography } from '@mui/material';

import styled, { css } from 'styled-components/macro';

import { CloseIcon } from 'components/Icons';

const Dialog = styled(MuiDialog)<{
  $isCenteredText?: boolean;
  $hasWhiteBackground?: boolean;
}>`

${({ $isCenteredText }) =>
  $isCenteredText &&
  css`
    text-align: center;
  `}



  .MuiDialog-container {
    padding: 16px;


  background: rgba(${({
    theme: {
      palette: {
        primary: { dark },
      },
    },
  }) => dark}, 0.4);

  .MuiDialog-paper {
    margin: 0;
    max-width: 560px;
    min-width: 330px;
    border-radius: ${({ theme }) => `${theme.palette.custom.radius.radiusSmall}`};
    background: white;
    box-shadow: none;

    ${({ $hasWhiteBackground }) =>
      $hasWhiteBackground &&
      css`
        &:before {
          content: '';
          height: 256px;
          width: 100%;
          background: white;
        }
      `}
      
    ${(props) => props.theme.breakpoints.up('md')} {
      width: 100%;
    }
  }
`;

const Content = styled(DialogContent)<{
  $hasExtraPadding?: boolean;
  $hasLightPadding?: boolean;
  $contentHasDarkBackground?: boolean;
}>`
  padding: 60px 36px 40px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding: 76px 36px 40px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => `${theme.palette.secondary.main}`};
  }

  p {
    padding-bottom: 12px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  ${({ $contentHasDarkBackground }) =>
    $contentHasDarkBackground &&
    css`
      background: ${({ theme }) => `${theme.palette.primary.dark}`};
      color: ${({ theme }) => `${theme.palette.common.white}`};
      ${(props) => props.theme.breakpoints.up('lg')} {
        padding-left: 90px;
        padding-right: 90px;
      }
    `}

  ${({ $hasExtraPadding }) =>
    $hasExtraPadding &&
    css`
      padding-top: 76px;
    `}

    ${({ $hasLightPadding }) =>
    $hasLightPadding &&
    css`
      padding: 36px 30px 40px;

      ${(props) => props.theme.breakpoints.up('lg')} {
        padding: 30px;
      }
    `}
`;

const CloseButton = styled(CloseIcon)<{
  $closeIconColor?: string;
}>`
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 30px;
  width: 24px;
  height: 24px;
  z-index: 1;
  line {
    stroke: ${({ theme: { palette } }) => `${palette.primary.main}`};
  }
  ${({ $closeIconColor }) =>
    $closeIconColor &&
    css`
      line {
        stroke: ${$closeIconColor};
      }
    `};
`;

const Title = styled(Typography)`
  padding-bottom: 20px;
  text-align: center;
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-top: -12px;
  }
`;

const Bottom = styled('div')`
  padding-top: 32px;
`;

const ImageWrapper = styled.div`
  width: 230px;
  height: 230px;
  position: absolute;
  top: 48px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1;
  svg {
    width: 100%;
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 280px;
  }
`;

const CheckboxLabel = styled(Typography)`
  color: ${({ theme: { palette } }) => `${palette.common.black}`};
  margin-left: 16px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-left: 32px;
  }
`;

const InfoWrapper = styled.div`
  margin-left: 12px;
  height: 16px;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-left: 9px;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme: { palette } }) => `${palette.grey[400]}`};
  &:first-child {
    padding-top: 16px;
    ${(props) => props.theme.breakpoints.up('lg')} {
      padding-top: 20px;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  p {
    padding: 0;
  }
`;

export const ModalStyles = {
  CloseButton,
  Content,
  Title,
  Bottom,
  Dialog,
  ImageWrapper,
  CheckboxLabel,
  InfoWrapper,
  CheckboxWrapper,
};
