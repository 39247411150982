import { SVGProps } from 'react';

export const BagIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.99301 23.25H2.45101C2.23768 23.2499 2.02682 23.2044 1.83249 23.1164C1.63817 23.0283 1.46485 22.8999 1.32412 22.7395C1.18338 22.5792 1.07847 22.3907 1.01637 22.1866C0.954272 21.9825 0.936423 21.7675 0.964014 21.556L2.82201 7.306C2.86913 6.94473 3.04613 6.61297 3.31994 6.37264C3.59376 6.13231 3.94569 5.99985 4.31001 6H5.42601M8.99301 23.25L6.91301 7.306C6.86592 6.9449 6.68907 6.61327 6.41546 6.37297C6.14185 6.13266 5.79017 6.0001 5.42601 6M8.99301 23.25H21.534C21.7474 23.2501 21.9584 23.2046 22.1529 23.1167C22.3473 23.0287 22.5207 22.9003 22.6616 22.7399C22.8024 22.5796 22.9074 22.391 22.9696 22.1869C23.0317 21.9827 23.0496 21.7676 23.022 21.556L21.163 7.306C21.1159 6.9449 20.9391 6.61327 20.6655 6.37297C20.3918 6.13266 20.0402 6.0001 19.676 6H14.243M5.42601 6H11.993M17.993 10.5V3.75C17.993 3.35603 17.9154 2.96593 17.7647 2.60195C17.6139 2.23797 17.3929 1.90726 17.1143 1.62868C16.8358 1.3501 16.505 1.12913 16.1411 0.978361C15.7771 0.827597 15.387 0.75 14.993 0.75C14.599 0.75 14.2089 0.827597 13.845 0.978361C13.481 1.12913 13.1503 1.3501 12.8717 1.62868C12.5931 1.90726 12.3721 2.23797 12.2214 2.60195C12.0706 2.96593 11.993 3.35603 11.993 3.75V10.5M9.57801 1.253C9.1667 1.52742 8.8296 1.89925 8.59669 2.33542C8.36378 2.77158 8.24228 3.25855 8.24301 3.753V6'
      stroke='#2D424D'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </svg>
);
