import { keyframes } from 'styled-components/macro';

const ANIMATION_DURATION = 0.2;

export const animationList = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
      delay: ANIMATION_DURATION,
      type: 'spring',
      stiffness: 100,
    },
  },
};

export const bounceIn = keyframes`
 from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

export const fadeInOut = {
  initial: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: ANIMATION_DURATION,
    },
  },
  hide: {
    opacity: 0,
    transition: {
      ease: 'easeIn',
      duration: ANIMATION_DURATION,
    },
  },
};

export const float = keyframes`
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }
  
	100% {
		transform: translateY(0px);
	}
`;
