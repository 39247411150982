import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import { enCA } from 'date-fns/locale';

const formatMonthFromDate = (date: Date) => format(date, 'MMMM', { locale: enCA });
const formatToSimpleDate = (date: string) => format(new Date(date), 'yyyy-MM-dd', { locale: enCA });
const formatFullDate = (date: string) => {
  const updatedDate = date[date.length - 1] === 'Z' ? date.substring(0, date.length - 1) : date;
  return format(parse(formatToSimpleDate(updatedDate), 'yyyy-MM-dd', new Date()), 'MMMM dd, yyyy', { locale: enCA });
};
const formatMonthAndYear = (date: string) => format(parseISO(date), 'MMMM yyyy', { locale: enCA });
const formatMonthAndYearFromDate = (date: Date) => format(date, 'MMMM yyyy', { locale: enCA });
const formatFullISO = (date: string) => format(parseISO(date), "yyyy MM dd'T'HH:mm:ss'Z'", { locale: enCA });
const formatEnrichedDataDate = (date: string): string =>
  format(parseISO(date), "yyyy-MM-dd'T'HH:mm:ss'Z'", { locale: enCA });

export {
  formatFullDate,
  formatMonthAndYear,
  formatFullISO,
  formatEnrichedDataDate,
  formatMonthFromDate,
  formatMonthAndYearFromDate,
};
