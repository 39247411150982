import { SVGProps } from 'react';

export const ExpandLessIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4.00016 10.6666L7.66683 6.66663L11.3335 10.6666'
      stroke='#414042'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
