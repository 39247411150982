import { Layout } from 'App/components';

import { logout } from 'impactApp/translations';

import { LogoutStyles } from './LogoutPage.styles';

export const LogoutPage = () => {
  const { title, text, vancityButton, vancityURL } = logout;

  return (
    <Layout bgColor='dark' isFullHeight>
      <LogoutStyles.Wrapper>
        <LogoutStyles.Icon />
        <LogoutStyles.Title variant='h6'>{title}</LogoutStyles.Title>
        <LogoutStyles.Text>{text}</LogoutStyles.Text>
        <LogoutStyles.ButtonWrapper onClick={() => window.open(vancityURL, '_self')} text={vancityButton} />
      </LogoutStyles.Wrapper>
    </Layout>
  );
};
