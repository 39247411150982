import { SVGProps, useEffect, useState } from 'react';

interface FoodLessMeatProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodLessMeat = ({ props, isActive }: FoodLessMeatProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M28 20.9221C28 19.0649 27.1476 17.3144 25.6115 16.0082C24.0844 14.7109 22.0599 14 19.9023 14C17.6027 14 15.667 14.4443 14.3174 15.2796C13.5716 15.7416 12.9945 16.3192 12.6038 17.0034C12.2575 17.6077 12.0533 18.3096 12 19.0738C12 19.1449 12.0355 21.5974 12.0355 21.5974C12.0355 22.6637 12.2752 23.2857 12.9145 23.6589C13.4739 23.9877 14.2286 24.0321 15.1077 24.0943C16.333 24.1743 17.8513 24.272 19.4406 25.1606C21.2519 26.1647 22.8324 26.7601 24.1376 26.9378C24.4129 26.9733 24.6704 26.9911 24.919 26.9911C25.9667 26.9911 26.7481 26.6357 27.3252 25.9959C28.0622 25.1784 28 23.1613 28 20.9221ZM26.8635 25.6227C26.3219 26.2714 25.434 26.5113 24.2175 26.3513C22.9834 26.1825 21.4739 25.6049 19.7248 24.6364C18.02 23.6945 16.4218 23.5878 15.1432 23.499C13.4384 23.3835 12.586 23.3301 12.586 21.5619V21.3397C13.2164 21.9795 14.2819 22.0861 15.4362 22.2016C16.7592 22.3349 18.2597 22.486 19.7514 23.3479C21.5627 24.3964 23.1521 24.9296 24.4928 24.9296C25.5938 24.9296 26.4994 24.5564 27.1032 23.8366C27.2098 23.7122 27.2986 23.5878 27.3873 23.4545C27.343 24.7252 27.2364 25.1784 26.8635 25.6227ZM26.6415 23.4457C26.1443 24.0321 25.4162 24.3254 24.4839 24.3254C23.2764 24.3254 21.7403 23.81 20.0444 22.8236C18.4462 21.8995 16.8124 21.7307 15.4983 21.5974C13.4739 21.4019 12.586 21.242 12.586 19.5092C12.586 17.8831 13.2786 16.6302 14.6371 15.7861C15.889 15.013 17.7092 14.6042 19.9023 14.6042C24.04 14.6042 27.4051 17.4388 27.4051 20.931C27.4051 21.9973 27.1387 22.8681 26.6415 23.4457Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='0.5'
      />
      <path
        d='M17.1234 16.5234C16.0934 16.5234 15.2588 17.1988 15.2588 18.034C15.2588 18.8693 16.0934 19.5446 17.1234 19.5446C18.1533 19.5446 18.988 18.8693 18.988 18.034C18.9969 17.2076 18.1622 16.5234 17.1234 16.5234ZM17.1234 18.9582C16.4397 18.9582 15.8537 18.5405 15.8537 18.0429C15.8537 17.5453 16.4308 17.1277 17.1234 17.1277C17.8248 17.1277 18.3931 17.5364 18.3931 18.0429C18.3931 18.5494 17.8248 18.9582 17.1234 18.9582Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='0.5'
      />
    </svg>
  );
};
