import { SubCategoriesImages } from 'App/types';

import { reportDetails } from 'impactApp/translations';

import { Icon } from './Icon/index';

import { Content } from './Content/index';

import { DidYouKnowStyles } from './DidYouKnow.styles';

type DidYouKnowProps = {
  category: SubCategoriesImages;
  color: string;
  content: string | null;
  title: string | null;
};

export const DidYouKnow = ({ category: selectedCategory, color, content, title }: DidYouKnowProps) => {
  const {
    didYouKnowSection: { title: sectionTitle },
  } = reportDetails;

  return (
    <DidYouKnowStyles.Content>
      <DidYouKnowStyles.TextWrapper>
        <DidYouKnowStyles.Text align='left' color={color} variant='subtitle2' sx={{ typography: { lg: 'subtitle1' } }}>
          {sectionTitle}
        </DidYouKnowStyles.Text>
        <Content
          image={<Icon height='100%' name={selectedCategory} width='100%' />}
          information={content}
          title={title}
        />
      </DidYouKnowStyles.TextWrapper>
    </DidYouKnowStyles.Content>
  );
};
