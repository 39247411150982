import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { resetStateAction, useUserContext } from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';
import { Dialog } from 'components/Dialog';
import { Grid } from '@mui/material';

import { autoLogout } from 'App/translations';

import { Button, ButtonAppearance } from 'components';

import { appConfig } from 'App/appConfig/appConfig';

import { CharacterTime } from './assets';

import { AutoLogoutStyles } from './AutoLogout.styles';

export const AutoLogout = () => {
  const {
    REACT_APP_AUTO_LOGOUT_TIMER,
    REACT_APP_AUTO_LOGOUT_MODAL_TIMER,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
  } = appConfig;
  const { title, content, button, slug } = autoLogout;
  const [, dispatch] = useUserContext();
  const [isVisible, setVisible] = useState(false);
  const [seconds, setSeconds] = useState(REACT_APP_AUTO_LOGOUT_MODAL_TIMER);
  const [isAwake, setAwake] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const { LOGOUT_PAGE } = RoutePaths;

  const [cookies, , removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);

  const stayAwake = () => {
    setVisible(false);
    setAwake(true);
    setTimeout(() => setSeconds(REACT_APP_AUTO_LOGOUT_MODAL_TIMER), 1000);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    const goBackToHome = () => {
      setVisible(true);
      setAwake(false);
    };

    const restartAutoReset = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        goBackToHome();
      }, REACT_APP_AUTO_LOGOUT_TIMER);
    };

    const onMouseMove = () => {
      restartAutoReset();
    };

    if (location.pathname === LOGOUT_PAGE) return undefined;

    restartAutoReset();

    window.addEventListener('mousemove', onMouseMove);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [LOGOUT_PAGE, REACT_APP_AUTO_LOGOUT_TIMER, location]);

  useEffect(() => {
    if (seconds > 0 && !isAwake) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else if (!isAwake) {
      if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]) {
        removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
        removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
      }
      dispatch(resetStateAction());
      setVisible(false);
      setAwake(true);
      setTimeout(() => setSeconds(REACT_APP_AUTO_LOGOUT_MODAL_TIMER), 1000);
      navigate(LOGOUT_PAGE);
    }
  }, [
    seconds,
    isAwake,
    dispatch,
    REACT_APP_AUTO_LOGOUT_MODAL_TIMER,
    LOGOUT_PAGE,
    navigate,
    cookies,
    REACT_APP_ACCESS_TOKEN_COOKIE_NAME,
    REACT_APP_REFRESH_TOKEN_COOKIE_NAME,
    removeCookie,
  ]);

  return (
    <Dialog
      isOpen={isVisible}
      onClose={() => stayAwake()}
      isCenteredText
      imgComponent={<CharacterTime />}
      hasExtraPadding
      hasWhiteBackground
      contentHasDarkBackground
      removeCloseIcon
      slug={slug}
    >
      <AutoLogoutStyles.Title variant='h6' sx={{ typography: { sm: 'h6', lg: 'subtitle1' } }}>
        {title}
      </AutoLogoutStyles.Title>
      <AutoLogoutStyles.Subtitle variant='body2'>{content}</AutoLogoutStyles.Subtitle>
      <AutoLogoutStyles.Text variant='subtitle2'>Log out in {seconds} seconds</AutoLogoutStyles.Text>
      <Grid display='flex' width='100%' justifyContent='center'>
        <Button appearance={ButtonAppearance.LIGHT} onClick={() => stayAwake()} text={button} />
      </Grid>
    </Dialog>
  );
};
