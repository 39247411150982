import { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from '@mui/material';
import { Button, FooterSection } from 'components';
import { transactionDetails } from 'impactApp/translations/dictionary';

import { postAnswerOnboardingQuestions } from '@ecolytiq/js-data-provider';
import { handleFootPrintRefinement, handlePageRoute, handleSkipProfile } from 'App/utils';

import { color } from 'App/style';

import { useNavigate } from 'react-router-dom';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { answerType } from 'impactApp/modules';

import { useDeps } from 'App/context';

import { common } from 'impactApp/translations';

import { useCurrentAccountData } from '../../../impactApp/modules/FoodSettings/useCurrentAccountData';

import { Modal } from '../Modal';

import { Slide } from './Slide';

import { ProfileSettingsSliderStyles } from './ProfileSettingsSlider.styles';

type ProfileSettingsSlideProps = {
  closeHandler: () => void;
  submitButtonText: string;
};

export const ProfileSettingsSlider = ({ closeHandler, submitButtonText }: ProfileSettingsSlideProps) => {
  const [content, setContent] = useState([]);

  const [showButton, setShowButton] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);

  const [currentAnswers, setCurrentAnswers] = useState<answerType[]>([]);

  const navigate = useNavigate();

  const { httpClientService } = useDeps();

  const { HOME, PROFILE_SETTINGS } = RoutePaths;

  const { onboardingAnswers } = useCurrentAccountData();

  const { closeProfileSettingsModal } = common;

  useEffect(() => {
    const fetchData = async () => {
      const response: any = transactionDetails['feedbackLoop1' as keyof typeof transactionDetails];
      setContent(response.questions);

      if (onboardingAnswers && onboardingAnswers.length > 0) {
        setCurrentAnswers(onboardingAnswers);
      } else {
        const emptyAnswers: answerType[] = [];

        content?.forEach((element: any) => {
          emptyAnswers.push({ questionId: element?.questionId, answerId: '', input: null });
        });
        setCurrentAnswers(emptyAnswers);
      }
    };

    fetchData();
  }, [content, onboardingAnswers]);

  const checkLastSlide = (currentSlide: number, nextSlide: number) => {
    if (nextSlide === content?.length - 1) setShowButton(true);
    else setShowButton(false);
  };

  const handleSubmit = () => {
    if (currentAnswers.length > 0) {
      const postAnswers = postAnswerOnboardingQuestions(
        httpClientService,
        currentAnswers.map(({ questionId, ...rest }) => {
          return rest;
        })
      );

      postAnswers.post();

      handleFootPrintRefinement(PROFILE_SETTINGS, 'PROFILE');
    }
    navigate(HOME);
  };

  const handleMatomoSlides = (currentSlide: number) => {
    handlePageRoute(`${PROFILE_SETTINGS}/${currentSlide + 1}`);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: checkLastSlide,
    afterChange: handleMatomoSlides,
  };

  return (
    <Container disableGutters>
      <Modal
        slug='SKIPPROFILE'
        setOpen={setModalOpen}
        isOpen={isModalOpen}
        onCancel={() => {
          handleSkipProfile(PROFILE_SETTINGS);
          closeHandler();
        }}
        onClose={() => setModalOpen(false)}
        content={closeProfileSettingsModal.content}
        successButtonText={closeProfileSettingsModal.ja}
        cancelButtonText={closeProfileSettingsModal.nein}
      />
      <ProfileSettingsSliderStyles.Wrapper>
        <ProfileSettingsSliderStyles.CloseIcon hoverColor={color.highlightRed} onClick={() => setModalOpen(true)} />
        <ProfileSettingsSliderStyles.Slider {...settings}>
          {content?.map((element: any) => (
            <Slide
              key={`slide-${element?.title}`}
              question={element?.title}
              id={element?.questionId}
              answers={element?.answers}
              oldAnswers={currentAnswers}
              setAnswers={setCurrentAnswers}
              info={element?.info}
            />
          ))}
        </ProfileSettingsSliderStyles.Slider>
        {showButton && (
          <ProfileSettingsSliderStyles.ButtonWrapper>
            <Button iconRight text={submitButtonText} onClick={handleSubmit} />
          </ProfileSettingsSliderStyles.ButtonWrapper>
        )}
        <FooterSection />
      </ProfileSettingsSliderStyles.Wrapper>
    </Container>
  );
};
