import { AccordionAppearance, AccordionDetails, AccordionSummary, useMapAppearanceToColor } from 'components/Accordion';

import { Item } from '../types';

import { AccordionItemStyles } from './AccordionItem.styles';

type AccordionItemProps = {
  appearance: AccordionAppearance;
  body: Item['body'];
  isExpanded: boolean;
  onChange: () => void;
  title: string;
  titleCollapsed?: string;
  counter?: number;
};

export const AccordionItem = ({
  appearance,
  body,
  isExpanded,
  onChange,
  title,
  titleCollapsed,
  counter,
}: AccordionItemProps) => {
  const { iconColor, textColor } = useMapAppearanceToColor(appearance);

  let summaryTitle = title;

  if (!isExpanded && titleCollapsed) {
    summaryTitle = titleCollapsed;
  }
  return (
    <AccordionItemStyles.Item>
      <AccordionItemStyles.Wrapper square expanded={isExpanded} id={title} onChange={onChange}>
        <AccordionSummary
          body={counter !== undefined ? `${counter + 1}. ${summaryTitle} ` : summaryTitle}
          iconColor={iconColor}
          textColor={textColor}
        />
        <AccordionDetails body={body} textColor={textColor} />
      </AccordionItemStyles.Wrapper>
    </AccordionItemStyles.Item>
  );
};
