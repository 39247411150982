import { routes as vancityRoutes } from 'impactApp/translations';
import { RoutePaths as vancityRoutePaths } from 'impactApp/routes/routePaths';

import { NavigationRoutes, NavigationItem } from './types';

const { HOME, LOGOUT_PAGE } = vancityRoutePaths;
const { homePage, logoutPage } = vancityRoutes;

export const routesNavigationList: Array<NavigationRoutes | NavigationItem> = [
  {
    name: homePage,
    id: 'id_home_page',
    link: HOME,
  },
  {
    name: 'Take action',
    id: 'id_take_action_page',
    link: 'https://www.vancity.com/carboncountertakeaction',
    outlink: true,
  },
  {
    name: logoutPage,
    id: 'id_logout_page',
    link: LOGOUT_PAGE,
  },
];
