import styled, { css } from 'styled-components/macro';
import { Toolbar as MuiToolbar, Typography } from '@mui/material';
import { SpanType } from 'components/types';

const Wrapper = styled.div<{ transparent?: boolean; isFixed?: boolean; isInverted?: boolean }>`
  flex-grow: 1;
  position: relative;
  background: ${({ theme, transparent }) => `${transparent ? 'transparent' : theme.palette.primary.main}`};
  ${(props) => props.theme.breakpoints.down('lg')} {
    padding-top: 28px;
    ${({ isFixed }) =>
      isFixed &&
      css`
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 10;
      `}

    ${({ isInverted }) =>
      isInverted &&
      css`
        background: white;
        svg path {
          stroke: ${({ theme }) => `${theme.palette.primary.main}`};
        }
      `}
  }
`;

const Toolbar = styled(MuiToolbar)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  height: 56px;
  min-height: 56px;
  ${(props) => props.theme.breakpoints.up('md')} {
    height: 72px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin: 40px auto 76px;
    z-index: 10;
    position: absolute;
    height: 44px;
    width: 100%;
  }
`;

const RightButton = styled.div`
  width: 40px;
  height: 40px;
`;

const LeftButton = styled.div`
  width: 40px;
  height: 40px;
`;

const Info: SpanType = styled(Typography)`
  display: none;
  ${(props) => props.theme.breakpoints.up('lg')} {
    color: ${({ theme }) => `${theme.palette.primary.dark}`};
    background: ${({ theme }) => `${theme.palette.custom.howItWorksBackground}`};
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;

    svg {
      margin-right: 8px;
    }
    padding: 32px;
  }
`;

const Title = styled(Typography)<{ $isSemiBold?: boolean }>`
  color: ${({ theme }) => `${theme.palette.primary.dark}`};
  text-transform: capitalize;
  ${({ $isSemiBold }) =>
    $isSemiBold &&
    css`
      font-weight: 600;
    `}
`;

const HowItWorksContainer = styled.span`
  margin-left: auto;
  display: flex;
`;

const HowItWorksCloseIconContainer = styled(HowItWorksContainer)`
  cursor: pointer;
  svg {
    margin-right: 0;
    line {
      stroke: ${({ theme }) => `${theme.palette.primary.dark}`};
  }
`;

export const HeaderBar = {
  Toolbar,
  RightButton,
  LeftButton,
  Wrapper,
  Info,
  Title,
  HowItWorksContainer,
  HowItWorksCloseIconContainer,
};
