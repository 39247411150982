import { Grid, Container, useMediaQuery, Typography } from '@mui/material';
import { theme } from 'App/style';
import { SubCategoriesImages, SubCategories, CategoriesTranslation } from 'App/types';

import {
  Header as LayoutHeader,
  CircularLoading,
  GoBackButton,
  Button,
  NavLink,
  ComparableText,
  MerchantWrapper,
} from 'components';

import { HeaderWithValue, Layout, BARS_HEIGHT } from 'App/components';
import {
  convertGramsToLargerUnits,
  formatCurrency,
  formatFullDate,
  useRandomComparablesByIdData,
  useRandomInsightsByIdData,
} from 'App/utils';
import { CurrencySymbol } from 'App/translations';
import { common, transactionDetails } from 'impactApp/translations/dictionary';

import { useLocation } from 'react-router-dom';

import { Transaction } from '@ecolytiq/js-data-provider';

import { RoutePaths } from 'impactApp/routes';

import { DidYouKnow } from '../ReportDetails/submodules';

import { TransactionDetails } from './TransactionDetails.style';

export const TransactionDetailsPage = () => {
  const location = useLocation();
  const transaction: Transaction = location?.state?.transaction;

  const { feedbackLoopButton, textHelper } = transactionDetails;
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const { merchantPlaceholder } = common;
  const { randomInsight } = useRandomInsightsByIdData(transaction?.id);
  const { randomComparable } = useRandomComparablesByIdData(transaction?.id);

  const insight: any = randomInsight;

  const Insight = insight?.title ? (
    <DidYouKnow
      category={SubCategoriesImages[transaction?.category.value as keyof typeof SubCategoriesImages]}
      color={theme.palette.grey[400]}
      content={insight?.text}
      title={insight?.title}
    />
  ) : (
    ''
  );

  if (!transaction) {
    return (
      <Layout bgColor='light' header={<LayoutHeader button={<GoBackButton />} />}>
        <TransactionDetails.Wrapper alignItems='center' height={`calc(100vh - ${BARS_HEIGHT})`}>
          <CircularLoading />
        </TransactionDetails.Wrapper>
      </Layout>
    );
  }

  const {
    id,
    date,
    merchant,
    metaCategory,
    co2Footprint: { value: co2Value },
    amount: { value, currencyCode },
    category: { value: subCategoryValue },
  } = transaction;
  const footprint = convertGramsToLargerUnits(co2Value);

  const feedbackLoopCategory = SubCategories[subCategoryValue as keyof typeof SubCategories];
  const { TRANSACTIONS, REPORTS } = RoutePaths;

  const redirectUrlBack = `${REPORTS}/${metaCategory.toLowerCase()}`;

  return (
    <Layout
      bgColor='light'
      hideContainer
      header={
        <LayoutHeader
          transparent
          title={CategoriesTranslation[metaCategory as keyof typeof CategoriesTranslation]}
          showDesktopNavigation
          hideRightButton
          button={<GoBackButton url={redirectUrlBack} />}
        />
      }
    >
      <Container maxWidth='xl' disableGutters>
        <Grid flexDirection='column' justifyContent='center' alignItems='center'>
          <TransactionDetails.Wrapper
            item
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            width='100%'
          >
            <TransactionDetails.Details color='primary.main' variant='h3' sx={{ typography: { lg: 'h1' } }}>
              {formatCurrency(value, currencyCode as CurrencySymbol)}
            </TransactionDetails.Details>

            <MerchantWrapper date={formatFullDate(date)} merchant={merchant?.name || merchantPlaceholder} />
          </TransactionDetails.Wrapper>
        </Grid>
      </Container>
      <TransactionDetails.PaperWrapper>
        <Container maxWidth='xl' disableGutters>
          <TransactionDetails.Footprint>
            <HeaderWithValue unitVariant={!isLg ? 'h6' : 'h3'} value={footprint} color='common.black' />
          </TransactionDetails.Footprint>
          {(randomComparable as any)?.text && (
            <TransactionDetails.ComparableWrapper>
              <ComparableText text={(randomComparable as any)?.text} />
            </TransactionDetails.ComparableWrapper>
          )}
          <Typography textAlign='center' variant='caption' sx={{ typography: { lg: 'body1' } }} color='common.black'>
            {textHelper}
          </Typography>
          {feedbackLoopCategory && (
            <NavLink redirectURL={`${TRANSACTIONS}/${id}/edit`} transaction={transaction}>
              <TransactionDetails.ButtonWrapper>
                <Button text={feedbackLoopButton} />
              </TransactionDetails.ButtonWrapper>
            </NavLink>
          )}
          <TransactionDetails.InsightsWrapper>{Insight}</TransactionDetails.InsightsWrapper>
        </Container>
      </TransactionDetails.PaperWrapper>
    </Layout>
  );
};
