import * as CSS from 'csstype';
import { Variant } from '@mui/material/styles/createTypography';
import { formatNumber } from 'App/utils';

import { HeaderWithValueStyles } from './HeaderWithValue.styles';

type HeaderWithValueProps = {
  value: [string, string];
  unitVariant: Variant;
  isNegative?: boolean;
  isLarge?: boolean;
  isExtraLarge?: boolean;
  justifyContent?: CSS.Property.JustifyContent;
  color: string;
};

export const HeaderWithValue = ({
  isNegative = false,
  justifyContent,
  unitVariant,
  isLarge,
  isExtraLarge,
  value,
  color,
}: HeaderWithValueProps) => {
  const valueWithVector = isNegative ? `- ${value[0]}` : value[0];
  return (
    <HeaderWithValueStyles.Wrapper
      $isExtraLarge={isExtraLarge}
      alignItems='baseline'
      id='header-with-value'
      justifyContent={justifyContent}
    >
      <HeaderWithValueStyles.Value
        $isExtraLarge={isExtraLarge}
        variant={unitVariant}
        sx={{ typography: { md: isLarge ? 'h1' : 'h3' } }}
        color={color}
      >
        {formatNumber(+valueWithVector)}
      </HeaderWithValueStyles.Value>
      <HeaderWithValueStyles.Unit sx={{ typography: { sm: 'h6', md: isLarge ? 'h4' : 'h2' } }} color={color}>
        {value[1]}
        {` `}
      </HeaderWithValueStyles.Unit>
      <HeaderWithValueStyles.Suffix sx={{ typography: { sm: 'h6', md: isLarge ? 'h4' : 'h2' } }} color={color}>
        <>
          CO
          <sub>2</sub>e
        </>
      </HeaderWithValueStyles.Suffix>
    </HeaderWithValueStyles.Wrapper>
  );
};
