import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { Grid, Container } from '@mui/material';

import { handlePageRoute } from 'App/utils';

import { Layout, BASIC_GRADIENT_BACKGROUND_HEIGHT, Teaser, ProfileSettingsSlider } from 'App/components';

import { RoutePaths } from 'impactApp/routes/routePaths';

import { profileSettings } from '../../translations/dictionary/profileSettings';

import illustraion from './assets/illustration.png';

import { ProfileSettingsStyles } from './ProfileSettings.styles';

import { LoginButton } from './LoginButton';

export const ProfileSettings = () => {
  const { title, subtitle, text, startButton, submitButton } = profileSettings;
  const navigate = useNavigate();

  const { HOME, PROFILE_SETTINGS } = RoutePaths;

  const [isSliderVisible, setIsSliderVisible] = useState(false);

  const closeSlider = () => {
    setIsSliderVisible(false);
    navigate(HOME);
  };

  return (
    <Layout bgHeight={BASIC_GRADIENT_BACKGROUND_HEIGHT} hideContainer bgColor='light' isFullHeight>
      {isSliderVisible ? (
        <ProfileSettingsSlider submitButtonText={submitButton} closeHandler={closeSlider} />
      ) : (
        <ProfileSettingsStyles.CurveWrapper>
          <Teaser title={title} subtitle={subtitle} isAlignCenter />
          <Container>
            <Grid
              container
              columns={{ sm: 5, md: 8, lg: 12 }}
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
            >
              <ProfileSettingsStyles.ImgWrapper>
                <ProfileSettingsStyles.Img src={illustraion} />
              </ProfileSettingsStyles.ImgWrapper>
              <Grid item md={4}>
                <ProfileSettingsStyles.Text variant='subtitle3'>{text}</ProfileSettingsStyles.Text>
              </Grid>
              <ProfileSettingsStyles.ButtonWrapper>
                <LoginButton
                  button={startButton}
                  setIsSliderVisible={() => {
                    handlePageRoute(`${PROFILE_SETTINGS}/1`);
                    setIsSliderVisible(true);
                  }}
                />
              </ProfileSettingsStyles.ButtonWrapper>
            </Grid>
          </Container>
        </ProfileSettingsStyles.CurveWrapper>
      )}
    </Layout>
  );
};
