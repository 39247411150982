import { SVGProps, useEffect, useState } from 'react';

interface FoodRegionalProps {
  isActive?: boolean;
  props?: SVGProps<SVGSVGElement>;
}

export const FoodRegional = ({ props, isActive }: FoodRegionalProps): JSX.Element => {
  const [isActiveState, setActive] = useState(isActive);

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    <svg {...props} width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.3082 25.9118C24.3147 22.5567 26.8508 17.84 26.8508 15.4155C26.8508 11.3257 23.552 8 19.4953 8C15.4386 8 12.1398 11.3257 12.1398 15.4155C12.1398 17.84 14.6759 22.5567 16.6823 25.9118C14.3018 26.0931 8.88477 26.7445 8.88477 28.9045C8.88477 31.0302 14.3844 32 19.5002 32C24.6111 32 30.1155 31.0302 30.1155 28.9045C30.1107 26.7445 24.6888 26.0931 22.3082 25.9118ZM13.1552 15.4155C13.1552 11.889 16.0022 9.01878 19.4953 9.01878C22.9933 9.01878 25.8402 11.889 25.8402 15.4155C25.8402 18.4963 20.9722 26.2253 19.4953 28.498C18.0184 26.2253 13.1552 18.4963 13.1552 15.4155ZM19.4953 30.9763C13.2815 30.9763 9.89529 29.6049 9.89529 28.8996C9.89529 28.3559 12.1301 27.1853 17.2799 26.8865C18.2467 28.4588 18.9803 29.5706 19.0775 29.7029C19.2087 29.8792 19.5925 30.0898 19.918 29.7029C20.02 29.5804 20.7487 28.4588 21.7155 26.8865C26.8653 27.1853 29.1002 28.351 29.1002 28.8996C29.0953 29.6098 25.7091 30.9763 19.4953 30.9763Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='0.3'
      />
      <path
        d='M22.6967 15.4153C22.6967 13.6324 21.2635 12.1875 19.4951 12.1875C17.7267 12.1875 16.2935 13.6324 16.2935 15.4153C16.2935 17.1981 17.7267 18.643 19.4951 18.643C21.2635 18.643 22.6967 17.1981 22.6967 15.4153ZM17.304 15.4153C17.304 14.1957 18.2854 13.2063 19.4951 13.2063C20.7048 13.2063 21.6862 14.1957 21.6862 15.4153C21.6862 16.6348 20.7048 17.6242 19.4951 17.6242C18.2854 17.6242 17.304 16.6348 17.304 15.4153Z'
        fill={isActiveState ? '#ffffff' : '#F04E39'}
        stroke={isActiveState ? '#ffffff' : '#F04E39'}
        strokeWidth='0.3'
      />
    </svg>
  );
};
