import { Typography } from '@mui/material';
import { Grid } from 'components';
import { formatNumber } from 'App/utils';

import { ComparisonStyles } from './Comparison.styles';

type ComparisonProps = {
  comparison: [string, string];
  comparisonMessage: string;
};

export const Comparison = ({ comparison, comparisonMessage }: ComparisonProps) => (
  <Grid alignItems='baseline' spacingInside={{ top: 0.5, bottom: 0.5 }}>
    <ComparisonStyles.Value variant='h6' sx={{ typography: { sm: 'subtitle1', lg: 'h6' } }} color='primary.dark'>
      {formatNumber(+comparison[0])} {comparison[1]}
    </ComparisonStyles.Value>
    <Typography variant='body2' color='primary.dark'>
      {comparisonMessage}
    </Typography>
  </Grid>
);
