export const CheckIcon = () => (
  <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.5 12L10.157 15.751L18 8.5'
      stroke='#74C8AD'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.8'
    />
    <path
      d='M1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12V12Z'
      stroke='#74C8AD'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.8'
    />
  </svg>
);
