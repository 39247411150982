import { Config } from 'App/config';

import { useRefreshSession, useMutateData, PostSessionResponse } from '@ecolytiq/js-data-provider';

import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import {
  setSessionTokenAction,
  useUserContext,
  setIsLoggedInAction,
  useDeps,
  setSessionRefreshTokenAction,
} from 'App/context';

import { useCookies } from 'react-cookie';

import { RoutePaths } from 'impactApp/routes/routePaths';
import { appConfig } from 'App/appConfig/appConfig';

import { handleUserLogin } from '../../../App/utils';

export const useRefreshSessionData = (refreshToken: string) => {
  const { httpClientService, httpClientServiceOAuth } = useDeps();
  const [{ isLoggedIn, sessionRefreshToken }, dispatch] = useUserContext();
  const { REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_ID, REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_SECRET } = Config.getAll();
  const navigate = useNavigate();
  const { SSO_PAGE } = RoutePaths;
  const { REACT_APP_FIRST_LOGIN_COOKIE_NAME, REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } =
    appConfig;
  const [cookies, setCookie] = useCookies([REACT_APP_FIRST_LOGIN_COOKIE_NAME]);
  const { mutate: sessionMutate } = useMutateData<PostSessionResponse>(
    useRefreshSession(httpClientServiceOAuth, refreshToken),
    {
      onSuccess: async (sessionDataResponse: any) => {
        const { access_token, refresh_token } = sessionDataResponse;
        dispatch(setSessionTokenAction(access_token));
        handleUserLogin(access_token);

        const interceptor = httpClientService.setInterceptor(`Bearer ${access_token}`);
        setCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, access_token, {
          path: '/',
        });
        setCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, refresh_token, {
          path: '/',
        });

        dispatch(setIsLoggedInAction(true));
        dispatch(setSessionRefreshTokenAction(false));

        httpClientService.removeInterceptor(interceptor);
      },
      onError: async () => {
        navigate(SSO_PAGE);
      },
    }
  );

  useEffect(() => {
    if (sessionRefreshToken && isLoggedIn) {
      httpClientServiceOAuth.removeInterceptor(0);

      httpClientServiceOAuth.setInterceptor(({ headers, ...restRequestConfig }: any) => {
        return {
          ...restRequestConfig,
          headers: {
            ...headers,
            authorization: `Basic ${Buffer.from(
              `${REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_ID}:${REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_SECRET}`
            ).toString('base64')}`,
          },
        };
      });
      sessionMutate();
    }
  }, [
    cookies,
    isLoggedIn,
    sessionMutate,
    httpClientService,
    REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_ID,
    REACT_APP_ECOLYTIQ_API_AUTH_CLIENT_SECRET,
    dispatch,
    httpClientServiceOAuth,
    sessionRefreshToken,
  ]);
};
