import { SVGProps } from 'react';

export const SuitcaseIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#suitcase)'>
      <path
        d='M14.25 6.74899V2.24899C14.25 1.85117 14.408 1.46964 14.6893 1.18833C14.9706 0.907028 15.3522 0.748993 15.75 0.748993H18.75C19.1478 0.748993 19.5294 0.907028 19.8107 1.18833C20.092 1.46964 20.25 1.85117 20.25 2.24899V6.74899M15 11.999V17.249M19.5 11.999V17.249M14.25 21.749V23.249M20.25 21.749V23.249M5.25 12.749H8.25M3 17.249H8.25M0.75 21.749H8.25M11.25 6.74899H23.25V21.749H11.25V6.74899Z'
        stroke='#2D424D'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </g>
    <defs>
      <clipPath id='suitcase'>
        <rect fill='white' height='24' width='24' />
      </clipPath>
    </defs>
  </svg>
);
