import { useTheme } from 'styled-components/macro';
import { TypographyProps } from '@mui/material/Typography';

import { AccordionAppearance } from '../enums';

export const useMapAppearanceToColor = (appearance: AccordionAppearance) => {
  const {
    palette: {
      common: { white, black },
    },
  } = useTheme();

  const colorsMap = {
    [AccordionAppearance.LIGHT]: {
      iconColor: white,
      textColor: white as TypographyProps['color'],
    },
    [AccordionAppearance.DARK]: {
      iconColor: black,
      textColor: black as TypographyProps['color'],
    },
  };

  return colorsMap[appearance];
};
