import { Layout } from 'App/components';

import { sso } from 'impactApp/translations';

import { ButtonAppearance } from 'components';

import { SSOStyles } from './SSOPage.styles';

export const SSOPage = () => {
  const { title, text, vancityButton, vancityURL } = sso;

  return (
    <Layout bgColor='dark' isFullHeight>
      <SSOStyles.Wrapper>
        <SSOStyles.Icon />
        <SSOStyles.Title variant='h6'>{title}</SSOStyles.Title>
        <SSOStyles.Text>{text}</SSOStyles.Text>
        <SSOStyles.ButtonWrapper
          appearance={ButtonAppearance.LIGHT}
          onClick={() => window.open(vancityURL, '_self')}
          text={vancityButton}
        />
      </SSOStyles.Wrapper>
    </Layout>
  );
};
