export const reportDetails = {
  title: {
    prefix: 'Your ',
    postfix: 'footprint is:',
  },
  listSpenders: {
    title: 'Your biggest emitters',
  },
  didYouKnowSection: {
    title: 'Did you know...',
  },
  infoExtrapolation:
    "There weren't enough transactions in this category to determine its CO<sub>2</sub> footprint. We've made our best guess, based on your transactions in other categories as well as census data on how CO<sub>2</sub> footprints in different categories relate to one another.",
};
