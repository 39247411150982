import { Grid } from '@mui/material';
import { ReactNode } from 'react';

type InnerContainerProps = {
  children: ReactNode;
};

export const InnerContainer = ({ children }: InnerContainerProps): JSX.Element => (
  <Grid container columns={{ md: 8, lg: 12, xl: 12 }} justifyContent='center'>
    <Grid item width='100%' lg={10}>
      {children}
    </Grid>
  </Grid>
);
