import { Typography } from '@mui/material';
import styled, { css } from 'styled-components/macro';

const Wrapper = styled('div')<{
  $textColor: string;
}>`
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  ${({ $textColor }) =>
    $textColor &&
    css`
      color: ${$textColor};
    `};
  svg {
    width: 16px;
    height: 16px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Text = styled(Typography)`
  margin-left: 8px;
`;

export const LinkStyles = {
  Wrapper,
  Text,
};
