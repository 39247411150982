import {
  FoodMixed,
  FoodLessMeat,
  FoodThreeTimes,
  FoodDaily,
  FoodMeat,
  FoodNormal,
  FoodRarely,
  FoodRegional,
  FoodVegan,
  FoodVegetarian,
  FirstClass,
  Benzin,
  Diesel,
  OneWay,
  RoundTrip,
  MonthlyTravel,
  EconomyClass,
  BusinessClass,
} from 'components/Icons';
import { AnswersIconsName } from 'App/types';
import React from 'react';

const {
  MIXED_DIET,
  FEW_MEAT,
  LOTS_OF_MEAT,
  VEGAN,
  VEGETERIAN,
  DAILY,
  RARE,
  REGIONAL,
  UNTIL_THREE_TIMES,
  NORMAL,
  FIRST_CLASS,
  BENZIN,
  DIESEL,
  ONE_WAY,
  ROUND_TRIP,
  MONTHLY_TRAVEL,
  ECONOMY_CLASS,
  BUSINESS_CLASS,
} = AnswersIconsName;

const icons = {
  [MIXED_DIET]: <FoodMixed />,
  [FEW_MEAT]: <FoodLessMeat />,
  [LOTS_OF_MEAT]: <FoodMeat />,
  [VEGAN]: <FoodVegan />,
  [VEGETERIAN]: <FoodVegetarian />,
  [DAILY]: <FoodDaily />,
  [RARE]: <FoodRarely />,
  [REGIONAL]: <FoodRegional />,
  [UNTIL_THREE_TIMES]: <FoodThreeTimes />,
  [NORMAL]: <FoodNormal />,
  [FIRST_CLASS]: <FirstClass />,
  [BENZIN]: <Benzin />,
  [DIESEL]: <Diesel />,
  [ONE_WAY]: <OneWay />,
  [ROUND_TRIP]: <RoundTrip />,
  [MONTHLY_TRAVEL]: <MonthlyTravel />,
  [ECONOMY_CLASS]: <EconomyClass />,
  [BUSINESS_CLASS]: <BusinessClass />,
};

const renderFeedbackIcon = (iconName: AnswersIconsName, $isActive: boolean) => {
  return React.cloneElement(icons[iconName] as React.ReactElement<any>, {
    isActive: $isActive,
  });
};

export { renderFeedbackIcon };
