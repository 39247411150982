import { CircularProgress } from '@mui/material';

import { CircularLoadingStyles } from './CircularLoading.styles';

type CircularLoadingProps = {
  isFullHeight?: boolean;
};

export const CircularLoading = ({ isFullHeight }: CircularLoadingProps) => (
  <CircularLoadingStyles.CircularProgress isFullHeight={isFullHeight} id='circular-loader'>
    <CircularProgress />
  </CircularLoadingStyles.CircularProgress>
);
