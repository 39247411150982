import dompurify from 'dompurify';
import { AccordionDetails as MuiAccordionDetails, Typography, TypographyProps } from '@mui/material';

import { Item } from '../types';

type AccordionDetailsProps = {
  body: Item['body'];
  textColor: TypographyProps['color'];
};

const sanitizer = dompurify.sanitize;

export const AccordionDetails = ({ body, textColor }: AccordionDetailsProps) => {
  return (
    <MuiAccordionDetails>
      {typeof body !== 'string' ? (
        body
      ) : (
        <Typography
          color={textColor}
          dangerouslySetInnerHTML={{
            __html: sanitizer(body, { ADD_ATTR: ['target'] }),
          }}
          variant='body2'
        />
      )}
    </MuiAccordionDetails>
  );
};
