import { Typography } from '@mui/material';

import { MerchantWrapperStyles } from './MerchantWrapper.style';

type MerchantWrapperProps = {
  merchant: string;
  price?: string;
  date: string;
};

export const MerchantWrapper = ({ merchant, price, date }: MerchantWrapperProps): JSX.Element => {
  return (
    <>
      {price && (
        <MerchantWrapperStyles.Price color='primary.dark' variant='subtitle2' sx={{ typography: { lg: 'h6' } }}>
          {price}
        </MerchantWrapperStyles.Price>
      )}
      <MerchantWrapperStyles.Merchant color='primary.dark' variant='subtitle3' sx={{ typography: { lg: 'subtitle1' } }}>
        {merchant}
      </MerchantWrapperStyles.Merchant>
      <Typography color='primary.dark' variant='caption' sx={{ typography: { sm: 'caption', lg: 'body1' } }}>
        {date}
      </Typography>
    </>
  );
};
