import { Typography } from '@mui/material';
import { Paper } from 'components';
import styled, { css } from 'styled-components/macro';

const Question = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  div:first-child {
    width: 280px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    margin-bottom: 64px;
    div:first-child {
      width: auto;
    }
  }
`;

const AnswersWrapper = styled(Paper.Rounded)`
  padding: 32px 12px;
  justify-content: center;
  background: ${({ theme }) => theme.palette.common.white};
  margin: 0 -12px 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 230px;
  width: 280px;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 420px;
    padding: 48px 32px;
  }
  ${(props) => props.theme.breakpoints.up('lg')} {
    width: 468px;
    min-height: 380px;
  }
`;

const AnswerIconWrapper = styled.div`
  width: fit-content;
  margin: 4px;
  svg {
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.light};
  }
`;

const AnswerWrapper = styled.div<{ isAnswer: boolean }>`
  padding: 12px 8px;
  width: 33.3333%;
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  ${({ isAnswer }) =>
    isAnswer &&
    css`
      ${AnswerIconWrapper} {
        svg {
          background: ${({ theme }) => theme.palette.primary.main};
        }
      }
    `}
`;

const AnswerTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const SlideWrapper = styled.div`
  margin: 0 auto;
  height: calc(100vh - 70px);
  min-height: 700px;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const InfoWrapper = styled.div`
  cursor: pointer;
  margin-left: 4px;
`;

export const SlideStyles = {
  Question,
  AnswerTitle,
  AnswerIconWrapper,
  AnswerWrapper,
  AnswersWrapper,
  SlideWrapper,
  InfoWrapper,
};
