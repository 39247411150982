import { UserActions } from './types';

export const toggleMenuVisibilityAction = (): { type: UserActions.TOGGLE_MENU_VISIBILITY } => ({
  type: UserActions.TOGGLE_MENU_VISIBILITY,
});

export const setSessionTokenAction = (
  sessionToken: string
): { type: UserActions.SET_SESSION_TOKEN; sessionToken: string } => ({
  type: UserActions.SET_SESSION_TOKEN,
  sessionToken,
});

export const setSessionRefreshTokenAction = (
  sessionRefreshToken: boolean
): { type: UserActions.SET_SESSION_REFRESH_TOKEN; sessionRefreshToken: boolean } => ({
  type: UserActions.SET_SESSION_REFRESH_TOKEN,
  sessionRefreshToken,
});

export const setMonthDeltaAction = (monthDelta: number): { type: UserActions.SET_MONTH_DELTA; monthDelta: number } => ({
  type: UserActions.SET_MONTH_DELTA,
  monthDelta,
});

export const setIsLoggedInAction = (
  isLoggedIn: boolean
): { type: UserActions.SET_IS_LOGGED_IN; isLoggedIn: boolean } => ({
  type: UserActions.SET_IS_LOGGED_IN,
  isLoggedIn,
});

export const setIsFirstLoginAction = (
  isFirstLogin: boolean
): { type: UserActions.SET_IS_FIRST_LOGIN; isFirstLogin: boolean } => ({
  type: UserActions.SET_IS_FIRST_LOGIN,
  isFirstLogin,
});

export const resetStateAction = (): { type: UserActions.RESET_STATE } => ({ type: UserActions.RESET_STATE });
