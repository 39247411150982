import { SVGProps } from 'react';

export const CutleryIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.74902 0.75V23.25M9.74902 0.75V7.5C9.74665 8.29492 9.42982 9.0566 8.86772 9.6187C8.30563 10.1808 7.54394 10.4976 6.74902 10.5C5.9541 10.4976 5.19242 10.1808 4.63033 9.6187C4.06823 9.0566 3.7514 8.29492 3.74902 7.5V0.75M15.749 15.75H18.749C18.948 15.7576 19.1465 15.724 19.3319 15.6513C19.5173 15.5786 19.6857 15.4684 19.8265 15.3275C19.9674 15.1867 20.0776 15.0183 20.1503 14.8329C20.223 14.6475 20.2566 14.449 20.249 14.25C20.088 9.942 19.914 5.43 17.136 1.093C17.047 0.957313 16.9166 0.853891 16.7642 0.798029C16.6118 0.742167 16.4455 0.736831 16.2898 0.78281C16.1342 0.828789 15.9975 0.923642 15.8999 1.05334C15.8023 1.18304 15.7491 1.3407 15.748 1.503V23.25'
      stroke='#2D424D'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </svg>
);
