import { SVGProps } from 'react';

export const WalletIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M21.75 18.5V20C21.75 20.7956 21.4339 21.5587 20.8713 22.1213C20.3087 22.6839 19.5456 23 18.75 23H3.75C2.95435 23 2.19129 22.6839 1.62868 22.1213C1.06607 21.5587 0.75 20.7956 0.75 20V9.49999C0.75 8.70434 1.06607 7.94128 1.62868 7.37867C2.19129 6.81606 2.95435 6.49999 3.75 6.49999M21.75 18.5C22.1478 18.5 22.5294 18.342 22.8107 18.0606C23.092 17.7793 23.25 17.3978 23.25 17V12.5C23.25 12.1022 23.092 11.7206 22.8107 11.4393C22.5294 11.158 22.1478 11 21.75 11M21.75 18.5H18C17.0054 18.5 16.0516 18.1049 15.3483 17.4016C14.6451 16.6984 14.25 15.7446 14.25 14.75C14.25 13.7554 14.6451 12.8016 15.3483 12.0983C16.0516 11.3951 17.0054 11 18 11H21.75M3.75 6.49999H18.75M3.75 6.49999L14.408 1.17099C14.5842 1.08282 14.7761 1.03023 14.9726 1.01624C15.1692 1.00224 15.3666 1.02711 15.5535 1.08943C15.7405 1.15174 15.9133 1.25027 16.0621 1.3794C16.211 1.50853 16.3329 1.66572 16.421 1.84199L18.75 6.49999M18.75 6.49999C19.5456 6.49999 20.3087 6.81606 20.8713 7.37867C21.4339 7.94128 21.75 8.70434 21.75 9.49999V11M18 14.375C18.0742 14.375 18.1467 14.397 18.2083 14.4382C18.27 14.4794 18.3181 14.538 18.3465 14.6065C18.3748 14.675 18.3823 14.7504 18.3678 14.8231C18.3533 14.8959 18.3176 14.9627 18.2652 15.0152C18.2127 15.0676 18.1459 15.1033 18.0732 15.1178C18.0004 15.1323 17.925 15.1248 17.8565 15.0964C17.788 15.0681 17.7294 15.02 17.6882 14.9583C17.647 14.8967 17.625 14.8242 17.625 14.75C17.625 14.6505 17.6645 14.5552 17.7348 14.4848C17.8052 14.4145 17.9005 14.375 18 14.375Z'
      stroke='#2D424D'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
    />
  </svg>
);
