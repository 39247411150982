import { Theme } from '@mui/material';
import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box; 
  }   

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 22px;
    margin: 0;    
    padding: 0;
    background-color: ${({ theme }: { theme: Theme }) => theme.palette.common.white};    
  }

  strong {
    font-weight: 900;
    line-height: 0;
  }

  sub, sup {
    font-size: 50%;
  }
 
  p { 
    margin: 0;
  }

  .MuiContainer-root {
    position: relative;
  }

  .MuiListItemAvatar-alignItemsFlexStart {
    margin-top: 0;
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;
