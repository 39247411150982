import { Container, Grid } from '@mui/material';

import { TeaserStyles } from './Teaser.styles';

type TeaserProps = {
  title?: string;
  subtitle?: string;
  isAlignCenter?: boolean;
  hasFixedHeader?: boolean;
};

export const Teaser = ({ title, subtitle, isAlignCenter, hasFixedHeader }: TeaserProps) => {
  return (
    <TeaserStyles.Wrapper alignText={isAlignCenter} hasSubtitle={!!subtitle} hasFixedHeader={hasFixedHeader}>
      <Container maxWidth='xl'>
        <Grid container columns={{ md: 8, lg: 12 }} justifyContent='center' alignItems='center'>
          <Grid item md={6}>
            {title && (
              <TeaserStyles.Title variant='h2' sx={{ typography: { sm: 'h5', lg: 'h2' } }}>
                {title}
              </TeaserStyles.Title>
            )}
            {subtitle && (
              <TeaserStyles.Subtitle sx={{ typography: { sm: 'subtitle3', lg: 'subtitle2' } }}>
                {subtitle}
              </TeaserStyles.Subtitle>
            )}
          </Grid>
        </Grid>
      </Container>
    </TeaserStyles.Wrapper>
  );
};
