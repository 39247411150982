import { LogoutIcon } from 'components/Icons';
import { resetStateAction, useUserContext } from 'App/context';

import { useNavigate } from 'react-router-dom';

import { appConfig } from 'App/appConfig/appConfig';

import { useCookies } from 'react-cookie';

import { IconButton } from '../IconButton';

type LogoutButtonProps = {
  url: string;
};

export const LogoutButton = ({ url }: LogoutButtonProps) => {
  const navigate = useNavigate();
  const [, dispatch] = useUserContext();
  const { REACT_APP_ACCESS_TOKEN_COOKIE_NAME, REACT_APP_REFRESH_TOKEN_COOKIE_NAME } = appConfig;

  const [cookies, , removeCookie] = useCookies([REACT_APP_ACCESS_TOKEN_COOKIE_NAME]);

  const handleLogout = () => {
    if (cookies[REACT_APP_ACCESS_TOKEN_COOKIE_NAME]) {
      removeCookie(REACT_APP_ACCESS_TOKEN_COOKIE_NAME, { path: '/' });
      removeCookie(REACT_APP_REFRESH_TOKEN_COOKIE_NAME, { path: '/' });
    }
    dispatch(resetStateAction());
    navigate(url);
  };

  return (
    <IconButton.Content aria-label='Logout' color='inherit' edge='end' disableRipple onClick={handleLogout}>
      <IconButton.IconWrapper>
        <LogoutIcon />
      </IconButton.IconWrapper>
    </IconButton.Content>
  );
};
