import { Typography } from '@mui/material';
import styled, { css } from 'styled-components/macro';

const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Month = styled(Typography)`
  width: 160px;
  text-align: center;
  font-weight: 500;
`;

const Wrapper = styled.div`
  ${FlexCenter};
  padding-bottom: 28px;

  ${(props) => props.theme.breakpoints.up('lg')} {
    padding-bottom: 24px;
  }

  input {
    text-align: center;
    padding: 8px 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    &::before {
      border: none;
    }
  }

  div::before {
    content: none;
  }
  div::after {
    content: none;
  }
`;

export const DatePickerStyles = {
  Month,
  Wrapper,
};
