import { handleClickedButton } from 'App/utils';
import { ButtonAppearance } from 'components';
import { ChevronRightIcon } from 'components/Icons';
import { useLocation } from 'react-router-dom';

import { ButtonStyles } from './Button.styles';

type ButtonProps = {
  text: string;
  appearance?: ButtonAppearance;
  isDisabled?: boolean;
  isSmall?: boolean;
  isLarge?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: boolean;
  id?: string;
  onClick?: () => void;
  isTransparent?: boolean;
};

export const Button = ({
  appearance = ButtonAppearance.PRIMARY,
  isSmall,
  isLarge,
  text,
  iconLeft,
  iconRight,
  isDisabled = false,
  id,
  onClick,
  isTransparent,
}: ButtonProps) => {
  const location = useLocation();

  return (
    <ButtonStyles.Button
      isTransparent={isTransparent}
      $isSmall={isSmall}
      $isLarge={isLarge}
      appearance={appearance}
      disabled={isDisabled}
      onClick={() => {
        onClick?.();
        handleClickedButton(text, location.pathname, '');
      }}
      id={id}
    >
      {iconLeft}
      {text}
      {iconRight && <ChevronRightIcon />}
    </ButtonStyles.Button>
  );
};
