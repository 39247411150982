export enum Categories {
  FOOD = 'FOOD',
  TRAVEL = 'TRAVEL',
  SHOPPING = 'SHOPPING',
  LIVING = 'LIVING',
  OTHER = 'OTHER',
}

export enum CategoriesTranslation {
  FOOD = 'Food',
  TRAVEL = 'Transportation',
  SHOPPING = 'Shopping',
  LIVING = 'Living',
  OTHER = 'Uncategorized',
}

export enum SubCategories {
  'ex:transport.flight' = '2',
  'ex:food.groceries' = '8',
  'ex:food.corner' = '8',
  'ex:food.cafe' = '9',
  'ex:food.catering' = '9',
}

export enum SubCategoriesImages {
  'ex:transport.flight' = 'Air-Travel',
  'ex:transport.localtransport' = 'Local-Transport',
  'ex:transport.train' = 'Train',
  'ex:transport.taxi' = 'Taxi',
  'ex:transport.servicestations' = 'Service-Stations',
  'ex:transport.emobility' = 'Service-Stations',
  'ex:transport.car-rental' = 'Car-Rental',
  'ex:shopping.online' = 'Online-Shopping',
  'ex:shopping.furniture' = 'Furniture',
  'ex:shopping.clothes' = 'Clothes-Shopping',
  'ex:misc.travel' = 'Travel-Agencies',
  'ex:misc.streaming' = 'Streaming',
  'ex:misc.streaming.video' = 'Streaming',
  'ex:misc.streaming.audio' = 'Streaming',
  'ex:misc.internet' = 'Streaming',
  'ex:misc.lodging' = 'Lodging-Accomodation',
  'ex:misc.drug-stores' = 'Drug-Stores',
  'ex:misc' = 'General',
  'ex:misc.zero' = 'General',
  'ex:food.groceries' = 'Groceries',
  'ex:food.butcher' = 'Groceries',
  'ex:food.fishmonger' = 'Groceries',
  'ex:food.corner' = 'Convenience-Stores',
  'ex:food.liquor-store' = 'Convenience-Stores',
  'ex:food.cafe' = 'Bakeries-Cafe',
  'ex:food.catering' = 'Catering',
  'ex:living.energy' = 'Energy',
  'ex:living.home' = 'Home',
  'ex:living.water' = 'Home',
}

export enum ComparisonValue {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  CONSTANT = 'constant',
}

export enum UnitsOfMass {
  KG = 'kg',
  T = 't',
}

export enum Months {
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
}

export enum HighlightedColor {
  ORANGE = 'orange',
  BLACK = 'black',
  HIGHLIGHTED_RED = 'highlighted_red',
}

export enum AnswersIconsName {
  LOTS_OF_MEAT = 'lots-of-meat',
  MIXED_DIET = 'mixed-diet',
  FEW_MEAT = 'few-meat',
  VEGETERIAN = 'vegeterian',
  VEGAN = 'vegan',
  NORMAL = 'normal',
  REGIONAL = 'regional',
  DAILY = 'daily',
  UNTIL_THREE_TIMES = 'until-three-times',
  RARE = 'rare',
  FIRST_CLASS = 'first-class',
  BENZIN = 'benzin',
  DIESEL = 'diesel',
  ONE_WAY = 'one-way',
  ROUND_TRIP = 'round-trip',
  MONTHLY_TRAVEL = 'monthly-travel',
  ECONOMY_CLASS = 'economy-class',
  BUSINESS_CLASS = 'business-class',
}
