import { createContext, Dispatch, PropsWithChildren, useContext, useReducer } from 'react';

import { initialState, reducer } from './User.reducer';
import { UserAction, UserState } from './types';

type UserContextType = [UserState, Dispatch<UserAction>];

const UserContext = createContext<UserContextType>([initialState, () => null]);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be nested in UserProvider');
  }

  return context;
};

export const UserProvider = ({ children, ...restProps }: PropsWithChildren<Partial<UserState>>) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const newState = { ...state, ...restProps };

  return <UserContext.Provider value={[newState, dispatch]}>{children}</UserContext.Provider>;
};
