import dompurify from 'dompurify';
import { PropsWithChildren } from 'react';

import { YearMonthPicker } from 'components';

import { ListHeaderStyles } from './ListHeader.styles';

type ListHeaderProps = {
  month?: number;
  onMonthChange?: (month: number) => void;
  position?: string;
  subtitle: string;
  title: string;
};

const sanitizer = dompurify.sanitize;

export const ListHeader = ({
  children,
  month,
  onMonthChange,
  position,
  subtitle,
  title,
}: PropsWithChildren<ListHeaderProps>) => {
  const monthPicker = month !== undefined && onMonthChange && (
    <YearMonthPicker month={month} onMonthChange={onMonthChange} />
  );

  return (
    <>
      {monthPicker}
      <ListHeaderStyles.Wrapper
        $position={position}
        justifyContent='space-between'
        spacingInside={{ right: 4, bottom: 4, left: 4 }}
      >
        <ListHeaderStyles.TitleWrapper>
          <ListHeaderStyles.Title
            color='textPrimary'
            variant='subtitle1'
            dangerouslySetInnerHTML={{
              __html: sanitizer(title),
            }}
          />
          <ListHeaderStyles.Subtitle variant='subtitle3'>{subtitle}</ListHeaderStyles.Subtitle>
        </ListHeaderStyles.TitleWrapper>
        <div>{children}</div>
      </ListHeaderStyles.Wrapper>
    </>
  );
};
