import styled from 'styled-components/macro';
import { color, footerHeight, footerMobileHeight } from 'App/style/theme';
import { Typography } from '@mui/material';
import { CheckOutlineIcon } from 'components/Icons/CheckOutlineIcon';
import { Button } from 'components';

const Icon = styled(CheckOutlineIcon)`
  margin-bottom: 40px;
`;

const Wrapper = styled.div`
  min-height: calc(100vh - ${footerMobileHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    min-height: calc(100vh - ${footerHeight});
    width: 50%;
    margin: 0 auto;
  }
`;

const Title = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.primary.light}`};
  text-align: center;
  margin-bottom: 56px;
  padding: 0 32px;
`;

const Text = styled(Typography)`
  color: ${color.white};
  text-align: center;
  margin-bottom: 100px;
  padding: 0 32px;
  ${(props) => props.theme.breakpoints.up('lg')} {
    margin-bottom: 52px;
    padding: 0 128px;
  }
`;

const ButtonWrapper = styled(Button)`
  margin: 20px auto 0;
`;

export const SSOStyles = {
  Wrapper,
  Icon,
  Title,
  Text,
  ButtonWrapper,
};
