import { SVGProps } from 'react';
import { theme } from 'App/style';

export const ChartMarkerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    fill={theme.palette.primary.main}
    height='12'
    viewBox='0 0 13 12'
    width='13'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='6.99805' cy='5.99976' r='4.5' stroke={theme.palette.primary.main} strokeWidth='3' />
  </svg>
);
