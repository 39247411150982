import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import styled from 'styled-components/macro';

import { Grid } from 'components';
import { HighlightedColor } from 'App/types';

const DetailsIcon = styled(Grid)<{ $color: string }>`
  svg {
    color: ${({ $color }) => $color};
  }
`;

const Value = styled(Typography)<{
  $colorText?: boolean;
  appearance: HighlightedColor;
}>`
  margin-left: 4px;
  color: ${({
    theme: {
      palette: {
        custom: {
          list: { highlightedData, lightText, darkText },
        },
      },
    },
    $colorText,
    appearance,
  }) => {
    if ($colorText) return highlightedData[appearance];
    if (appearance === HighlightedColor.BLACK) return lightText;
    return darkText;
  }};
`;

const Information = styled(Typography)`
  margin-top: 4px;

  color: ${({
    theme: {
      palette: {
        custom: {
          list: { lightGreyText },
        },
      },
    },
  }) => lightGreyText};

  white-space: nowrap;
`;

const Title = styled(Typography)`
  text-transform: capitalize;
  color: ${({
    theme: {
      palette: {
        text: { primary },
      },
    },
  }) => primary};
`;

const IconWrapper = styled(Grid)`
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme }) => `${theme.palette.grey[400]}`};
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    background: linear-gradient(-90deg, #f7f7f7 6.42%, rgba(247, 247, 247, 0) 100%);
  }
`;

export const BasicListItemStyles = {
  DetailsIcon,
  IconWrapper,
  Information,
  Link: StyledLink,
  Title,
  Value,
};
