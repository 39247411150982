import styled from 'styled-components/macro';
import { footerHeight, footerMobileHeight } from 'App/style/theme';

const Wrapper = styled.div`
  min-height: calc(100vh - ${footerMobileHeight});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) => props.theme.breakpoints.up('lg')} {
    min-height: calc(100vh - ${footerHeight});
    width: 50%;
    margin: 0 auto;
  }
`;

export const LoadingPageStyles = {
  Wrapper,
};
