import { Typography } from '@mui/material';
import styled from 'styled-components/macro';

export const ComparableTextWrapper = styled(Typography)`
  background: ${({ theme }) => theme.palette.secondary.light};
  border-radius: 99px;
  padding: 8px 20px;
  width: fit-content;
  margin: 0 auto;
  ${(props) => props.theme.breakpoints.up('lg')} {
    border-radius: 140px;
    padding: 12px 32px;
  }
`;
