import styled from 'styled-components/macro';
import vrLogo from 'impactApp/assets/Logo.svg';
import { common } from 'impactApp/translations';

interface LogoProps {
  inFooter?: boolean;
}

const StyledLogo = styled.div<{
  inFooter?: boolean;
}>`
  img {
    display: flex;
    width: ${({ inFooter }) => (inFooter ? '56px' : '92px')};

    ${(props) => props.theme.breakpoints.up('md')} {
      width: ${({ inFooter }) => (inFooter ? '74px' : '92px')};
    }
  }
`;

export const Logo = ({ inFooter }: LogoProps): JSX.Element => (
  <StyledLogo inFooter={inFooter}>
    <img alt={common.logoAlt} src={vrLogo} />
  </StyledLogo>
);
